import type { FC } from 'react'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ContentLayout } from '@helloextend/customers-ui'

import { contactFormActions } from '../../actions'
import * as selectors from '../../reducers/selectors'
import type { RootState } from '../../reducers'
import { ContactForm } from './contact-form'
import { ContactFormSuccess } from './contact-form-success'

interface DP {
  contactFormSubmit: typeof contactFormActions.contactFormSubmit
  resetContactForm: typeof contactFormActions.contactFormResetSubmit
}

interface SP {
  hasSubmitted: boolean
  decodedAccessToken: ReturnType<typeof selectors.getDecodedAccessToken>
}

type ContactProps = DP & SP

const Component: FC<ContactProps> = ({
  contactFormSubmit,
  resetContactForm,
  hasSubmitted,
  decodedAccessToken,
}) => {
  useEffect(() => {
    return () => {
      resetContactForm()
    }
  }, [resetContactForm])

  return (
    <ContentLayout pageTitle="Contact Extend">
      {hasSubmitted ? (
        <ContactFormSuccess />
      ) : (
        <ContactForm onSubmit={contactFormSubmit} decodedAccessToken={decodedAccessToken} />
      )}
    </ContentLayout>
  )
}

const Contact = connect(
  (state: RootState): SP => ({
    hasSubmitted: selectors.getIsContractFormSubmitted(state),
    decodedAccessToken: selectors.getDecodedAccessToken(state),
  }),
  {
    contactFormSubmit: contactFormActions.contactFormSubmit,
    resetContactForm: contactFormActions.contactFormResetSubmit,
  } as DP,
)(Component)

export type { ContactProps }
export { Component, Contact }
