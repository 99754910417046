import type { Locale } from '../types/localization'
import { SUPPORTED_LANGUAGES } from '../types/localization'

export const injectParams = (params: { [param: string]: string }, path: string): string => {
  const keys = Object.keys(params)

  if (!keys.length) {
    return path
  }

  return keys.reduce((str, key) => str.replace(`:${key}`, params[key]), path)
}

export const determineLocale = (pathname: string): Locale => {
  const locale = pathname.split('/')[1]

  if (SUPPORTED_LANGUAGES.includes(locale)) {
    return locale as Locale
  }

  const browserLanguage = navigator.language
  if (browserLanguage && SUPPORTED_LANGUAGES.includes(browserLanguage)) {
    return browserLanguage as Locale
  }

  return 'en-US'
}
