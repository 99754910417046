import type { DecodedAccessToken } from '@helloextend/extend-api-client'
import type { Action } from '../../actions'

export type AuthDecodedAccessTokenReducerState = DecodedAccessToken | null

export const initialState: AuthDecodedAccessTokenReducerState = null

export default function decodedAccessToken(
  state = initialState,
  action: Action,
): AuthDecodedAccessTokenReducerState {
  switch (action.type) {
    case 'USER_LOGIN_SUCCESS':
      return action.payload.decodedAccessToken
    case 'USER_LOGOUT':
      return null
    default:
      return state
  }
}
