import type { Reply, Slot } from '@helloextend/extend-api-client'
import type {
  ConnectRequest,
  ConnectResponse,
  UpdateRequest as IncredibotUpdateRequest,
  UpdateResponse,
} from '@extend-incredibot/types'

export interface ClaimSessionLog {
  id: string
  sessionId: string
  createdAt: number
  // slot and slot value are optional as they will be missing in the connect endpoint call
  slot?: Slot
  slotValue?: string | number
  reply: Reply
}

export interface UpdateRequest {
  accessToken: string
  data: IncredibotUpdateRequest
}

export type { ConnectRequest, ConnectResponse, UpdateResponse }
