import type { FC } from 'react'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { COLOR, bp } from '@helloextend/customers-ui'
import images from '../../lib/images'

import { Button } from '@extend/zen'
import { logEvent } from '../../analytics'

const FileAClaim: FC = () => {
  const history = useHistory()

  const routeToPlans = (): void => {
    logEvent('Claims - Clicks', 'View My Plans')
    history.push('/my_plans')
  }

  const routeToSupport = (): void => {
    logEvent('Claims - Clicks', 'Contact Support')
    history.push('/contact')
  }
  return (
    <FileAClaimWrapper data-cy="file-a-claim-wrapper">
      <Container>
        <Header>
          <StyledImage alt="kaley" src={images.kaleySmall} />
          <FileClaimTitle>Looking to file a claim?</FileClaimTitle>
        </Header>
        <Content>
          Head over to the <strong>My Plans</strong> tab, find your product, and <br /> select{' '}
          <strong>File a Claim</strong> to get started.
        </Content>
        <ButtonsWrapper>
          <Button
            data-cy="view-plans-button"
            onClick={routeToPlans}
            text="View My Plans"
            fillContainer
            size={isMobile ? 'regular' : 'large'}
          />
          <Button
            data-cy="view-contact-support"
            onClick={routeToSupport}
            text="Contact Support"
            emphasis="low"
            fillContainer
            size={isMobile ? 'regular' : 'large'}
          />
        </ButtonsWrapper>
      </Container>
    </FileAClaimWrapper>
  )
}

const FileAClaimWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '70vh',
  justifyContent: 'center',
  [bp.mobile]: {
    marginTop: 32,
  },
  [bp.desktop]: {
    marginTop: 56,
  },
})

const Container = styled.div({
  maxWidth: 520,
})

const Header = styled.div({
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'row',
})

const FileClaimTitle = styled.div({
  color: COLOR.NEUTRAL[1000],
  fontWeight: 600,
  paddingLeft: '8px',
  [bp.mobile]: {
    fontSize: '28px',
    lineHeight: '36px',
  },
  [bp.desktop]: {
    fontSize: '44px',
    lineHeight: '52px',
  },
})

const Content = styled.div({
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  color: COLOR.NEUTRAL[800],
  fontWeight: 400,
  paddingTop: '16px',
  wordBreak: 'break-word',
  [bp.mobile]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: '20px',
    lineHeight: '28px',
  },
})

const ButtonsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  [bp.mobile]: {
    paddingTop: '24px',
  },
  [bp.desktop]: {
    paddingTop: '48px',
  },
})

const StyledImage = styled.img({
  width: '48px',
  height: '48px',
})

export { FileAClaim }
