import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { shortUid } from '@extend/client-helpers'
import { bp } from '@extend/client-helpers'
import { COLOR } from '@helloextend/customers-ui'

import type { FeatureElement } from '../../../types/plan-features'

interface FeatureContentProps {
  content: string
  elements?: FeatureElement[] | undefined
  dataCy?: string
}

const FeatureContent: FC<FeatureContentProps> = ({ content, elements, dataCy }) => {
  if (!elements) {
    return <FeatureDescription data-cy={dataCy}>{content}</FeatureDescription>
  }
  const splitContent = content
    .split(/{|}/)
    .map((contentSlice: string) => ({ contentSlice, key: shortUid() }))

  return (
    <FeatureDescription data-cy={dataCy}>
      {splitContent.map(({ key, contentSlice }) => {
        const element = elements.find((currElement) => currElement.elementId === contentSlice)
        if (!element) {
          return <span key={key}>{contentSlice}</span>
        }
        const { style, text } = element
        if (style === 'bold') {
          return (
            <span css={{ fontWeight: 'bold' }} key={key}>
              {text}
            </span>
          )
        }

        return <span key={key}>{text}</span>
      })}
    </FeatureDescription>
  )
}

const FeatureDescription = styled.p({
  color: COLOR.NEUTRAL[800],
  fontSize: 16,
  margin: 0,
  lineHeight: '24px',
  [bp.maxWidthMd]: {
    fontSize: 15,
    lineHeight: '20px',
  },
})

export { FeatureContent }
