/* eslint-disable no-nested-ternary */
import type { FC } from 'react'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { bp } from '@helloextend/customers-ui'
import {
  Badge,
  Button,
  Popover,
  usePopover,
  COLOR,
  MenuButtonItem,
  More,
  OpenInNew,
  ArrowDropDownClose,
  ArrowDropDown,
  Subheading,
  Package,
  Icon,
} from '@extend/zen'
import {
  useGetMerchantServicingSettingsQuery,
  usePrecheckQuery,
} from '@helloextend/extend-api-rtk-query'
import { images } from '../../../lib/assets'
import { isMobile } from 'react-device-detect'
import {
  formatDate,
  getDisplayDetails,
  getIsClaimInProgress,
  missingProfileFieldTooltip,
  mapContractStatusBadge,
} from '../../../lib/helper-functions'
import { logEvent } from '../../../analytics'
import type { ShippingProtectionContract, ShippingProtectionProduct } from '../../../types/contract'
import { mapTrackingData } from '../../../lib/shipping-protection-utils'

export interface ShippingProtectionCardProps {
  contract: ShippingProtectionContract
  onFileClaimClick: (handlerArgs: {
    contractId: string
    isMerchantOwnedIntake?: boolean
    lineItemIds?: string[]
    shipmentId?: string
  }) => void
  isMissingRequiredProfileField?: boolean
}

const statusColorMap: Record<string, 'red' | 'blue' | 'green' | 'yellow' | 'neutral'> = {
  Active: 'green',
  Cancelled: 'red',
  Refunded: 'red',
  Fulfilled: 'neutral',
  Expired: 'neutral',
  Other: 'neutral',
}

const ShippingProtectionCard: FC<ShippingProtectionCardProps> = ({
  contract,
  onFileClaimClick,
  isMissingRequiredProfileField = false,
}) => {
  const history = useHistory()
  const { locale } = useIntl()
  const [isViewAllProductsExpanded, setIsViewAllProductsExpanded] = useState<Record<string, boolean>>({})
  const [isViewAllShipmentsExpanded, setIsViewAllShipmentsExpanded] = useState(false)
  const { triggerRef, popoverRef, isPresent, toggle, triggerBoundingBox } =
    usePopover<HTMLButtonElement>()
  const { data: precheck, isLoading } = usePrecheckQuery({ contractId: contract.id })
  const { data: merchantServicingSettings } = useGetMerchantServicingSettingsQuery({
    sellerId: contract.sellerId,
  })

  const { sellerName, purchaseDate, productsList, trackingInfo } = contract

  const handleContactSupport = (): void => {
    logEvent('My Plans - Contract Card - Clicks', 'Contact Support')
    history.push(`/${locale}/contact`)
  }

  const handleReviewTerms = (): void => {
    logEvent('My Plans - Contract Card - Clicks', 'Review Terms')
    window.open('https://www.extend.com/shipping-protection-guide')
  }

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>): void => {
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.src = images.fallbackImage
  }

  const toggleViewAllProducts = (id: string): void => {
    setIsViewAllProductsExpanded({
      ...isViewAllProductsExpanded,
      [id]: !isViewAllProductsExpanded[id],
    })
  }

  const MappedShippingProducts = ({
    id,
    products,
  }: {
    id: string
    products: ShippingProtectionProduct[]
  }) => {
    const hasManyProducts = products.length > 3
    let numberOfProductsToShow = 3

    const shipmentProducts =
      hasManyProducts && !isViewAllProductsExpanded[id]
        ? numberOfProductsToShow <= 0
          ? []
          : products.slice(0, numberOfProductsToShow)
        : products

    return (
      <>
        <ProductList>
          {shipmentProducts.map((product) => {
            numberOfProductsToShow--
            return (
              <Product key={product.referenceId}>
                <ProductImage
                  data-cy={`sp-card-product-image-${product.referenceId}`}
                  src={product?.imageUrl ? product?.imageUrl : images.defaultCarouselImage}
                  onError={(e) => handleImageError(e)}
                />
                <ProductTitle data-cy="sp-card-product-title">{product?.title}</ProductTitle>
              </Product>
            )
          })}
        </ProductList>
        {hasManyProducts && (
          <Button
            emphasis="low"
            color="neutral"
            text={isViewAllProductsExpanded[id] ? 'View less items' : 'View all items'}
            icon={isViewAllProductsExpanded[id] ? ArrowDropDownClose : ArrowDropDown}
            onClick={() => toggleViewAllProducts(id)}
            data-cy="sp-card-view-more-items-button"
          />
        )}
      </>
    )
  }

  const ShipmentLineItemButtons = ({
    trackingInfo,
    products,
  }: {
    trackingInfo?: ShippingProtectionContract['trackingInfo'][0]
    products: ShippingProtectionProduct[]
  }) => {
    const hasTrackingUrl = Boolean(
      !!trackingInfo?.trackingUrl || !!trackingInfo?.requestTrackingUrl,
    )
    return (
      <ShipmentButtonsWrapper>
        <Button
          color="neutral"
          emphasis="high"
          icon={OpenInNew}
          data-cy={'tracking-url-link'}
          text="Track Package"
          isDisabled={!hasTrackingUrl}
          onClick={() => {
            if (hasTrackingUrl) {
              window.open(trackingInfo?.trackingUrl || trackingInfo?.requestTrackingUrl, '_blank')
            }
          }}
          tooltip="Track on carrier website"
        />
        <Button
          color="neutral"
          emphasis="medium"
          text="Report Shipment Issue"
          isDisabled={!precheck || isClaimFilingDisabled || isMissingRequiredProfileField}
          tooltip={isMissingRequiredProfileField ? missingProfileFieldTooltip : fileClaimTooltip}
          onClick={() =>
            onFileClaimClick({
              contractId: contract.id,
              isMerchantOwnedIntake: merchantServicingSettings?.isMerchantOwnedIntake,
              lineItemIds: products.map(
                (product) => (product as { lineItemId: string }).lineItemId,
              ),
              shipmentId: trackingInfo?.shipmentId,
            })
          }
          data-cy={`sp-card-file-claim-button-${products[0].referenceId}`}
        />
      </ShipmentButtonsWrapper>
    )
  }

  const { isClaimFilingDisabled, fileClaimTooltip, subBadgeText } = getDisplayDetails({
    precheck,
    contract,
  })

  const { trackedProducts, untrackedProducts } = mapTrackingData(contract)
  const hasManyShipments = trackedProducts && trackedProducts?.length > 3
  let numberOfShipmentsToShow = 3
  const shipments =
    hasManyShipments && !isViewAllShipmentsExpanded
      ? numberOfShipmentsToShow <= 0
        ? []
        : trackedProducts.slice(0, numberOfShipmentsToShow)
      : trackedProducts

  return (
    <Wrapper data-contract-id={contract.id} data-cy="shipping-protection-card">
      <Header>
        <HeaderInfo>
          <BadgeWrapper>
            {!isMobile && (
              <>
                <Icon icon={Package} color={COLOR.NEUTRAL[600]} />
                <StyledSubheading data-cy="sp-card-subheading">
                  Shipping Protection
                </StyledSubheading>
              </>
            )}
            {!isLoading && (
              <>
                <Badge
                  text={mapContractStatusBadge(contract.status)}
                  color={statusColorMap[mapContractStatusBadge(contract.status)]}
                  data-cy="sp-card-badge"
                  icon={
                    isMobile
                      ? Package
                      : undefined
                  }
                />
                {getIsClaimInProgress(precheck, contract.productsList) && (
                  <ClaimInProgressWrapper>
                    <Badge
                      emphasis="low"
                      text={subBadgeText || 'Claim in Progress'}
                      data-cy="sp-card-claim-progress-badge"
                      color="green"
                    />
                  </ClaimInProgressWrapper>
                )}
              </>
            )}
          </BadgeWrapper>
          <HeaderTitle data-cy="sp-card-header-title">{`Order from ${sellerName}`}</HeaderTitle>
          <HeaderDetails data-cy="sp-card-header-details">{`${
            trackingInfo?.length ?? 0
          } shipment(s) · ${productsList?.length} item(s) · Purchased ${formatDate(
            purchaseDate,
          )}`}</HeaderDetails>
        </HeaderInfo>
        {!isMobile && (
          <HeaderButtons>
            <Button
              color="neutral"
              emphasis="medium"
              icon={More}
              onClick={toggle}
              ref={triggerRef}
              isToggled={isPresent}
              data-cy="sp-card-more-button"
            />
            <Popover ref={popoverRef} isPresent={isPresent} triggerBoundingBox={triggerBoundingBox}>
              <MenuLinkWrapper>
                <MenuButtonItem onClick={handleReviewTerms} data-cy="sp-card-review-terms-button">
                  Review Terms & Conditions
                </MenuButtonItem>
                <MenuButtonItem
                  onClick={handleContactSupport}
                  data-cy="sp-card-contact-support-button"
                >
                  Contact Support
                </MenuButtonItem>
              </MenuLinkWrapper>
            </Popover>
          </HeaderButtons>
        )}
      </Header>
      <Body>
        {shipments &&
          shipments.map(({ trackingInfo, products }, index) => {
            numberOfShipmentsToShow--
            return (
              !!products?.length && (
                <ShipmentWrapper data-cy="shipment-wrapper" key={trackingInfo.trackingId}>
                  <ShipmentDetails>
                    <ShipmentDetailsTextWrapper data-cy="sp-card-shipment-details">
                      <ShipmentDetailsPretext>{`Ship ${index + 1} · `}</ShipmentDetailsPretext>
                      <ShipmentDetailsText>
                        {trackingInfo.actualDeliveryDate
                          ? `Delivered ${formatDate(trackingInfo.actualDeliveryDate)}`
                          : trackingInfo.estimatedDeliveryDate
                          ? `Arriving ${formatDate(trackingInfo.estimatedDeliveryDate)}`
                          : 'Pending shipment details'}
                      </ShipmentDetailsText>
                    </ShipmentDetailsTextWrapper>
                    <ShipmentLineItemButtons trackingInfo={trackingInfo} products={products} />
                  </ShipmentDetails>
                  <MappedShippingProducts products={products} id={trackingInfo.trackingId} />
                </ShipmentWrapper>
              )
            )
          })}
        {hasManyShipments && (
          <Button
            emphasis="low"
            color="neutral"
            text={isViewAllShipmentsExpanded ? 'View less shipments' : 'View all shipments'}
            icon={isViewAllShipmentsExpanded ? ArrowDropDownClose : ArrowDropDown}
            onClick={() => setIsViewAllShipmentsExpanded(!isViewAllShipmentsExpanded)}
            data-cy="sp-card-view-more-shipments-button"
          />
        )}
        {untrackedProducts.length > 0 && (
          <>
            <ShipmentDetails>
              <ShipmentDetailsText data-cy="sp-card-no-shipment-text">
                Pending shipment details
              </ShipmentDetailsText>
              <ShipmentLineItemButtons products={untrackedProducts} />
            </ShipmentDetails>
            <MappedShippingProducts products={untrackedProducts} id="untracked-products"/>
          </>
        )}
      </Body>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '16px',
  border: '1px solid',
  borderColor: COLOR.NEUTRAL['300'],
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderBottom: '1px solid',
  borderColor: COLOR.NEUTRAL['300'],
  [bp.mobile]: {
    flexDirection: 'column',
    padding: '16px',
  },
  [bp.desktop]: {
    flexDirection: 'row',
    padding: '24px',
  },
})

const Body = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  [bp.mobile]: {
    padding: '16px',
  },
  [bp.desktop]: {
    padding: '24px',
  },
})

const HeaderInfo = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})

const HeaderButtons = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  justifyContent: 'flex-end',
  [bp.mobile]: { alignSelf: 'flex-start' },
  [bp.desktop]: { alignSelf: 'center' },
})

const BadgeWrapper = styled.div({
  display: 'flex',
  alignSelf: 'flex-start',
  alignItems: 'center',
  gap: '8px',
  [bp.mobile]: {
    flexDirection: 'column',
    alignContent: 'flex-start',
  },
  [bp.desktop]: { flexDirection: 'row' },
})

const StyledSubheading = styled(Subheading)({
  color: COLOR.NEUTRAL[600],
  fontWeight: 700,
})

const ClaimInProgressWrapper = styled.div({
  alignSelf: 'flex-start',
})

const MenuLinkWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  padding: '8px',
})

const HeaderTitle = styled.div({
  fontWeight: 700,
  [bp.mobile]: {
    fontSize: '17px',
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: '24px',
    lineHeight: '32px',
  },
})

const HeaderDetails = styled.div({
  fontWeight: 400,
  [bp.mobile]: {
    fontSize: '15px',
    lineHeight: '20px',
  },
  [bp.desktop]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
})

const ShipmentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

const ShipmentDetails = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignSelf: 'stretch',
  [bp.mobile]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  [bp.desktop]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})

const ShipmentButtonsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 8,
  [bp.mobile]: {
    flexDirection: 'column',
  },
  [bp.desktop]: {
    flexDirection: 'row',
  },
})

const ShipmentDetailsTextWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  lineHeight: '28px',
  fontWeight: 700,
  [bp.mobile]: {
    fontSize: '17px',
  },
  [bp.desktop]: {
    fontSize: '18px',
  },
  alignItems: 'center',
})
const ShipmentDetailsText = styled.div({
  fontWeight: 700,
  [bp.mobile]: {
    fontSize: '17px',
    lineHeight: '33px',
  },
  [bp.desktop]: {
    fontSize: '18px',
    lineHeight: '34px',
  },
})
const ShipmentDetailsPretext = styled.div({
  color: COLOR.NEUTRAL['500'],
})

const ProductList = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

const Product = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
})

const ProductImage = styled.img({
  [bp.mobile]: {
    width: '40px',
    height: '40px',
    borderRadius: '4px',
  },
  [bp.desktop]: {
    width: '56px',
    height: '56px',
    borderRadius: '8px',
  },
})

const ProductTitle = styled.div({
  fontWeight: 400,
  [bp.mobile]: {
    fontSize: '15px',
    lineHeight: '20px',
  },
  [bp.desktop]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
})

export { ShippingProtectionCard }
