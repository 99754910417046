import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { isErrorResponse } from '@helloextend/extend-api-fetch'
import { contactFormActions } from '../actions'

type Action = ReturnType<typeof contactFormActions.contactFormSubmit>

export function* contactFormSubmit(action: Action): SagaIterator {
  const formValues = action.payload
  try {
    const response: ApiResponse = yield call(client.incredibot.contact, formValues)

    if (isErrorResponse(response)) {
      yield put(contactFormActions.contactFormSubmitFailure(response.data.message, response.status))
      return
    }

    yield put(contactFormActions.contactFormSubmitSuccess())
  } catch (error) {
    if (error instanceof Error) {
      yield put(contactFormActions.contactFormSubmitFailure(error))
    }
  }
}
