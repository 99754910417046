import type { FC } from 'react'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import type { Element } from '@helloextend/extend-api-client'
import { MessageType } from '@extend-conversations/types'
import { shortUid } from '@extend/client-helpers'
import styled from '@emotion/styled'
import { ChatResendCode } from './chat-resend-code'
import { ChatImage } from './chat-image'
import { ChatLinkedContent } from './chat-linked-content'

interface ChatContentProps {
  content: string
  elements?: Element[]
  image?: { src: string; width: number; height: number }
  type?: string
}

const ChatContent: FC<ChatContentProps> = ({ content, elements, image, type }) => {
  const isImageMessageType = type === MessageType.image
  if (!elements) {
    return (
      <>
        {!isImageMessageType && (
          <div data-cy="chat-content-markdown">
            <ReactMarkdown
              components={{
                a: ({ href, children }) => {
                  const [text] = children
                  return (
                    <ChatLinkedContent
                      key={shortUid()}
                      href={String(href)}
                      text={String(text)}
                      isExternal
                    />
                  )
                },
              }}
            >
              {content}
            </ReactMarkdown>
          </div>
        )}
        <ChatImage image={image} caption={content} />
      </>
    )
  }

  const splitContent = content
    .split(/[{}]/)
    .map((contentSlice: string) => ({ contentSlice, key: shortUid() }))

  return (
    <>
      {splitContent.map(({ key, contentSlice }) => {
        const element = elements.find(
          (currElement) =>
            currElement.elementId === contentSlice || currElement.elementId === 'entireMessage',
        )

        if (!element) {
          return <span key={key}>{contentSlice}</span>
        }

        const { link, resendCodeData, elementType, elementId } = element
        if (link) {
          return <ChatLinkedContent key={key} {...link} data-cy="chat-linked-content" />
        }

        if (resendCodeData) {
          return <ChatResendCode key={key} {...resendCodeData} data-cy="chat-resend-code" />
        }
        if (elementId === 'toggleAction') {
          const isTouchDevice = 'ontouchstart' in window || Boolean(navigator.maxTouchPoints)
          const actionText = isTouchDevice ? 'Tap this bubble' : 'Click this bubble'
          return (
            <Bold key={key} data-cy="chat-bold">
              {actionText}
            </Bold>
          )
        }

        if (elementType === 'Bold') {
          return (
            <Bold key={key} data-cy="chat-bold">
              {contentSlice}
            </Bold>
          )
        }

        return null
      })}
      <ChatImage image={image} caption={content} data-cy="chat-image" />
    </>
  )
}

const Bold = styled.span({
  fontWeight: 700,
})

export default ChatContent
