import type { RefForwardingComponent } from 'react'
import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import type { Element } from '@helloextend/extend-api-client'
import ChatTyping from './chat-typing'
import ChatLinkedContent from './chat-content'

interface ChatHiddenDivProps {
  isTyping: boolean
  image?: { src: string; width: number; height: number }
  content: string
  elements?: Element[]
}

const ChatHiddenDiv: RefForwardingComponent<HTMLDivElement, ChatHiddenDivProps> = (
  { isTyping, content, elements, image },
  ref,
) => (
  <div style={{ display: 'flex' }}>
    <HiddenDiv ref={ref}>
      {isTyping ? (
        <ChatTyping />
      ) : (
        <>
          <ChatLinkedContent image={image} elements={elements} content={content} />
        </>
      )}
    </HiddenDiv>
  </div>
)

export const HiddenDiv = styled.div({
  opacity: 0,
  fontSize: '16px',
  position: 'relative',
  left: '-1000px',
  padding: '8px 16px',
})

export default forwardRef(ChatHiddenDiv)
