import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

import { bp } from '../../utils/breakpoints'

interface LinkText {
  displayText: string
}

interface LinkPropsOnClick extends LinkText {
  handleOnClick: () => void
}

interface LinkPropsHref extends LinkText {
  href: string
}

export type LinkProps = LinkPropsOnClick | LinkPropsHref | LinkText

export interface FooterProps {
  className?: string
  links?: LinkProps[]
}

const Footer: FC<FooterProps> = ({ className, links }) => {
  const linksRender = links?.map((link: LinkProps) => {
    if ('handleOnClick' in link) {
      return (
        <LinkButton key={link.displayText} onClick={link.handleOnClick}>
          {link.displayText}
        </LinkButton>
      )
    }

    if ('href' in link) {
      return (
        <Link key={link.displayText} href={link.href} target="_blank" rel="noopener noreferrer">
          {link.displayText}
        </Link>
      )
    }

    return <FooterText>{link.displayText}</FooterText>
  })

  return (
    <FooterWrapper className={className} role="main">
      {!links && (
        <LinksSection>
          <Link href="https://www.extend.com/privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </Link>
          <Link href="https://www.extend.com/terms" target="_blank" rel="noopener noreferrer">
            Terms and Conditions
          </Link>
        </LinksSection>
      )}
      {links && <LinksSection>{linksRender}</LinksSection>}
    </FooterWrapper>
  )
}

const FooterText = styled.div({
  textAlign: 'center',
  color: COLOR.NEUTRAL[1000],
  font: 'normal',
  fontWeight: 300,
  fontSize: 14,
  lineHeight: '22px',
})

const Link = styled.a({
  color: COLOR.NEUTRAL[1000],
  font: 'normal',
  fontWeight: 300,
  fontSize: 14,
  lineHeight: '22px',
  '&:hover': { textDecoration: 'underline' },
})

const LinkButton = styled.button({
  textAlign: 'left',
  color: COLOR.NEUTRAL[1000],
  font: 'normal',
  fontWeight: 300,
  fontSize: 14,
  lineHeight: '22px',
  border: 'none',
  background: COLOR.WHITE,
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
})

const LinksSection = styled.div({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'left',
  [bp.mobile]: {
    flexDirection: 'column',
    gap: 16,
    margin: '20px 0 0 20px',
  },
  [bp.desktop]: {
    flexDirection: 'row',
    gap: 40,
    margin: '32px 0 32px 40px',
  },
})

const FooterWrapper = styled.div({
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  background: COLOR.WHITE,
  margin: 0,
  borderTop: `1px solid ${COLOR.NEUTRAL[100]}`,
  width: '100vw',
  minWidth: '100vw',
  bottom: 0,
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  [bp.mobile]: {
    height: 152,
  },
  [bp.desktop]: {
    height: 86,
  },
})

export { Footer }
