import type { ConsumerProfileResponse } from '@helloextend/extend-api-rtk-query/src/my-extend/types'
import { formatPhoneNumberLocal } from '@extend/client-helpers'
import { formatAddress } from '../utils/format-address'

export interface AccountInfo {
  name: string
  phoneNumber: string
  email: string
  shippingAddress: ConsumerProfileResponse['addresses'][0]
}

export const getAccountInfo = (consumerProfile: ConsumerProfileResponse): AccountInfo => {
  return {
    name: consumerProfile.name,
    phoneNumber: formatPhoneNumberLocal(getPrimaryPhone(consumerProfile)),
    email: getPrimaryEmail(consumerProfile),
    shippingAddress: formatAddress(getShippingAddress(consumerProfile)),
  }
}

export const getPrimaryEmail = (consumerProfile: ConsumerProfileResponse): string => {
  if (!consumerProfile.emails) return ''
  const emailKeys = Object.keys(consumerProfile.emails)
  const primaryEmailKey = emailKeys.find((key) => consumerProfile.emails[key].isPrimary)
  return primaryEmailKey || emailKeys[0]
}

export const getPrimaryPhone = (consumerProfile: ConsumerProfileResponse): string => {
  if (!consumerProfile.phones) return ''
  const phoneKeys = Object.keys(consumerProfile.phones)
  const primaryPhoneKey = phoneKeys.find((key) => consumerProfile.phones[key].isPrimary)
  return primaryPhoneKey || phoneKeys[0]
}

export const getShippingAddress = (
  consumerProfile: ConsumerProfileResponse,
): ConsumerProfileResponse['addresses'][0] => {
  return consumerProfile.addresses?.find((address) => address.type === 'shipping')
}
