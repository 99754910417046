import type { FC } from 'react'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { COLOR, Text } from '@helloextend/customers-ui'
import { bp } from '@extend/client-helpers'
import type { PlanDetails } from '@helloextend/extend-api-client'
import { useIntl } from 'react-intl'
import { svgs } from '../../../lib/assets'

interface FinePrintBannerProps {
  planId: string
  planDetails: PlanDetails
}

const FinePrintBanner: FC<FinePrintBannerProps> = ({ planId, planDetails }) => {
  const { locale } = useIntl()

  return (
    <Banner>
      <TermsAndConditionsHeader>{planDetails.planDetails.finePrintHeader}</TermsAndConditionsHeader>
      <TermsAndConditionsWrapper>
        <TermsAndConditions>
          <StyledLink to={`/${locale}/warranty_terms/${planId}`} target="_blank">
            {planDetails.planDetails.finePrintTermsAndConditions}
            <svgs.External />
          </StyledLink>
        </TermsAndConditions>
      </TermsAndConditionsWrapper>
    </Banner>
  )
}

const StyledLink = styled(Link)({
  display: 'flex',
  color: COLOR.BLUE[800],
  flexDirection: 'row',
  fontSize: 16,
  [bp.md]: {
    fontSize: 20,
  },
  '&:visited': {
    color: COLOR.BLUE[800],
  },
  cursor: 'pointer',
  '& svg': {
    display: 'inline',
    height: 11,
    width: 11,
    marginTop: 6,
    transform: 'translateX(6px)',
    [bp.md]: {
      marginTop: 11,
    },
  },
})

const Banner = styled.section({
  backgroundColor: COLOR.NEUTRAL[100],
  display: 'block',
  margin: 0,
  marginBottom: 32,
  marginLeft: -32,
  padding: 32,
  textAlign: 'center',
  width: '100vw',
  [bp.lg]: {
    marginBottom: 95,
    marginLeft: -40,
  },
  [bp.xl]: {
    marginLeft: -112,
  },
  [bp.maxWidth]: {
    width: '100vw',
    marginLeft: 0,
  },
})

const TermsAndConditionsHeader = styled.h4({
  fontSize: 18,
  margin: '0 0 8px 0',
  [bp.md]: {
    margin: '0 0 16px 0',
    fontSize: 26,
  },
})

const TermsAndConditionsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
})

const TermsAndConditions = styled(Text)({
  display: 'flex',
  margin: 0,
  fontSize: 16,
  [bp.md]: {
    fontSize: 20,
  },
})

export { FinePrintBanner }
