import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, bp } from '@helloextend/customers-ui'

import { images } from '../../../lib/assets'

const ChatKaleyIntro: FC = () => {
  return (
    <Wrapper>
      <ChatHead />
      <Header>
        Hi! I&#39;m Kaley
        <HandWave src={images.handWave} alt="hand wave" />
      </Header>
      <Content>
        I am your virtual claims specialist.
        <br />
        How can I help you today?
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  justifyContent: 'center',
  maxWidth: 302,
  [bp.mobile]: {
    marginBottom: 42,
  },
  [bp.desktop]: {
    marginBottom: 56,
  },
})

const ChatHead = styled.div({
  width: 80,
  height: 80,
  backgroundImage: `url(${images.kaleyLarge})`,
  backgroundSize: 'contain',
  marginBottom: 16,
  alignSelf: 'center',
  backgroundColor: COLOR.BLUE[300],
  borderRadius: '50%',
})

const HandWave = styled.img({
  display: 'inline',
  width: 16,
  height: 16,
  marginLeft: 4,
})

const Header = styled.div({
  textAlign: 'center',
  marginBottom: 8,
  fontWeight: 800,
  fontSize: 25,
  lineHeight: '34px',
})

const Content = styled.div({
  textAlign: 'center',
  fontSize: 20,
  lineHeight: '27px',
})

export { ChatKaleyIntro }
