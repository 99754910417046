import type { FC } from 'react'
import React, { Suspense, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { SplashScreen } from '@helloextend/component-commons'
import { PageLayout } from '@helloextend/customers-ui'
import { IntlProvider } from 'react-intl'
import { TrackingProvider } from '../lib/client-analytics'
import { Banner } from '../components/common'
import { useAnalytics, AnalyticsWrapper } from '../analytics'
import { Header } from '../components/header'
import { PortalFooter } from '../components/footer'
import { Routes as ConsumerRoutes } from './routes'
import type { SubRouterProps } from './types'
import { determineLocale } from './utils'
import frenchCanadaMessages from '../translations/compiled/fr-CA.json'
import englishCanadaMessages from '../translations/compiled/en-CA.json'
import englishUSmessages from '../translations/compiled/en-US.json'
import { MyExtendLoader } from '../devlink'
import '../devlink/global.css'

const ANIMATION_TIMEOUT = 100

const Component: FC<SubRouterProps> = ({ isLoggedIn }) => {
  const { initialized } = useAnalytics()
  const { pathname } = useLocation()
  const locale = determineLocale(pathname)

  const [messages, setMessages] = useState({})
  useEffect(() => {
    if (!Object.keys(messages).length) {
      const loadLocaleData = (): void => {
        switch (locale) {
          case 'fr-CA':
            setMessages(frenchCanadaMessages)
            break
          case 'en-CA':
            setMessages(englishCanadaMessages)
            break
          default:
            setMessages(englishUSmessages)
            break
        }
      }
      loadLocaleData()
    }
  }, [locale, messages])

  const isAuthRoute = pathname.includes('/authentication')
  const [shouldLoadSubRouter, setShouldLoadSubRouter] = useState<boolean>(!isAuthRoute)

  // Hides the router until the load animation starts to prevent flickering
  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldLoadSubRouter(true)
    }, ANIMATION_TIMEOUT)

    return () => clearTimeout(timer)
  }, [])

  const subRouter = (
    <TrackingProvider>
      <IntlProvider locale={locale} defaultLocale="en-US" messages={messages}>
        <Suspense fallback={<SplashScreen data-cy="splash-screen" />}>
          <Header isLoggedIn={isLoggedIn} />
          <PageLayout>
            <Banner />
            <AnalyticsWrapper initialized={initialized}>
              <ConsumerRoutes isLoggedIn={isLoggedIn} locale={locale} />
            </AnalyticsWrapper>
          </PageLayout>
          <PortalFooter locale={locale} />
        </Suspense>
      </IntlProvider>
    </TrackingProvider>
  )

  return (
    <>
      {isAuthRoute && <MyExtendLoader />}
      {shouldLoadSubRouter && subRouter}
    </>
  )
}

export { Component as SubRouter }
