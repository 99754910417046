import { EXTEND_ENV } from '@helloextend/client-constants'
import type { FC } from 'react'
import React from 'react'
import { connect } from 'react-redux'
import { COLOR, ContentLayout, Spinner, bp } from '@helloextend/customers-ui'
import {
  useListInsuranceClaimsQuery,
  useSearchContractsV1Query,
} from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { ContractType } from '@helloextend/extend-api-client'
import type { RootState } from '../../reducers'
import * as selectors from '../../reducers/selectors'
import { ErrorComponent } from './error-component'
import type { Claim } from '../../types/claim'
import type { ContractsSearchIndividual } from '../../types/contract'
import { isEmpty } from 'lodash'
import { FileAClaim } from './file-a-claim'
import { mapToClaimDetails } from '../../lib/helper-functions'
import { ViewAllClaims } from './view-all-claims'

interface ClaimsPageProps {
  decodedAccessToken: ReturnType<typeof selectors.getDecodedAccessToken>
}

const Component: FC<ClaimsPageProps> = ({ decodedAccessToken }) => {
  const email = decodedAccessToken?.email
  const phone = decodedAccessToken?.phone_number

  const {
    data: { items: fetchedClaims } = {},
    isLoading: isLoadingClaims,
    error: claimsQueryError,
  } = useListInsuranceClaimsQuery({
    containsCustomerEmail: email,
    containsCustomerPhone: phone,
    minLimit: 500,
  })

  // Accounts in lower environments can potentially have hundreds of claims, and each claim requires a request to get service orders
  // and a request to get merchant servicing settings. This will limit those accounts to 10 displayed claims.
  let claims = fetchedClaims

  if (EXTEND_ENV !== 'production' && fetchedClaims) {
    claims = fetchedClaims.slice(0, 10)
  }

  const {
    data: { contracts } = {},
    isLoading: isLoadingContracts,
    error: contractsQueryError,
  } = useSearchContractsV1Query({
    customerEmail: email,
    customerPhone: phone,
    typeFilter: [
      ContractType.PCRS,
      ContractType.SHIPPING_PROTECTION,
      ContractType.PRODUCT_PROTECTION_BUNDLE,
      ContractType.CATEGORY,
    ],
    // needed to fully display productsList
    showAll: true,
  })

  const claimDetails = mapToClaimDetails(
    (claims as unknown as Claim[]) ?? [],
    (contracts as unknown as ContractsSearchIndividual[]) ?? [],
  )
  const hasAtLeastOneMatchingContract = Object.values(claimDetails).some(
    (claimDetail) => claimDetail.contract,
  )

  return (
    <ContentLayout pageTitle="My Claims">
      <ErrorComponent
        claimsError={
          (claimsQueryError && 'error' in claimsQueryError && claimsQueryError?.error) || ''
        }
        contractsError={
          (contractsQueryError && 'error' in contractsQueryError && contractsQueryError?.error) ||
          ''
        }
      />
      {isLoadingClaims || isLoadingContracts ? (
        <SpinnerLoading data-cy="spinner-loading">
          <Spinner size="md" />
        </SpinnerLoading>
      ) : (
        <>
          {isEmpty(claims) || !hasAtLeastOneMatchingContract ? (
            <>
              <FileAClaim />
            </>
          ) : (
            <>
              <ClaimsPageSubheader data-cy="claims-page-subheader">
                Stay Updated on Your Claims
              </ClaimsPageSubheader>
              <ClaimsPageHeader data-cy="claims-page-header">Claims</ClaimsPageHeader>
              <ViewAllClaims claimDetails={claimDetails} />
            </>
          )}
        </>
      )}
    </ContentLayout>
  )
}

const ClaimsPageHeader = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  textAlign: 'end',
  fontWeight: 800,
  color: COLOR.NEUTRAL[1000],
  lineHeight: 'auto',
  [bp.mobile]: {
    margin: '0px 20px 0px 36px',
    fontSize: 28,
    fontWeight: 700,
  },
  [bp.tablet]: {
    margin: '0px 80px 0px 80px',
    fontSize: 40,
  },
  [bp.desktop]: {
    margin: '0px 120px 24px 255px',
    fontSize: 44,
  },
})

const ClaimsPageSubheader = styled.div({
  color: COLOR.NEUTRAL[900],
  fontFamily: 'Nunito Sans',
  fontWeight: 400,
  lineHeight: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  textAlign: 'end',
  [bp.mobile]: {
    margin: '24px 20px 0px 36px',
    fontSize: 15,
  },
  [bp.tablet]: {
    margin: '24px 80px 0px 80px',
    fontSize: 15,
  },
  [bp.desktop]: {
    margin: '56px 120px 0px 255px',
    fontSize: 20,
  },
})

export const SpinnerLoading = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '50px 20px 0',
})

const ClaimsPage = connect((state: RootState): ClaimsPageProps => {
  return {
    decodedAccessToken: selectors.getDecodedAccessToken(state),
  }
})(Component)

export type { ClaimsPageProps }
export { Component, ClaimsPage }
