import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import type { ContractSearch } from '@extend-contracts/client/dist/api-rest/versions/2023-07-01/models'
import { ConsumerProfileResponse } from '@helloextend/extend-api-rtk-query/src/my-extend/types'
import { isEmpty } from 'lodash'
import { CategoryCard } from './contract-cards/category-card'
import { ShippingProtectionCard } from './contract-cards/shipping-protection-card'
import { ProductProtectionCard } from './contract-cards/product-protection-card'
import type {
  ExtendedWarrantyContract,
  ShippingProtectionContract,
  CategoryContract,
  ProductProtectionBundleContract,
} from '../../types/contract'
import { getAccountInfo } from '../../lib/consumer-profile-utils'
import * as selectors from '../../reducers/selectors'
import { LDFlag } from '../../constants/ld-flags'

interface CardMapperProps {
  contract: ContractSearch
  handleFileClaimClick: (handlerArgs: {
    contractId: string
    lineItemId?: string
    isMerchantOwnedIntake?: boolean
  }) => void
}

const CardMapper: React.FC<CardMapperProps> = ({ contract, handleFileClaimClick }) => {
  const {
    [LDFlag.BundleContractClaims]: FF_BUNDLE_CONTRACT_CLAIMS,
    [LDFlag.ProfileVerification]: FF_PROFILE_VERIFICATION_ENABLED,
  } = useFlags()
  const contractType = contract.type
  const consumerProfile = useSelector(selectors.getConsumerProfile)
  const accountInfo = !isEmpty(consumerProfile) ? getAccountInfo(consumerProfile as ConsumerProfileResponse) : undefined
  const isMissingRequiredProfileField =
    FF_PROFILE_VERIFICATION_ENABLED &&
    !!accountInfo &&
    (!accountInfo?.phoneNumber || !accountInfo?.email)

  return (
    <div>
      {contractType === 'category' && (
        <CategoryCard
          key={contract.id}
          contract={contract as CategoryContract}
          onFileClaimClick={handleFileClaimClick}
          isMissingRequiredProfileField={isMissingRequiredProfileField}
        />
      )}
      {contractType === 'shipping_protection' && (
        <ShippingProtectionCard
          key={contract.id}
          contract={contract as ShippingProtectionContract}
          onFileClaimClick={handleFileClaimClick}
          isMissingRequiredProfileField={isMissingRequiredProfileField}
        />
      )}
      {contractType === 'pcrs' && (
        <ProductProtectionCard
          key={contract.id}
          contract={contract as ExtendedWarrantyContract}
          onFileClaimClick={handleFileClaimClick}
          isMissingRequiredProfileField={isMissingRequiredProfileField}
        />
      )}
      {FF_BUNDLE_CONTRACT_CLAIMS && contractType === 'product_protection_bundle' && (
        <ProductProtectionCard
          key={contract.id}
          contract={contract as ProductProtectionBundleContract}
          onFileClaimClick={handleFileClaimClick}
          isMissingRequiredProfileField={isMissingRequiredProfileField}
        />
      )}
    </div>
  )
}

export default CardMapper
