import type { ServiceOrder } from '@helloextend/extend-api-client'

export const isClaimCompleted = (serviceOrder?: ServiceOrder[]): boolean => {
  if (
    !!serviceOrder &&
    ['fulfilled', 'payment_requested', 'payment_approved'].includes(serviceOrder[0]?.status)
  ) {
    return true
  }
  return false
}

export const calcActiveStep = (serviceOrder?: ServiceOrder[], isRepair?: boolean): number => {
  const serviceOrderStatus = serviceOrder?.[0]?.status
  if (isClaimCompleted(serviceOrder)) {
    return isRepair && serviceOrderStatus !== 'fulfilled' ? 1 : 2
  }

  if (
    (!!serviceOrder && serviceOrderStatus === 'replacement_approved') ||
    (!!serviceOrder && serviceOrderStatus === 'checked_in') ||
    (!!serviceOrder && serviceOrderStatus === 'repair_started') ||
    (!!serviceOrder && serviceOrderStatus === 'closed')
  ) {
    return 1
  }

  if (!!serviceOrder && serviceOrder[0]?.status === 'action_required') {
    return 2
  }

  return 0
}
