import { customLogger } from '@extend/client-helpers'
import type { Address } from '@helloextend/extend-api-client'
import type { AutocompletePrediction, GeocoderAddressComponent } from './maps-service'

export interface OptionType {
  value: string
  label: string
}

export const getNewOptions = (predictions: AutocompletePrediction[]): OptionType[] => {
  const addressesOnly = predictions.filter(
    (prediction) =>
      prediction.types.includes('street_address') || prediction.types.includes('premise'),
  )
  const newOptions = addressesOnly
    .map((prediction) => ({
      value: prediction?.place_id,
      label: prediction?.description,
    }))
    .reverse()
  return newOptions
}

/*
 * Finds a specific type of component from a Google Maps GeocoderAddressComponent array
 * The Types parameter will be iterated through until an address component is found
 * i.e. For cities, the type could be either locality or sublocality, thus both types should be supplied
 */
export const getAddressComponent = (
  types: GeocoderAddressComponent['types'],
  addressComponents: GeocoderAddressComponent[],
): string | null => {
  if (!types.length || !addressComponents.length) return null

  let addressComponent: google.maps.GeocoderAddressComponent | undefined
  types.every((type) => {
    addressComponent = addressComponents.find((component) => component.types.includes(type))
    return !addressComponent
  })

  if (addressComponent) {
    return addressComponent.short_name
  }

  customLogger.error(`Error occurred when parsing Google Maps address details object`, {
    timestamp: Date.now(),
    errorMessage: `Address Component Types: ${JSON.stringify(
      types,
    )} could not be found in any of the given Address Components: ${JSON.stringify(
      addressComponents,
    )}`,
  })
  return null
}

export const getFullAddress = (address_components: GeocoderAddressComponent[]): Address => {
  return {
    address1: `${getAddressComponent(['street_number'], address_components)} ${getAddressComponent(
      ['route'],
      address_components,
    )}`,
    city: `${getAddressComponent(
      ['locality', 'sublocality', 'administrative_area_level_3'],
      address_components,
    )}`,
    provinceCode: `${getAddressComponent(['administrative_area_level_1'], address_components)}`,
    postalCode: `${getAddressComponent(['postal_code'], address_components)}`,
    countryCode: `${getAddressComponent(['country'], address_components)}`,
  }
}
