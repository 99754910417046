import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, Checkbox } from '@extend/zen'

interface RadioInputProps {
  checked: boolean
  name: string
  label: string
  value: string
  id: string
  onChange: () => void
  imageUrl?: string
}

const ProductListItem: FC<RadioInputProps> = ({
  checked,
  name,
  label,
  value,
  id,
  onChange,
  imageUrl,
}) => {
  return (
    <Card htmlFor={id} onClick={onChange} data-cy="product-list-item">
      <Label checked={checked}>
        {imageUrl && (
          <ImageWrapper data-cy="product-image">
            <img src={imageUrl} alt={label} />
          </ImageWrapper>
        )}
        <LabelText>{label}</LabelText>
        <LabelCheck onClick={onChange}>
          <StyledCheckbox
            checked={checked}
            label=""
            name={name}
            value={value}
            onChange={onChange}
          />
        </LabelCheck>
      </Label>
    </Card>
  )
}

const Card = styled.label()

const ImageWrapper = styled.div({
  minWidth: 48,
  width: 48,
  height: 48,
  backgroundColor: COLOR.NEUTRAL[100],
  marginRight: 16,
  img: {
    height: '100%',
    objectFit: 'cover',
    overflow: 'hidden',
    width: '100%',
    borderRadius: 4,
  },
})

interface LabelProps {
  checked?: boolean // Include any other props the component needs
}

const Label = styled.div<LabelProps>`
  align-items: center;
  display: flex;
  justify-content: left;
  border: 1px solid ${COLOR.NEUTRAL[200]};
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 8px;
  min-height: 48px;
  padding: 8px;

  ${(props) =>
    props.checked
      ? `
    {
      border-color: ${COLOR.BLUE[700]};
      color: ${COLOR.BLUE[700]};
      font-weight: 600;
      background-color: ${COLOR.NEUTRAL[100]};
    }
  `
      : ''}
`

const LabelCheck = styled.div({
  display: 'flex',
  justifyContent: 'right',
  marginInlineStart: 'auto',
})

const LabelText = styled.div()

const StyledCheckbox = styled(Checkbox)({
  opacity: 0,
  position: 'absolute',
})

export type { RadioInputProps }
export { ProductListItem }
