import { validatePhoneNumber } from '@extend/client-helpers'
import { InferType, object, string } from 'yup'

export const schema = object()
  .shape({
    name: string().trim().required('Name is required'),
    phoneNumber: string()
      .trim()
      .required('Phone number is required')
      .test('is-valid-e.164-number', 'Wrong phone number', (value) => {
        return validatePhoneNumber(value, 'US')
      }),
    email: string().trim().required('Email is required').email('Wrong email'),
    shippingAddress: string().required('Shipping address is required'),
  })
  .defined()

export type Values = InferType<typeof schema>
