import type { SagaIterator } from 'redux-saga'
import { call, put, select, delay } from '@redux-saga/core/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/extend-api-fetch'
import { customLogger } from '@extend/client-helpers'
import * as selectors from '../../reducers/selectors'
import { chatActions } from '../../actions'
import { formatUserMessage, generateBotMessage } from '../../lib/chat-utils'
import cancelableLoadBotContent from './cancelable-load-bot-content'

type Action = ReturnType<typeof chatActions.chatSessionUpdate>

export default function* sessionUpdate(action: Action): SagaIterator {
  const { message = '', value, slot } = action.payload

  yield put(chatActions.chatSessionUpdateRequest())
  try {
    const accessToken = yield select(selectors.getChatAccessToken)

    // Add the user's response to the messages array if it exists
    if (message) {
      yield put(chatActions.chatMessagesAdd(formatUserMessage({ content: message, slot })))
    }

    yield delay(1000)

    // Immediately load a `dummy` bot message when the user responds
    const firstBotMessage = generateBotMessage()
    yield put(chatActions.chatMessagesAdd(firstBotMessage))


    // start the response timer
    const startRequestTime = new Date().getTime()
    // Fetch the next messages and prompt
    const response = yield call(client.incredibot.update, slot, accessToken, {
      slotValue: value,
    })

    // end the response timer
    const endRequestTime = new Date().getTime()
    const requestTimeout = endRequestTime - startRequestTime

    if (isErrorResponse(response)) {
      yield put(chatActions.chatSessionUpdateFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(chatActions.chatSessionUpdateFailure('An unknown error occurred', response.status))
      return
    }

    yield put(chatActions.chatSessionUpdateSuccess(response.data))

    const reply = response.data
    // Load the content
    yield call(cancelableLoadBotContent, reply, slot, firstBotMessage.key, requestTimeout)
  } catch (e) {
    if (e instanceof Error) {
      customLogger.warn(`Error calling /update`, {
        timestamp: Date.now(),
        err: e.message,
      })
      yield put(chatActions.chatSessionUpdateFailure(e.message))
    }
  }
}
