import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/customers-ui'

const ChatTyping: FC = () => {
  const [highlighted, setHighlighted] = useState(0)

  useEffect(() => {
    const animate = setInterval(() => {
      setHighlighted(highlighted === 2 ? 0 : highlighted + 1)
    }, 300)
    return () => clearInterval(animate)
  }, [highlighted])

  const circles = [0, 1, 2].map((index) => {
    return <Circle highlighted={index === highlighted} key={index} />
  })

  return <CirclesWrapper data-cy="chat-typing-circles-wrapper">{circles}</CirclesWrapper>
}

interface CircleProps {
  highlighted: boolean
}

export const Circle = styled.div<CircleProps>(
  {
    height: '8px',
    width: '8px',
    margin: '2px',
    borderRadius: '50%',
  },
  (props) => ({
    background: props.highlighted ? COLOR.NEUTRAL[500] : COLOR.NEUTRAL[800],
  }),
)

export const CirclesWrapper = styled.div({
  display: 'flex',
})

export default ChatTyping
