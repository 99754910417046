import type { FC, FormEvent, ChangeEvent } from 'react'
import React from 'react'
import { createPortal } from 'react-dom'
import { animated, useTransition } from 'react-spring'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import type { MultiSelectPrompt } from '@helloextend/extend-api-client'
import { COLOR } from '@helloextend/customers-ui'

import svgs from '../../../../../lib/svgs'
import { Checkbox } from './checkbox'

interface MultiSelectModalProps {
  currentValue: number | null
  isOpen: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onClickClose: () => void
  onReset: () => void
  onSubmit: (e: FormEvent) => void
  prompt: MultiSelectPrompt
}

const Modal: FC<MultiSelectModalProps> = ({
  currentValue,
  prompt,
  isOpen,
  onChange,
  onClickClose,
  onReset,
  onSubmit,
}) => {
  const { options, slot, title } = prompt
  const transitionProps = useTransition(isOpen, null, {
    config: { duration: 300 },
    from: { transform: 'scale(0.80)', opacity: 0 },
    enter: { transform: 'scale(1)', opacity: 1 },
    leave: { transform: 'scale(0.80)', opacity: 0 },
  })

  // Wrapping the portal in Fragments/divs in order to follow
  //  FunctionComponent type, and due to an active bug in useTransition
  //  in which portals wrapped in useTransition are mounted twice
  //  Source: https://github.com/pmndrs/react-spring/issues/1179
  return (
    <>
      {transitionProps.map(
        ({ item, key, props }) =>
          item && (
            <div key={key}>
              {createPortal(
                <Portal data-cy="modal-portal">
                  <Overlay />
                  <Form
                    role="dialog"
                    aria-labelledby="dialogTitle"
                    onSubmit={onSubmit}
                    onReset={onReset}
                    style={props}
                  >
                    <Header>
                      <CloseButton type="button" aria-label="close" onClick={onClickClose}>
                        <svgs.CloseModal />
                      </CloseButton>
                      <Title id="dialogTitle" data-cy="modal-title">
                        {title}
                      </Title>
                    </Header>
                    <Content data-cy="modal-content">
                      {options.map(
                        ({ outputText, value, imageUrl, title: promptOptionTitle }, index) => (
                          <Checkbox
                            checked={currentValue === index}
                            key={`${value}-${promptOptionTitle}`}
                            label={outputText}
                            name={slot}
                            id={`${value}-${promptOptionTitle}`}
                            onChange={onChange}
                            value={index}
                            imageUrl={imageUrl}
                          />
                        ),
                      )}
                    </Content>
                    <Footer>
                      <FormButton
                        data-cy="form-button"
                        type="submit"
                        disabled={currentValue === null}
                      >
                        Submit
                      </FormButton>
                    </Footer>
                  </Form>
                </Portal>,
                document?.body,
              )}
            </div>
          ),
      )}
    </>
  )
}

const Portal = styled.div({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 2,
})

const Overlay = styled.div({
  backgroundColor: COLOR.BLACK,
  height: '100%',
  left: 0,
  opacity: 0.75,
  top: 0,
  width: '100%',
})

const Form = styled(animated.form)({
  backgroundColor: COLOR.WHITE,
  position: 'fixed',
  borderRadius: 4,
  width: 304,
  [bp.lg]: {
    width: 592,
  },
})

const Header = styled.div({
  padding: 16,
  [bp.lg]: {
    padding: 24,
  },
})

const Title = styled.h3({
  color: COLOR.NEUTRAL[700],
  fontSize: 15,
  fontWeight: 600,
  lineHeight: '20px',
  margin: '25px 0 0',
  textAlign: 'center',
  [bp.lg]: {
    margin: '29px 0 0',
  },
})

// forced right-margin rules are applied
// to allocate a gap for the content scrollbar
const Content = styled.div({
  padding: '0 16px 0 20px',
  marginRight: 4,
  maxHeight: 305,
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: 4,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 2,
    backgroundColor: COLOR.NEUTRAL[600],
    height: 65,
  },
  [bp.lg]: {
    maxHeight: 346,
    padding: '0 28px 0 32px',
  },
})

const Footer = styled.div({
  alignItems: 'flex-end',
  display: 'flex',
  gap: 24,
  justifyContent: 'center',
  padding: '16px 0 24px',
  [bp.lg]: {
    justifyContent: 'center',
    gap: 24,
    padding: '12px 0 24px',
  },
})

const CloseButton = styled.button({
  border: 'none',
  boxShadow: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  position: 'absolute',
  top: 16,
  right: 16,
  '> svg': {
    fill: COLOR.BLUE[1000],
  },
})

const FormButton = styled.button({
  backgroundColor: COLOR.WHITE,
  border: `1px solid ${COLOR.BLUE[700]}`,
  borderRadius: 22,
  color: COLOR.BLUE[700],
  cursor: 'pointer',
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '44px',
  width: 114,
  '&[type="submit"]': {
    backgroundColor: COLOR.BLUE[700],
    color: COLOR.WHITE,
    // re-negotiate the color inconsistencies relative to
    // the shared button component with PM/design
    '&:disabled': {
      backgroundColor: '#BAC2C9',
      borderColor: '#BAC2C9',
      color: '#323d48',
      cursor: 'not-allowed',
    },
  },
})

export type { MultiSelectModalProps }
export { Modal }
