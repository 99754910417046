import type { FC, Dispatch, SetStateAction } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp, COLOR } from '@helloextend/customers-ui'
import { ChatMessageGroup } from './chat-message-group'
import { ChatKaleyIntro } from './chat-kaley-intro'
import groupMessages from './group-messages'
import type { ChatMessage } from '../../../types/chat-message'

interface ChatMessagesProps {
  messages: ChatMessage[]
  chatInputHeight: number
  setChatInputHeight: Dispatch<SetStateAction<number>>
}

const ChatMessages: FC<ChatMessagesProps> = ({ messages, chatInputHeight, setChatInputHeight }) => {
  const groupedMessages = groupMessages(messages)
  const renderMessages = groupedMessages.map((group) => (
    <ChatMessageGroup setChatInputHeight={setChatInputHeight} key={group[0].key} group={group} />
  ))

  return (
    <ChatMessagesSection inputHeight={chatInputHeight}>
      <CenterWrapper>
        <Container>
          {renderMessages}
          <ChatKaleyIntro />
        </Container>
      </CenterWrapper>
    </ChatMessagesSection>
  )
}

const ChatMessagesSection = styled.section<{ inputHeight: number }>(({ inputHeight }) => ({
  display: 'flex',
  flexBasis: '1',
  justifyContent: 'center',
  transition: 'marginBottom 300ms',
  fontSize: 14,
  // subtracting from input height to correct content layout offset for footer which is hidden on this page
  [bp.mobile]: {
    margin: `26px 0 ${inputHeight - 152 || -152}px`,
  },
  [bp.desktop]: {
    margin: `42px 0 86px`,
  },
}))

export const CenterWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
})

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'flex-end',
  height: '100%',
  width: '100%',
  maxWidth: 673,
  minWidth: 325,
  padding: '0 24px 16px 24px',
  background: COLOR.WHITE,
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '::-webkit-scrollbar': {
    width: 0,
    height: 0,
  },
})

export type { ChatMessagesProps }
export { ChatMessages, ChatMessagesSection }
