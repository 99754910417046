import type { SagaIterator } from 'redux-saga'
import { put } from '@redux-saga/core/effects'
import { Slot } from '@helloextend/extend-api-client'
import dayjs from 'dayjs'
import { getParam } from '../../lib/chat-utils'
import { chatActions } from '../../actions'

const helpType = getParam('HelpType')
const claimType = getParam('ClaimType')
const contractsSearchKey = getParam('ContractsSearchKey')
const contractId = getParam('ContractId')
const incidentDate = getParam('IncidentDate')
const claimExists = getParam('ClaimExists')
const adjudicationType = getParam('AdjudicationType')
const failureType = getParam('FailureType')
const failureDescription = getParam('FailureDescription')

const formattedDate = incidentDate ? dayjs(incidentDate).unix() * 1000 : null

interface ChatUpdatePayload {
  message?: string | null
  value?: string | number | null
  slot: Slot
}

export const chatUpdatePayloads: ChatUpdatePayload[] = [
  { message: helpType, value: helpType, slot: Slot.HelpType },
  { message: claimType, value: claimType, slot: Slot.ClaimType },
  { message: contractsSearchKey, value: contractsSearchKey, slot: Slot.ContractsSearchKey },
  { message: "It's this one", value: contractId, slot: Slot.ContractId },
  { message: incidentDate, value: formattedDate, slot: Slot.IncidentDate },
  {
    message: `${
      adjudicationType === 'ACCIDENT' ? 'It was an accident' : 'It just stopped working properly'
    }`,
    value: adjudicationType,
    slot: Slot.AdjudicationType,
  },
  { message: failureType, value: failureType, slot: Slot.FailureType },
  { message: failureDescription, value: failureDescription, slot: Slot.FailureDescription },
  { message: claimExists, value: claimExists, slot: Slot.ClaimExists },
]

export default function* chatAutoAdvance(slot: Slot): SagaIterator {
  const payload = chatUpdatePayloads.find((item) => item.slot === slot)

  if (payload && payload.value) {
    yield put(chatActions.chatSessionUpdate(payload))
  }
}
