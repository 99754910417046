import type { Claim, ContractsV2GetResponse, ServiceOrder } from '@helloextend/extend-api-client'
import {
  useGetContractQuery,
  useGetInsuranceClaimQuery,
  useGetServiceOrdersByClaimQuery,
} from '@helloextend/extend-api-rtk-query'

const useGetClaimDetailsData = (
  claimId: string,
): {
  contract?: ContractsV2GetResponse
  claim?: Claim
  isLoading: boolean
  isError: boolean
  serviceOrders?: ServiceOrder[]
} => {
  const {
    data: serviceOrders,
    isLoading: isServiceOrdersLoading,
    isError: isServiceOrdersQueryError,
  } = useGetServiceOrdersByClaimQuery({ claimId })
  const {
    data: insuranceClaimData,
    isLoading: isInsuranceClaimLoading,
    isError: isInsuranceClaimQueryError,
  } = useGetInsuranceClaimQuery({ claimId })

  const claim = insuranceClaimData ?? undefined

  const {
    data: contract,
    isLoading: isContractLoading,
    isError: isContractQueryError,
  } = useGetContractQuery(
    {
      contractId: claim?.contractId || '',
    },
    { skip: !claim?.contractId },
  ) as { data?: ContractsV2GetResponse; isLoading: boolean; isError: boolean }

  const isLoading = isInsuranceClaimLoading || isContractLoading || isServiceOrdersLoading

  const isError = isServiceOrdersQueryError || isInsuranceClaimQueryError || isContractQueryError

  return {
    contract,
    claim,
    isLoading,
    isError,
    serviceOrders,
  }
}

export { useGetClaimDetailsData }
