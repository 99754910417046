import type { FC } from 'react'
import React from 'react'
import { Footer } from '@helloextend/customers-ui'

import { logEvent } from '../../analytics'

const handleFooterTermsClick = (): void => {
  logEvent('Navigating to Terms page')
  window.open('https://www.extend.com/terms')
}

const handleFooterPrivacyClick = (): void => {
  logEvent('Navigating to Privacy page')
  window.open('https://www.extend.com/privacy')
}

const AnalyticsFooter: FC = () => {
  return (
    <Footer
      links={[
        { handleOnClick: handleFooterPrivacyClick, displayText: 'Privacy Policy' },
        { handleOnClick: handleFooterTermsClick, displayText: 'Terms and Conditions' },
      ]}
    />
  )
}

export { AnalyticsFooter }
