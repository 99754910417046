import type { Claim, ClaimType } from '@helloextend/extend-api-client'
import type { InsuranceClaim } from '@helloextend/extend-api-client/src/models/claim'

export type ClaimStatus = 'approved' | 'denied' | 'review' | 'fulfilled' | 'closed' | 'pending'
export type ShippingStatus = 'notScanned' | 'scanned' | 'received' | 'inspected' | 'expired'

export type ClaimsSearchResponseV2 = {
  items: ClaimRecord[]
  limit?: number
  nextPageCursor?: string
}

export type InsuranceClaimsListResponse = {
  items: InsuranceClaimRecord[]
  limit?: number
  nextPageCursor?: string
}

type ClaimRecord = Claim & {
  [key: string]: unknown
}

type InsuranceClaimRecord = InsuranceClaim & {
  [key: string]: unknown
}

export type ClaimsSearchQueryStringOptions = {
  sellerId?: string
  claimId?: string
  sessionId?: string
  searchVersion?: string
  minLimit?: number
  cursor?: string
  sortKey?: string
  sortAsc?: boolean
  containsContractId?: string
  containsClaimId?: string
  containsCustomerEmail?: string
  matchesClaimStatus?: string[]
  matchesClosedResolution?: string[]
  matchesPendingDisposition?: string[]
  createdAtBegin?: number
  createdAtEnd?: number
  reportedAtBegin?: number
  reportedAtEnd?: number
  updatedAtBegin?: number
  updatedAtEnd?: number
  typeFilter?: ClaimType[]
}

export interface InsuranceClaimsListQueryStringOptions extends ClaimsSearchQueryStringOptions {
  matchesAssignedUser?: string
  matchesEscalationTier?: string
  matchesClaimType?: string
  matchesClaimServiceType?: string
  containsCustomerPhone?: string
  filterUnassigned?: boolean
  followUpDateBegin?: number
  followUpDateEnd?: number
  fraudRiskScoreBegin?: number
  fraudRiskScoreEnd?: number
  cacheBust?: string | number
}
