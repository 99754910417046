import type { PlanTermsSetResponse } from '@helloextend/extend-api-client/src/models/plan-terms'
import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/customers-ui'
import svgs from '../../../../lib/svgs'

type PlanTermsListProps = {
  terms: PlanTermsSetResponse
}

const PlanTermsList: FC<PlanTermsListProps> = ({ terms }) => {
  return (
    <Wrapper data-cy="plan-terms-links-list">
      {terms.map((term) => {
        return (
          <div key={term.termsId}>
            <Link
              key={term.termsId}
              href={term.staticAssetUrl ?? term.s3Url}
              target="_blank"
              rel="noopener noreferrer"
              aria-describedby="extend-terms-conditions"
              data-cy={`plan-terms-link-${term.termsId}`}
            >
              {term.consumerText} <svgs.External />
            </Link>
          </div>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
})

const Link = styled.a({
  color: COLOR.BLUE[700],
  cursor: 'pointer',
  lineHeight: '27px',
  fontSize: 20,
  '> svg': {
    display: 'inline',
    height: 12,
  },
})

export { PlanTermsList }
