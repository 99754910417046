import { Toast } from '@helloextend/customers-ui'
import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import { COLOR } from '@extend/zen'
import { customLogger } from '@extend/client-helpers'
import { logEvent } from '../../analytics'

const handleReload = (): void => {
  window.location.reload()
}
const ErrorComponent: FC<{
  claimsError?: string
  contractsError?: string
}> = ({ claimsError, contractsError }) => {
  if (claimsError || contractsError) {
    if (claimsError) {
      customLogger.error(`[Err: Claim Details Page - claims]: ${claimsError}`)
      logEvent('Claims - Error', 'Claim Load Error on Page Load')
    }
    if (contractsError) {
      customLogger.error(`[Err: Claim Details Page - contracts]: ${contractsError}`)
      logEvent('Claims - Error', 'Contract Load Error on Page Load')
    }
    return (
      <Toast message="We've encountered an error. Please refresh this page." type="danger">
        <RefreshButton onClick={handleReload}>Refresh</RefreshButton>
      </Toast>
    )
  }
  return null
}

const RefreshButton = styled.button({
  [bp.md]: {
    display: 'flex',
    backgroundColor: COLOR.RED['200'],
    color: COLOR.RED['700'],
    fontSize: 14,
    borderRadius: 25,
    border: `1px solid ${COLOR.RED['700']}`,
    padding: '7px 26px',
    lineHeight: '19px',
    fontWeight: 700,
    marginLeft: 44,
  },
  [bp.maxWidthMd]: {
    display: 'none',
  },
  '&:hover': {
    cursor: 'pointer',
  },
})

export { ErrorComponent }
