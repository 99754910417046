import type { FC, Dispatch, SetStateAction } from 'react'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { COLOR, bp, Grid, Card, Divider } from '@helloextend/customers-ui'
import { formatPhoneNumberLocal } from '@extend/client-helpers'
import type {
  FormSubmitData,
  CustomerSubmitDataTypes,
} from '../../components/forms/customer-form-template.types'
import { ContractTransferForm } from '../../components/forms/contract-transfer-form'
import type { ContractsSearchIndividual } from '../../types/contract'
import { ExtendedWarrantyProductDetails } from './product-details/extended-warranty-product-details'
import { CategoryProductDetails } from './product-details/category-product-details'

interface TransferInputViewProps {
  contract: ContractsSearchIndividual
  formData: CustomerSubmitDataTypes
  setFormData: Dispatch<SetStateAction<CustomerSubmitDataTypes>>
  setStage: Dispatch<SetStateAction<string>>
}

const TransferInputView: FC<TransferInputViewProps> = ({
  contract,
  formData,
  setFormData,
  setStage,
}) => {
  const history = useHistory()
  const locale = history.location.pathname.split('/')[1]
  const { customer } = formData as FormSubmitData

  const handleOnSubmit = (data: CustomerSubmitDataTypes): void => {
    setFormData(data)
    setStage('confirm')
    history.push({
      pathname: `/${locale}/my_plans/${contract.id}/transfer`,
      search: '?stage=confirm',
    })
  }

  const handleOnCancel = (): void => {
    history.push(`/${locale}/my_plans/${contract.id}`)
  }

  useEffect(() => {
    if (history.location.search.length) {
      history.push(`/${locale}/my_plans/${contract.id}/transfer`)
    }
  }, [history, contract.id, locale])

  return (
    <TransferInputGrid dataCy="transfer-input-grid">
      <Title data-cy="title">Transfer Plan</Title>
      <SubTitle>
        You are transferring your plan to another owner. Protection plans may only be transferred to
        consumers in the United States. Note that this action cannot be undone.
      </SubTitle>
      {contract.type === 'pcrs' && <ExtendedWarrantyProductDetails contract={contract} />}
      {contract.type === 'category' && <CategoryProductDetails contract={contract} />}
      <StyledCard>
        <CardTitle>Recipient Information</CardTitle>
        <StyledDivider />
        <ContractTransferForm
          initialValues={{
            firstName: customer.name?.split(' ')[0],
            lastName: customer.name?.split(' ')[1],
            email: customer.email,
            phoneNumber: customer.phone && formatPhoneNumberLocal(customer.phone, 'US'),
            address1: customer.shippingAddress?.address1,
            address2: customer.shippingAddress?.address2,
            city: customer.shippingAddress?.city,
            postalCode: customer.shippingAddress?.postalCode,
            provinceCode: customer.shippingAddress?.provinceCode,
          }}
          ownerEmail={contract.customer.email}
          onSubmit={handleOnSubmit}
          onCancel={handleOnCancel}
        />
      </StyledCard>
    </TransferInputGrid>
  )
}

const TransferInputGrid = styled(Grid)({
  flexDirection: 'column',
  width: '100%',
  [bp.mobile]: {
    padding: '0 20px',
  },
  [bp.desktop]: {
    padding: 0,
  },
})

const Title = styled.div({
  color: COLOR.NEUTRAL[1000],
  [bp.mobile]: {
    margin: '40px 0 8px',
    fontSize: 20,
    lineHeight: '28px',
  },
  [bp.desktop]: {
    width: 668,
    margin: '60px auto 16px',
    fontSize: 28,
    lineHeight: '36px',
  },
})

const SubTitle = styled.div({
  color: COLOR.NEUTRAL[800],
  [bp.mobile]: {
    marginBottom: 24,
    fontSize: 15,
    lineHeight: '20px',
  },
  [bp.desktop]: {
    width: 668,
    margin: '0 auto 32px',
    fontSize: 16,
    lineHeight: '24px',
  },
})

const StyledCard = styled(Card)({
  [bp.mobile]: {
    width: '100%',
    margin: '16px 0 40px',
  },
  [bp.desktop]: {
    width: 668,
    margin: '32px auto 80px',
  },
})

const CardTitle = styled.div({
  color: COLOR.NEUTRAL[1000],
  [bp.mobile]: {
    marginBottom: 16,
    fontSize: 16,
    lineHeight: '24px',
  },
  [bp.desktop]: {
    marginBottom: 24,
    fontSize: 20,
    lineHeight: '28px',
  },
})

const StyledDivider = styled(Divider)({
  marginBottom: 24,
})

export { TransferInputView }
