import type { FC, RefObject } from 'react'
import React, { createRef, useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/customers-ui'
import { useDetectScreenResize } from '@helloextend/client-hooks'

import { images } from '../../../lib/assets'

export const DIV_SIZE = 40
const ANIMATION_DURATION = 1000

interface ChatKaleyHeadProps {
  setMoveHead: (moveHead: boolean) => void
  moveHead: boolean
}

const ChatKaleyHead: FC<ChatKaleyHeadProps> = ({ moveHead, setMoveHead }) => {
  const wrapperRef = createRef<HTMLDivElement>()
  const [startPoint, setStartPoint] = useState(DIV_SIZE)
  const [endPoint, setEndPoint] = useState(DIV_SIZE)

  const props = useSpring({
    from: {
      transform: `translateY(${startPoint}px)`,
    },
    to: {
      transform: `translateY(${endPoint - DIV_SIZE}px)`,
    },
  })

  const handleResize = (ref: RefObject<HTMLDivElement>): void => {
    if (ref.current) {
      setEndPoint(ref.current.getBoundingClientRect().height)
    }
  }

  useDetectScreenResize(() => {
    return handleResize(wrapperRef)
  })

  // Listens for when the animation is complete on the message and then
  // moves the chat head down
  useEffect(() => {
    let timeout: number
    if (wrapperRef.current && moveHead) {
      const { height: currentHeight } = wrapperRef.current.getBoundingClientRect()

      setEndPoint(currentHeight)
      timeout = window.setTimeout(() => {
        // Set the new point to animate from once the animation is complete
        setMoveHead(false)
        setStartPoint(currentHeight)
      }, ANIMATION_DURATION)
    }

    return () => window.clearTimeout(timeout)
  }, [wrapperRef, moveHead, setMoveHead])

  return (
    <div ref={wrapperRef} style={{ marginBottom: '10px' }}>
      <animated.div style={props}>
        <ChatHead />
      </animated.div>
    </div>
  )
}

const ChatHead = styled.div({
  width: `${DIV_SIZE}px`,
  height: `${DIV_SIZE}px`,
  marginRight: '16px',
  backgroundImage: `url(${images.kaleyLarge})`,
  backgroundSize: 'contain',
  backgroundColor: COLOR.BLUE[300],
  borderRadius: '50%',
  alignSelf: 'flex-end',
})

export default ChatKaleyHead
