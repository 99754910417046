import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Card, Button, Grid, bp } from '@helloextend/customers-ui'
import type { PlanDetails } from '@helloextend/extend-api-client'
import { useGetPlanTermsDocumentsQuery } from '@helloextend/extend-api-rtk-query'
import { FeatureContent } from '../../pages/plan-details/plan-features/feature-content'
import type { ContractsSearchIndividual } from '../../types/contract'

interface SP {
  planDetails: PlanDetails
  contract: ContractsSearchIndividual
}

type AtAGlanceProps = SP

const AtAGlance: FC<AtAGlanceProps> = ({ planDetails, contract }) => {
  const { data: planTermsDocuments } = useGetPlanTermsDocumentsQuery({
    termsId: contract.plan.termsId ?? '',
    version: contract.plan.termsVersion,
  })
  const planTermsPdfUrl = planTermsDocuments?.versions[0].languages[0].url

  const rundownList = [
    [
      planDetails?.planDetails.rundownInfoTitle1,
      planDetails?.planDetails.rundownInfoBody1,
      planDetails?.planDetails.rundownInfoIcon1,
    ],
    [
      planDetails?.planDetails.rundownInfoTitle2,
      planDetails?.planDetails.rundownInfoBody2,
      planDetails?.planDetails.rundownInfoIcon2,
    ],
    [
      planDetails?.planDetails.rundownInfoTitle3,
      planDetails?.planDetails.rundownInfoBody3,
      planDetails?.planDetails.rundownInfoIcon3,
    ],
    [
      planDetails?.planDetails.rundownInfoTitle4,
      planDetails?.planDetails.rundownInfoBody4,
      planDetails?.planDetails.rundownInfoIcon4,
    ],
    [
      planDetails?.planDetails.rundownInfoTitle5,
      planDetails?.planDetails.rundownInfoBody5,
      planDetails?.planDetails.rundownInfoIcon5,
    ],
    [
      planDetails?.planDetails.rundownInfoTitle6,
      planDetails?.planDetails.rundownInfoBody6,
      planDetails?.planDetails.rundownInfoIcon6,
    ],
  ]

  return (
    <Wrapper data-cy="contract-details-page-at-a-glance">
      <Card padding="0">
        <Grid>
          <Align>
            <Header>At a Glance</Header>
            <FeatureList>
              {rundownList.map(([title, content, icon], index) => (
                <FeatureListItem key={title}>
                  <IconWrapper>
                    <img data-cy={`at-a-glance-icon-${index}`} src={icon} alt={title} />
                  </IconWrapper>
                  <ContentWrapper>
                    <FeatureTitle data-cy={`at-a-glance-title-${index}`}>{title}</FeatureTitle>
                    <FeatureContent
                      dataCy={`at-a-glance-content-${index}`}
                      content={content || ''}
                    />
                  </ContentWrapper>
                </FeatureListItem>
              ))}
            </FeatureList>
            <FinePrint>The fine print</FinePrint>
            <StyledButton
              dataCy="at-a-glance-review-terms-button"
              kind="secondary"
              text="Review Terms &amp; Conditions"
              onClick={() => window.open(planTermsPdfUrl, '_blank')}
              isDisabled={!planTermsPdfUrl}
            />
          </Align>
        </Grid>
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div({})

const Align = styled.section({
  textAlign: 'center',
  margin: '0 auto',
  padding: 40,
  width: '100%',
  [bp.desktop]: {
    padding: 60,
  },
  [bp.desktop]: {
    padding: 100,
  },
})

const Header = styled.h2({
  fontSize: 28,
  fontWeight: 400,
  marginTop: 0,
  lineHeight: '36px',
  marginBottom: 40,
  [bp.desktop]: {
    marginBottom: 20,
    fontSize: 20,
    lineHeight: '28px',
  },
})

const FinePrint = styled.h2({
  margin: '20px 0',
  fontWeight: 400,
  fontSize: 28,
  lineHeight: '36px',
  width: '100%',
  [bp.desktop]: {
    margin: '16px 0',
  },
})

const FeatureList = styled.ul({
  display: 'flex',
  listStyleType: 'none',
  justifyContent: 'space-between',
  flexDirection: 'row',
  flexWrap: 'wrap',
  flexBasis: '100%',
  padding: '0 0 4px',
  [bp.desktop]: {
    padding: 0,
    fontSize: 17,
    lineHeight: '24px',
  },
  [bp.desktopMd]: {
    minWidth: 960,
  },
})

const FeatureListItem = styled.li({
  display: 'flex',
  margin: 0,
  textAlign: 'left',
  marginBottom: 60,
  flexBasis: '100%',
  [bp.tablet]: {
    flexBasis: '46%',
  },
  [bp.desktop]: {
    marginBottom: 24,
  },
})

const ContentWrapper = styled.div({
  flex: 1,
})

const FeatureTitle = styled.h3({
  fontWeight: 700,
  margin: '0 0 8px',
  fontSize: 20,
  lineHeight: '28px',
})

const IconWrapper = styled.div({
  marginRight: 23,
  width: 40,
  // fix for small icon
  '& #accidentalDamage': {
    width: '150%',
    transform: 'translate(-10px, -3px)',
  },
  '& img': {
    width: '100%',
  },
  [bp.tablet]: {
    marginRight: 40,
  },
})

const StyledButton = styled(Button)({
  [bp.mobile]: {
    padding: '8px 24px',
    fontSize: 16,
  },
  [bp.tablet]: {
    padding: '0 48px',
    fontSize: 20,
  },
})

export { AtAGlance }
