import type { FC, SyntheticEvent } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import { bp, Grid, Input, Button } from '@helloextend/customers-ui'
import { formatPhoneNumberOnChange } from '@extend/client-helpers'
import type { CustomerFormProps } from './customer-form-template.types'

const CustomerFormTemplate: FC<CustomerFormProps> = ({
  className,
  initialValues,
  disabledFields,
  optionalFields,
  dataQa,
  submitText = 'Save',
  ownerEmail,
  hasSubmitError,
  onSubmit,
  onCancel,
  isSubmitDisabled,
  formikConfig,
}) => {
  const {
    touched,
    errors,
    values,
    isSubmitting,
    setSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldError,
  } = useFormik(formikConfig(initialValues, onSubmit, ownerEmail))

  const handleOnChange = (e: SyntheticEvent<HTMLInputElement>): void => {
    const field = e.target as HTMLInputElement
    if (field.id === 'phoneNumber') {
      field.value = formatPhoneNumberOnChange(field.value)
    }
    setFieldError(field.id, undefined)
    handleChange(e)
  }

  useEffect(() => {
    if (isSubmitDisabled(errors)) {
      setSubmitting(false)
    }
  }, [errors, setSubmitting, isSubmitDisabled])

  useEffect(() => {
    if (hasSubmitError) {
      setSubmitting(false)
    }
  }, [hasSubmitError, setSubmitting])

  return (
    <form className={className} data-qa={dataQa} onSubmit={handleSubmit}>
      <InputRow hasError={Boolean(errors.firstName) || Boolean(errors.firstName)}>
        <NameInputWrapper>
          <Input
            isRequired={!optionalFields?.firstName}
            id="firstName"
            label="First Name"
            type="text"
            placeholder="First Name"
            value={values.firstName || ''}
            onChange={handleOnChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.firstName && errors.firstName)}
            isDisabled={isSubmitting || disabledFields?.firstName}
            errorMessage={errors.firstName}
            borderRadius={4}
          />
        </NameInputWrapper>
        <NameInputWrapper>
          <Input
            isRequired={!optionalFields?.lastName}
            id="lastName"
            label="Last Name"
            type="text"
            placeholder="Last Name"
            value={values.lastName || ''}
            onChange={handleOnChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.lastName && errors.lastName)}
            isDisabled={isSubmitting || disabledFields?.lastName}
            errorMessage={errors.lastName}
            borderRadius={4}
          />
        </NameInputWrapper>
      </InputRow>
      <InputRow hasError={Boolean(errors.email)}>
        <InputWrapper>
          <Input
            isRequired={!optionalFields?.email}
            id="email"
            label="Email"
            type="text"
            placeholder="Email"
            value={values.email || ''}
            onChange={handleOnChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.email && errors.email)}
            isDisabled={isSubmitting || disabledFields?.email}
            errorMessage={errors.email}
            borderRadius={4}
          />
        </InputWrapper>
      </InputRow>
      <AddressRow hasError={Boolean(errors.address1)}>
        <InputWrapper>
          <Input
            isRequired={!optionalFields?.address1}
            id="address1"
            label="Address"
            type="text"
            placeholder="Address"
            value={values.address1 || ''}
            onChange={handleOnChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.address1 && errors.address1)}
            isDisabled={isSubmitting || disabledFields?.address1}
            errorMessage={errors.address1}
            borderRadius={4}
          />
        </InputWrapper>
      </AddressRow>
      <AddressRow hasError={Boolean(errors.address2)}>
        <InputWrapper>
          <Input
            isRequired={!optionalFields?.address2}
            id="address2"
            label="Address 2"
            type="text"
            placeholder="Apartment, Suite, etc."
            value={values.address2 || ''}
            onChange={handleOnChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.address2 && errors.address2)}
            isDisabled={isSubmitting || disabledFields?.address2}
            errorMessage={errors.address2}
            borderRadius={4}
          />
        </InputWrapper>
      </AddressRow>
      <InputRow
        hasError={
          Boolean(errors.city) || Boolean(errors.provinceCode) || Boolean(errors.postalCode)
        }
      >
        <CityInputWrapper>
          <Input
            isRequired={!optionalFields?.city}
            id="city"
            label="City"
            type="text"
            placeholder="City"
            value={values.city || ''}
            onChange={handleOnChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.city && errors.city)}
            isDisabled={isSubmitting || disabledFields?.city}
            errorMessage={errors.city}
            borderRadius={4}
          />
        </CityInputWrapper>
        <StateInputWrapper>
          <Input
            isRequired={!optionalFields?.provinceCode}
            id="provinceCode"
            label="State"
            type="text"
            placeholder="State"
            value={values.provinceCode || ''}
            onChange={handleOnChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.provinceCode && errors.provinceCode)}
            isDisabled={isSubmitting || disabledFields?.provinceCode}
            errorMessage={errors.provinceCode}
            borderRadius={4}
          />
        </StateInputWrapper>
        <ZipInputWrapper>
          <Input
            isRequired={!optionalFields?.postalCode}
            id="postalCode"
            label="ZIP Code"
            type="text"
            placeholder="ZIP code"
            value={values.postalCode || ''}
            onChange={handleOnChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.postalCode && errors.postalCode)}
            isDisabled={isSubmitting || disabledFields?.postalCode}
            errorMessage={errors.postalCode}
            borderRadius={4}
          />
        </ZipInputWrapper>
      </InputRow>
      <InputRow hasError={Boolean(errors.phoneNumber)}>
        <InputWrapper>
          <Input
            isRequired={!optionalFields?.phoneNumber}
            id="phoneNumber"
            label="Phone Number"
            type="text"
            placeholder="Phone Number"
            value={values.phoneNumber || ''}
            onChange={handleOnChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.phoneNumber && errors.phoneNumber)}
            isDisabled={isSubmitting || disabledFields?.phoneNumber}
            errorMessage={errors.phoneNumber}
            borderRadius={4}
          />
        </InputWrapper>
      </InputRow>
      <ButtonRow>
        <CancelButton
          kind="dangerSecondary"
          type="reset"
          text="Cancel"
          isBlock
          isLoading={isSubmitting}
          onClick={() => onCancel()}
          dataCy="cancel-button"
        />
        <SubmitButton
          kind="primary"
          type="submit"
          text={submitText}
          isBlock
          isLoading={isSubmitting}
          isDisabled={isSubmitDisabled(errors)}
          dataCy="submit-button"
        />
      </ButtonRow>
    </form>
  )
}

const InputRow = styled(Grid)<{ hasError?: boolean }>(({ hasError }) => ({
  justifyContent: 'center',
  gap: 8,
  [bp.mobile]: {
    flexDirection: 'column',
    marginBottom: 32,
  },
  [bp.desktop]: {
    flexDirection: 'row',
    height: 72,
    marginBottom: hasError ? 52 : 32,
  },
}))

const AddressRow = styled(InputRow)<{ hasError?: boolean }>(({ hasError }) => ({
  [bp.mobile]: {
    marginBottom: 8,
  },
  [bp.desktop]: {
    marginBottom: hasError ? 28 : 8,
  },
}))

const InputWrapper = styled.div({
  [bp.mobile]: {
    width: '100%',
  },
  [bp.desktop]: {
    width: 604,
  },
})

const NameInputWrapper = styled(InputWrapper)({
  [bp.desktop]: {
    width: 298,
  },
})

const CityInputWrapper = styled(InputWrapper)({
  [bp.desktop]: {
    width: 316,
  },
})

const StateInputWrapper = styled(InputWrapper)({
  [bp.desktop]: {
    width: 136,
  },
})

const ZipInputWrapper = styled(InputWrapper)({
  [bp.desktop]: {
    width: 136,
  },
})

const ButtonRow = styled(Grid)({
  justifyContent: 'right',
  gap: 16,
  [bp.mobile]: {
    marginTop: -8,
  },
  [bp.desktop]: {
    maxWidth: 604,
    margin: '0 auto',
  },
})

const SubmitButton = styled(Button)({
  [bp.mobile]: {
    width: 'auto',
    height: 38,
    borderRadius: 20,
    fontSize: 16,
    padding: '8px 24px',
  },
  [bp.desktop]: {
    width: 'auto',
    height: 47,
    borderRadius: 24,
    fontSize: 20,
    padding: '10px 48px',
  },
})

const CancelButton = styled(Button)({
  [bp.mobile]: {
    width: 'auto',
    borderRadius: 20,
    fontSize: 16,
    height: 38,
    padding: '8px 24px',
  },
  [bp.desktop]: {
    width: 'auto',
    borderRadius: 24,
    fontSize: 20,
    height: 47,
    padding: '10px 48px',
  },
})

export { CustomerFormTemplate }
