import type { FC, ComponentProps, CSSProperties } from 'react'
import styled from '@emotion/styled'
import { Badge as ChakraBadge } from '@chakra-ui/react'
import { COLOR } from '@extend/zen'

type BadgeKind = 'info' | 'success' | 'warning' | 'danger' | 'active' | 'inactive'

// @ts-ignore
interface BadgeProps extends ComponentProps<typeof ChakraBadge> {
  status?: BadgeKind
  className?: string
  dataCy?: string
}
const Badge: FC<BadgeProps> = ({ children, status = 'info', dataCy, className, ...props }) => {
  const { ref, ...rest } = props

  return (
    <StyledBadge {...{ status, className }} {...rest} data-cy={dataCy}>
      {children}
    </StyledBadge>
  )
}

const StyledBadge = styled(ChakraBadge)(({ status }) => ({
  ...kindStyles(status),
  fontWeight: 700,
  padding: '3px 8px',
  fontSize: 13,
  lineHeight: '18px',
  borderRadius: 12,
}))

const kindStyles = (status: BadgeKind): CSSProperties => {
  switch (status) {
    case 'warning':
      return {
        background: COLOR.YELLOW['200'],
        color: COLOR.YELLOW['800'],
      }
    case 'danger':
      return {
        background: COLOR.RED['200'],
        color: COLOR.RED['800'],
      }
    case 'success':
    case 'active':
      return {
        background: COLOR.GREEN['200'],
        color: COLOR.GREEN['800'],
      }
    case 'inactive':
      return {
        background: COLOR.NEUTRAL['200'],
        color: COLOR.NEUTRAL['800'],
      }
    case 'info':
    default:
      return {
        background: COLOR.BLUE['200'],
        color: COLOR.BLUE['800'],
      }
  }
}

export type { BadgeKind }
export { Badge }
