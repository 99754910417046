import React from 'react'
import type { FC } from 'react'
import type {
  Claim as ApiClientClaim,
  ClaimPhotosGetResponse,
  ServiceOrder,
} from '@helloextend/extend-api-client'
import type { MerchantServicingSettings } from '@helloextend/extend-api-rtk-query/src/servicers/types'
import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'
import { Button, COLOR, ChevronRight, Paragraph, Subheading, HeadingLarge } from '@extend/zen'
import { bp } from '@helloextend/customers-ui'
import { useHistory } from 'react-router-dom'
import { ClaimStateMachine } from '../claim-state-machine'
import type { Claim } from '../../../types/claim'
import type { ShippingProtectionContract, ShippingProtectionProduct } from '../../../types/contract'
import svgs from '../../../lib/svgs'

export interface ShippingProtectionClaimCardProps {
  claim: Claim | ApiClientClaim
  contract?: ShippingProtectionContract
  serviceOrders?: ServiceOrder[]
  merchantServicingSettings?: MerchantServicingSettings
  photoData?: ClaimPhotosGetResponse
  handleResultModalOpen: () => void
}

export const ShippingProtectionClaimCard: FC<ShippingProtectionClaimCardProps> = ({
  claim,
  contract,
  serviceOrders,
  merchantServicingSettings,
  photoData,
  handleResultModalOpen,
}) => {
  const history = useHistory()
  const { products } = claim

  const productLineItemIds = new Set(products?.map((p) => p.lineItemId))
  const productImages = contract?.productsList
    .filter((product) => productLineItemIds.has((product as ShippingProtectionProduct)?.lineItemId))
    .map((product, index) => (
      <ProductImage key={`ProductImage-${index + 1}`} imageUrl={product.imageUrl || ''} />
    ))
    .slice(0, 5)

  const handleReviewClaimPage = (): void => {
    history.push(
      `/review_claim?claimId=${claim.id}&contractId=${claim.contractId}&sessionId=${claim.sessionId}`,
    )
  }

  return (
    <ClaimCardContainer data-cy="shipping-claim-card-container" data-claim-id={claim.id}>
      <ClaimCardHeaderSection data-cy="shipping-claim-card-header">
        <LogoInfoContainer>
          <LogoContainer data-cy="sp-desktop-logo-container">
            <svgs.PackageClaim />
          </LogoContainer>
          <HeaderContent>
            <ClaimType>
              <svgs.PackageIcon />
              <StyledSubheading data-cy="shipping-claim-subheader">Shipping Protection Claim</StyledSubheading>
            </ClaimType>
            <HeaderContainer>
              <HeadingLarge data-cy="shipping-claim-order-title">Order from {contract?.sellerName}</HeadingLarge>
              {isMobile && (
                <Button
                  icon={ChevronRight}
                  iconPosition="right"
                  emphasis="low"
                  color="neutral"
                  onClick={handleReviewClaimPage}
                  data-cy="claim-details-button"
                />
              )}
            </HeaderContainer>
            <Paragraph>
              {products?.length} {products?.length === 1 ? 'item' : 'items'} in claim
            </Paragraph>
            {isMobile && <ClaimImagesContainer>{productImages}</ClaimImagesContainer>}
          </HeaderContent>
        </LogoInfoContainer>
        {!isMobile && (
          <ClaimImagesButtonWrapper>
            <ClaimImagesContainer>{productImages}</ClaimImagesContainer>
            <ClaimDetailsButtonContainer>
              <Button
                icon={ChevronRight}
                iconPosition="right"
                emphasis="low"
                color="neutral"
                onClick={handleReviewClaimPage}
                text={'Claim details'}
                data-cy="claim-details-button"
              />
            </ClaimDetailsButtonContainer>
          </ClaimImagesButtonWrapper>
        )}
      </ClaimCardHeaderSection>
      <ClaimCardDetailsSection onClick={(e) => e.stopPropagation()}>
        <ClaimStateMachine
          claim={claim as ApiClientClaim}
          merchantServicingSettings={merchantServicingSettings}
          serviceOrders={serviceOrders}
          photoData={photoData}
          handleResultModalOpen={handleResultModalOpen}
        />
      </ClaimCardDetailsSection>
    </ClaimCardContainer>
  )
}

const ClaimCardContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  borderRadius: 16,
  border: `1px solid var(--neutral-200, ${COLOR.NEUTRAL[200]})`,
  background: `var(--white, ${COLOR.WHITE})`,
  overflow: 'hidden',
  width: '100%',
})

const ClaimCardHeaderSection = styled.div({
  display: 'flex',
  alignSelf: 'stretch',
  borderBottom: `1px solid ${COLOR.NEUTRAL[200]}`,
  background: `var(--white, ${COLOR.WHITE})`,
  justifyContent: 'space-between',
  alignItems: 'center',
  [bp.mobile]: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 16,
  },
  [bp.desktop]: {
    padding: 24,
    gap: 24,
    alignItems: 'center',
    flexDirection: 'row',
  },
})

const LogoInfoContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 24,
})

const HeaderContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 8,
})

const HeaderContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const ClaimType = styled.span({
  display: 'flex',
  gap: 8,
})

const StyledSubheading = styled(Subheading)({
  color: COLOR.NEUTRAL[600],
  fontWeight: 700,
})

const LogoContainer = styled.div({
  display: 'flex',
  width: '96px',
  height: '96px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  borderRadius: '16px',
  border: `1px solid ${COLOR.NEUTRAL[200]}`,
  [bp.maxWidthDesktopSm]: {
    display: 'none',
  },
})

const ProductImage = styled.img<{ imageUrl: string }>(({ imageUrl }) => ({
  width: 56,
  height: 56,
  borderRadius: '5px',
  border: `1px solid ${COLOR.NEUTRAL[200]}`,
  background: `url(${imageUrl}), lightgray 50% / cover no-repeat;`,
  backgroundSize: 'contain',
}))

const ClaimImagesContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  justifyContent: 'right',
})

const ClaimImagesButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 8,
})

const ClaimDetailsButtonContainer = styled.div({
  alignItems: 'center',
})

const ClaimCardDetailsSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 24,
  alignSelf: 'stretch',
  [bp.mobile]: {
    padding: '16px 16px 24px 16px',
    alignItems: 'center',
  },
  [bp.desktop]: {
    padding: '24px 24px 24px 144px',
    alignItems: 'flex-start',
  },
})
