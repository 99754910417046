import type { Action } from '../types/action-type'

export type ResourceFetchErrorState = {
  errorMessage: string
  statusCode: number
} | null

export const initialState: ResourceFetchErrorState = null

function resourceFetchError(state = initialState, action: Action): ResourceFetchErrorState {
  switch (action.type) {
    case 'PLANS_FETCH_FAILURE':
    case 'CONTRACTS_FETCH_FAILURE':
      return {
        errorMessage: action.payload.toString(),
        statusCode: action.meta?.statusCode ?? 400,
      }
    case 'RESOURCE_FETCH_ERROR_RESET':
      return initialState
    default:
      return state
  }
}

export default resourceFetchError
