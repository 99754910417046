import type { OfferType } from '@helloextend/extend-api-client'

export type CcsParams = {
  storeId?: string
  serviceType?: string
  coverageType?: string
  productCategory?: string
  locale?: string
}

export type SpContentParams = {
  planId?: string
  offerType?: OfferType
  locale?: string
  storeId?: string
}

export type CharityContentParams = {
  locale?: string
  storeId?: string
  charityId?: string
  extendProductType?: string
}

export interface CharityContent {
  results: CharityContentResult[]
}

interface CharityContentResult {
  result: string
  payloads: {
    donationConfiguration?: CharityPlanDetails
  }
}

export interface CharityPlanDetails {
  charityPlanDetailsHeadline: string
  charityPlanDetailsSubheadline: string
  charityPlanDetailsImage: string
  charityPlanDetailsDisclosureText: string
}
