import type { FC } from 'react'
import React from 'react'
import { matchPath, NavLink, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { COLOR, bp, Grid, Button } from '@helloextend/customers-ui'
import { useIntl } from 'react-intl'
import {
  Export,
  HeaderMenuItem,
  Mail,
  Icon,
  LinkButton,
  Popover,
  Account,
  PopoverAlignment,
  Stack,
  usePopover,
} from '@extend/zen'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { HamburgerIcon } from './hamburger-icon'
import { HamburgerMenu } from './hamburger-menu'
import { navLinksContent } from './nav-links-content'
import { reset as resetPersistedStorage } from '../../store'
import { ProfileMenu } from './profile-menu'
import * as selectors from '../../reducers/selectors'
import { Theme } from '@emotion/react'

interface NavBarProps {
  closeMenu: () => void
  openMenu: () => void
  showMenu: boolean
  isLoggedIn: boolean
  theme: Theme
}

const NavBar: FC<NavBarProps> = ({ showMenu, closeMenu, openMenu, isLoggedIn, theme }) => {
  const { locale } = useIntl()
  const { triggerRef, popoverRef, isPresent, toggle, triggerBoundingBox } =
    usePopover<HTMLDivElement>()
  const consumerProfile = useSelector(selectors.getConsumerProfile)

  const logout = (): void => {
    resetPersistedStorage()
  }

  const imageSource = theme.merchantConfiguration.brandingConfigurations.assetsUrls.logo
  const isButtonInverted = theme.merchantConfiguration.brandingConfigurations.primaryColor !== COLOR.WHITE

  const location = useLocation()
  return (
    <NavWrapper>
      <HamburgerIcon dataQa="nav-open-icon" openMenu={openMenu} />
      <HamburgerMenu {...{ showMenu, closeMenu, isLoggedIn }} />
      {isLoggedIn ? (
        <LinksWrapper>
          <LeftNavWrapper>
            {theme.merchantConfiguration.isExtend ? (
              <LogoLinkInternal to={`/${locale}/my_plans`} data-cy="plans-logo-link-internal">
                <Logo id="brand-logo" src={imageSource} alt="Brand logo" />
              </LogoLinkInternal>
            ) : (
              <LogoLinkExternal href={theme.merchantConfiguration.merchantWebsite} data-cy="plans-logo-link-external">
                <Logo id="brand-logo" src={imageSource} alt="Brand logo" />
              </LogoLinkExternal>
            )}
            <NavLinksWrapper>
              {navLinksContent.map((link) => {
                const { url, text, id } = link
                return (
                  <div key={id}>
                    <LinkButton
                      text={text}
                      to={`/${locale}${url}`}
                      emphasis="low"
                      isInverted={isButtonInverted}
                      isToggled={Boolean(
                        matchPath(location.pathname, {
                          path: `/${locale}${url}`,
                        }),
                      )}
                      color="neutral"
                      data-cy={`nav-link-${url.replace('/', '')}`}
                    />
                  </div>
                )
              })}
            </NavLinksWrapper>
          </LeftNavWrapper>
          <RightNavWrapper>
            <div>
              <LinkButton
                icon={Mail}
                text="Contact Support"
                to={`/${locale}/contact`}
                emphasis="low"
                isInverted={isButtonInverted}
                isToggled={Boolean(
                  matchPath(location.pathname, {
                    path: `/${locale}/contact`,
                  }),
                )}
                data-cy="nav-link-contact"
                color="neutral"
              />
            </div>
            <HeaderMenuItem
              ref={triggerRef}
              onClick={toggle}
              tooltip="Profile"
              data-cy="header-menu-item"
            >
              <ProfileWrapper>
                <Icon icon={Account} color={COLOR.NEUTRAL[800]} />
              </ProfileWrapper>
            </HeaderMenuItem>
            <Popover
              ref={popoverRef}
              isPresent={isPresent}
              alignment={PopoverAlignment.end}
              triggerBoundingBox={triggerBoundingBox}
              data-cy="popover"
            >
              <Stack>
                <PopoverLink data-cy="popover-link">
                  <ProfileMenu />
                </PopoverLink>
                {!isEmpty(consumerProfile) && (
                  <ManageAccountLink href="/profile" data-cy="manage-account-link">
                    Manage My Account
                  </ManageAccountLink>
                )}
                <LogoutLink href={'/'} onClick={logout} data-cy="logout-link">
                  <Icon icon={Export} color={COLOR.NEUTRAL[800]} /> Log out
                </LogoutLink>
              </Stack>
            </Popover>
          </RightNavWrapper>
        </LinksWrapper>
      ) : (
        <LinksWrapper>
          {theme.merchantConfiguration.isExtend ? (
            <LogoLinkInternal to={`/${locale}/`} data-cy="home-logo-link-internal">
              <Logo id="brand-logo" src={imageSource} alt="Brand logo" />
            </LogoLinkInternal>
          ) : (
            <LogoLinkExternal href={theme.merchantConfiguration.merchantWebsite} data-cy="home-logo-link-external">
              <Logo id="brand-logo" src={imageSource} alt="Brand logo" />
            </LogoLinkExternal>
          )}
          <RightNavWrapper>
            <div>
              <LinkButton
                icon={Mail}
                text="Contact Support"
                to={`/${locale}/contact`}
                emphasis="low"
                isInverted={isButtonInverted}
                isToggled={Boolean(
                  matchPath(location.pathname, {
                    path: `/${locale}/contact`,
                  }),
                )}
                data-cy="nav-link-contact"
                color="neutral"
              />
            </div>
            <NavLink to="/authentication" data-cy="nav-link-login">
              <LogInButton kind="login" isInverted={isButtonInverted} text="Log in" />
            </NavLink>
          </RightNavWrapper>
        </LinksWrapper>
      )}
    </NavWrapper>
  )
}

const NavWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  padding: '0 20px',
  [bp.desktop]: {
    padding: '0 40px',
    '#burger-icon': {
      display: 'none',
    },
  },
})

const LinksWrapper = styled(Grid)({
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  height: 48,
  [bp.desktop]: {
    height: 72,
  },
})

const LeftNavWrapper = styled(Grid)({
  width: '100%',
  [bp.desktop]: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
})

const RightNavWrapper = styled(Grid)({
  display: 'none',
  [bp.desktop]: {
    display: 'flex',
    gap: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
})

const LogoLinkInternal = styled(NavLink)({
  margin: '0 auto',
  [bp.desktop]: {
    margin: 0,
  },
})

const LogoLinkExternal = styled('a')({
  margin: '0 auto',
  [bp.desktop]: {
    margin: 0,
  },
})

const ProfileWrapper = styled.div({
  width: 40,
  height: 40,
  borderRadius: '50%',
  backgroundColor: COLOR.NEUTRAL[200],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const Logo = styled.img({
  [bp.mobile]: {
    height: 21,
    width: 100,
    margin: '16px 23px 16px 0',
  },
  [bp.desktop]: {
    height: 25,
    width: 120,
    margin: `13px 32px 16px 0`,
  },
})

const NavLinksWrapper = styled(Grid)({
  display: 'none',
  [bp.desktop]: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    height: 'auto',
    margin: 0,
  },
})

const PopoverLink = styled.div({
  padding: '18px 20px',
})

const ManageAccountLink = styled.a({
  borderTop: '1px solid',
  borderColor: COLOR.NEUTRAL[300],
  display: 'flex',
  gap: 8,
  padding: '18px 20px',
})

const LogoutLink = styled.a({
  borderTop: '1px solid',
  borderColor: COLOR.NEUTRAL[300],
  display: 'flex',
  gap: 8,
  padding: '18px 20px',
})

const LogInButton = styled(Button)({
  height: 40,
  width: 'auto',
  margin: '16px 0 0 8px',
  padding: '8px 32px',
  fontSize: 16,
  lineHeight: '22px',
})

export { NavBar }
