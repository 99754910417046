import React from 'react'
import styled from '@emotion/styled'
import type { FC } from 'react'
import { bp } from '@helloextend/customers-ui'
import type { Claim } from '@helloextend/extend-api-client'
import { LinkButton, Timeline, TimelineItem } from '@extend/zen'
import { formatDate } from '../../../lib/helper-functions'

interface ClaimClosedStateProps {
  claim: Claim
}
export const ClaimClosedState: FC<ClaimClosedStateProps> = ({ claim }) => {
  const date = claim.closedMetaData?.closedAt
  return (
    <ClaimClosedContainer data-cy="claim-closed-timeline">
      <Timeline>
        <TimelineItem
          label={
            <>
              <TimelineBold>Claim closed</TimelineBold>
              {date ? `: ${formatDate(date)}` : ''}
            </>
          }
          state="default"
          data-cy="claim-closed-timeline-item"
        />
      </Timeline>
      <TimelineFooter>
        <div>
          <LinkButton
            data-cy="get-help-button"
            emphasis="medium"
            text="Get help"
            color="neutral"
            to="/contact"
          />
        </div>
      </TimelineFooter>
    </ClaimClosedContainer>
  )
}

const TimelineFooter = styled.div({
  width: '100%',
  display: 'flex',
  [bp.mobile]: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  [bp.desktop]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
})

const ClaimClosedContainer = styled.div({
  width: '100%',
})

const TimelineBold = styled.span({
  fontWeight: 700,
})
