import React, { useState, useEffect } from 'react'
import type { FC } from 'react'
import { ContractType } from '@helloextend/extend-api-client'
import {
  useGetMerchantServicingSettingsQuery,
  useGetServiceOrdersByClaimQuery,
  useGetClaimPhotosQuery,
  useGetInsuranceClaimQuery,
} from '@helloextend/extend-api-rtk-query'
import { isEmpty } from 'lodash'
import { ShippingProtectionClaimCard } from './claim-card/shipping-protection-claim-card'
import { ProductProtectionClaimCard } from './claim-card/product-protection-claim-card'
import { ProductProtectionBundleClaimCard } from './claim-card/product-protection-bundle-claim-card'
import type {
  ProductProtectionBundleContract,
  CategoryContract,
  ContractsSearchIndividual,
  ShippingProtectionContract,
  ExtendedWarrantyContract,
} from '../../types/contract'
import type { Claim } from '../../types/claim'
import { CategoryClaimCard } from './claim-card/category-claim-card'
import { ClaimResultModal } from '../../components/claim-result-modal/claim-result-modal'

export interface ClaimCardProps {
  listedClaim: Claim
  contract: ContractsSearchIndividual
}

export const ClaimCard: FC<ClaimCardProps> = ({ listedClaim, contract }) => {
  const [shouldFetchClaim, setShouldFetchClaim] = useState(true)
  const { data: fetchedClaim } = useGetInsuranceClaimQuery(
    { claimId: listedClaim.id },
    {
      skip: !shouldFetchClaim,
      pollingInterval: 2000,
    },
  )

  const claim = (fetchedClaim || listedClaim) as Claim

  const { type: claimType } = claim
  const { type: contractType } = contract

  const [isResultModalOpen, setResultModalOpen] = useState(false)

  const { data: serviceOrders } = useGetServiceOrdersByClaimQuery(
    { claimId: claim.id, includeShippingLabels: true },
    {
      selectFromResult: (response) => ({
        ...response,
        data: response.data ? [...response.data].sort((a, b) => b.createdAt - a.createdAt) : [],
      }),
      pollingInterval: 10000,
      skip: ['pending_adjudication', 'denied'].includes(claim.status),
    },
  )
  const { data: merchantServicingSettings } = useGetMerchantServicingSettingsQuery({
    sellerId: claim.sellerId,
  })

  const { data: photoData, refetch: refetchClaimPhotos } = useGetClaimPhotosQuery({
    claimId: claim.id,
  })

  useEffect(() => {
    if (isResultModalOpen) {
      if (!isEmpty(fetchedClaim) && fetchedClaim?.status !== 'pending_adjudication') {
        refetchClaimPhotos()
        setShouldFetchClaim(false)
      } else {
        setShouldFetchClaim(true)
      }
    } else if (!isEmpty(fetchedClaim)) {
      // fetch the claim on load, then stop polling until the modal is opened
      setShouldFetchClaim(false)
    }
  }, [isResultModalOpen, fetchedClaim, refetchClaimPhotos])

  const handleResultModalOpen = (): void => {
    setResultModalOpen(true)
  }

  const handleResultModalClose = (): void => {
    setResultModalOpen(false)
  }

  return (
    <>
      {claimType === 'shipping_protection' && (
        <ShippingProtectionClaimCard
          claim={claim}
          contract={contract as ShippingProtectionContract}
          merchantServicingSettings={merchantServicingSettings}
          serviceOrders={serviceOrders}
          photoData={photoData}
          handleResultModalOpen={handleResultModalOpen}
        />
      )}
      {claimType === 'category' && (
        <CategoryClaimCard
          claim={claim}
          contract={contract as CategoryContract}
          merchantServicingSettings={merchantServicingSettings}
          serviceOrders={serviceOrders}
          photoData={photoData}
          handleResultModalOpen={handleResultModalOpen}
        />
      )}
      {claimType === 'extended_warranty' && contractType === ContractType.PCRS && (
        <ProductProtectionClaimCard
          claim={claim}
          contract={contract as ExtendedWarrantyContract}
          merchantServicingSettings={merchantServicingSettings}
          serviceOrders={serviceOrders}
          photoData={photoData}
          handleResultModalOpen={handleResultModalOpen}
        />
      )}
      {claimType === 'extended_warranty' &&
        contractType === ContractType.PRODUCT_PROTECTION_BUNDLE && (
          <ProductProtectionBundleClaimCard
            claim={claim}
            contract={contract as unknown as ProductProtectionBundleContract}
            merchantServicingSettings={merchantServicingSettings}
            serviceOrders={serviceOrders}
            photoData={photoData}
            handleResultModalOpen={handleResultModalOpen}
          />
        )}
      <ClaimResultModal
        claim={fetchedClaim as Claim}
        onDismiss={handleResultModalClose}
        isModalOpen={isResultModalOpen}
        isPollingClaim={shouldFetchClaim}
        photoData={photoData}
      />
    </>
  )
}
