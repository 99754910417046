import * as pages from '../pages'

// Routes in this file use the ProtectedRoute component in types.ts

export const routes = [
  {
    path: '/my_plans/:id',
    component: pages.ContractDetailsPage,
  },
  {
    path: '/my_plans/:id/product/:lineItemId',
    component: pages.ContractDetailsPage,
  },
  {
    path: '/my_plans',
    component: pages.PlansPage,
  },
  {
    path: '/my_claims/:id',
    component: pages.ClaimDetailsPage,
  },
  {
    path: '/my_claims',
    component: pages.ClaimsPage,
  },
  {
    path: '/my_claims/virtual_cards/:id',
    component: pages.VirtualCardFulfillmentMyExtend,
  },
  {
    path: '/profile',
    component: pages.UserProfilePage,
  },
  {
    path: '/error',
    component: pages.ErrorPage,
  },
  {
    path: '/review_claim',
    component: pages.ReviewClaimSubmissionPage,
  },
]
