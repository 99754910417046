const navLinksContent = [
  {
    text: 'My Plans',
    url: '/my_plans',
    id: 0,
  },
  {
    text: 'Claims',
    url: '/my_claims',
    id: 1,
  },
]

export { navLinksContent }
