import type { FC } from 'react'
import React from 'react'
import { isNumber } from 'lodash/fp'
import type {
  Contract,
  Plan,
  PlanDetails as PlanDetailsModel,
} from '@helloextend/extend-api-client'
import { FormattedMessage } from '@helloextend/customers-ui'
import { currency } from '@extend/client-helpers'
import { useIntl } from 'react-intl'
import { DescriptionList, Section } from '../../../../components/warranty-terms-section'
import { DateRange } from '../../../../components/format-dates'
import type { Locale } from '../../../../types/localization'

interface PlanDetailsProps {
  plan: Plan
  contract?: Contract
  planDetails: PlanDetailsModel
}

const LIFETIME_YEARS = 999

const PlanDetails: FC<PlanDetailsProps> = ({ plan, contract, planDetails }) => {
  const { contract: planContract } = plan
  const { locale } = useIntl()
  const termLengthYears = planContract.term_length / 12

  const isLifetime = plan.contract.term_length === LIFETIME_YEARS
  const currencyCode = contract
    ? contract.currency
    : currency.getCurrencyCodeByLocale(locale as Locale)

  return (
    <Section title={planDetails.planTermsLabels.planDetailsHeader}>
      <DescriptionList>
        {contract && (
          <>
            <dt>{planDetails.planTermsLabels.productNameLabel}</dt>
            <dd>{contract.product?.title ?? planDetails.planTermsLabels.notApplicable}</dd>
            <dt>{planDetails.planTermsLabels.contractIdLabel}</dt>
            <dd>{contract.id}</dd>
            <dt>{planDetails.planTermsLabels.termLabel}</dt>
            <dd>
              {/* Hardcoding 'Lifetime' as there is no value for this in content right now */}
              {isLifetime ? (
                <FormattedMessage
                  id="CONTRACT_TRANSFER_PRODUCT_DETAILS_TERM_LIFETIME"
                  defaultMessage="Lifetime"
                  description="Lifetime"
                />
              ) : (
                // Still need to use a Formatted message for this to format properly across languages
                <FormattedMessage
                  id="termLengthYears"
                  defaultMessage="{termLengthYears, plural, one {{termLengthYears} year} other {{termLengthYears} years}}"
                  description="termLengthYears: the length of the term in years"
                  values={{ termLengthYears }}
                />
              )}
            </dd>
            <dt>{planDetails.planTermsLabels.coverageTermLabel}</dt>
            <dd>
              <DateRange {...{ contract, isLifetime, locale }} />
            </dd>
          </>
        )}
        <dt>{planDetails.planTermsLabels.adhCoverageLabel}</dt>
        <dd>
          {/* "wheel_tire" logic exception to hide implications from customers (sales reasons) */}
          {planContract.category === 'wheel_tire' ? (
            <FormattedMessage id="WARRANTY_TERMS_NO" defaultMessage="No" description="No" />
          ) : (
            planDetails.planTerms.adhCoverageStatus
          )}
        </dd>
        <dt>{planDetails.planTermsLabels.serviceTypeLabel}</dt>
        <dd>{planDetails.planTerms.serviceTypeStatus}</dd>
        <dt>{planDetails.planTermsLabels.deductibleLabel}</dt>
        <dd>
          {isNumber(planContract.deductible)
            ? `${currency.format(planContract.deductible, currencyCode, undefined, locale)}`
            : planDetails.planTermsLabels.notApplicable}
        </dd>
      </DescriptionList>
    </Section>
  )
}

export type { PlanDetailsProps }
export { PlanDetails }
