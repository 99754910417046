import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isRtkFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error
}

export function safeBtoa(s: string): string {
  try {
    return btoa(s)
  } catch (e) {
    console.error(e)
    return ''
  }
}
