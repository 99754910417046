import type { ChangeEvent, FC, FormEvent } from 'react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { MultiSelectPrompt } from '@helloextend/extend-api-client'
import { Modal } from './modal'
import { chatActions } from '../../../../../actions'
import type { RootState } from '../../../../../reducers'
import * as selectors from '../../../../../reducers/selectors'

const ChatMultiselect: FC = () => {
  const dispatch = useDispatch()
  const prompt = useSelector((state: RootState) =>
    selectors.getChatPrompt(state),
  ) as MultiSelectPrompt
  const isPromptHidden = useSelector((state: RootState) => selectors.getChatIsPromptHidden(state))
  const { slot, options } = prompt
  const shouldModalBeOpen = !isPromptHidden

  const [optionValue, setOptionValue] = useState<number | null>(null)

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const selectedValue = +e.currentTarget.value
    const updatedValue = optionValue && optionValue === selectedValue ? null : selectedValue
    setOptionValue(updatedValue)
  }

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault()
    if (optionValue !== null && slot) {
      const selectedOption = options[optionValue]
      if (!selectedOption) return

      dispatch(
        chatActions.chatSessionUpdate({
          message: selectedOption.outputText,
          slot,
          value: selectedOption.value,
        }),
      )
    }
  }

  const handleReset = (): void => {
    setOptionValue(null)
    dispatch(chatActions.chatMultiselectHide())
  }

  return (
    <div data-cy="chat-multiselect">
      <Modal
        prompt={prompt}
        currentValue={optionValue}
        isOpen={shouldModalBeOpen}
        onChange={handleChange}
        onClickClose={handleReset}
        onSubmit={handleSubmit}
        onReset={handleReset}
      />
    </div>
  )
}

export { ChatMultiselect }
