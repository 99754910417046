import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/customers-ui'
import type { PlanDetails } from '@helloextend/extend-api-client'
import type { PlanTermsSetResponse } from '@helloextend/extend-api-client/src/models/plan-terms'
import { Section } from '../../../../components/warranty-terms-section'
import svgs from '../../../../lib/svgs'
import { PlanTermsList } from './plan-terms-list'

export interface PlanTermsProps {
  terms: string | PlanTermsSetResponse | null | undefined
  planDetails: PlanDetails
}

const PlanTerms: FC<PlanTermsProps> = ({ terms, planDetails }) => {
  return (
    <Section title={planDetails.planTermsLabels.fullStoryHeader}>
      {!terms && <LoadingIndicator>{planDetails.planTerms.loadingTermsMessage}</LoadingIndicator>}
      {terms && Array.isArray(terms) && <PlanTermsList terms={terms} />}
      {terms && !Array.isArray(terms) && (
        <TermsDownloadLink
          href={terms}
          target="_blank"
          rel="noopener noreferrer"
          aria-describedby="extend-terms-conditions"
        >
          {planDetails.planTerms.finePrintTermsAndConditions} <svgs.External />
        </TermsDownloadLink>
      )}
    </Section>
  )
}

const LoadingIndicator = styled.div({
  position: 'relative',
  color: COLOR.BLUE[700],
  fontSize: 20,
})

const TermsDownloadLink = styled.a({
  color: COLOR.BLUE[700],
  cursor: 'pointer',
  lineHeight: '27px',
  fontSize: 20,
  '> svg': {
    display: 'inline',
    height: 12,
  },
})

export { PlanTerms }
