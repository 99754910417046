import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { COLOR, bp } from '@helloextend/customers-ui'
import { logEvent } from '../../analytics'
import { images } from '../../lib/assets'
import { PlanDetailsFields } from './plan-details-fields'
import { OrderDetailsFields } from './order-details-fields'
import { ProductDetailsProp } from './contract-details-page'
import type { ContractsSearchIndividual } from '../../types/contract'

interface ContractPlanDetailsProps {
  contract: ContractsSearchIndividual
  isADH: boolean
  product: ProductDetailsProp
}

const ContractPlanDetails: FC<ContractPlanDetailsProps> = ({ contract, isADH, product }) => {
  const [hasDetailsFlag, setDetailsFlag] = useState(false)

  const viewPlanDetails = (): void => {
    setDetailsFlag(false)
    logEvent('Contract Details - Clicks', 'View Plan Details Clicked')
  }
  const viewOrderDetails = (): void => {
    setDetailsFlag(true)
    logEvent('Contract Details - Clicks', 'View Order Details Clicked')
  }
  return (
    <ContractPlanDetailsWrapper data-cy="contract-plan-details">
      <ImageWrapper>
        <ProductImage image={product.imageUrl} title={product.name} />
      </ImageWrapper>
      <DetailsContentWrapper>
        <TitleWrapper>
          <PlanDetailsTitle onClick={viewPlanDetails} hasDetailsFlag={hasDetailsFlag} data-cy="plan-details-title">
            Plan Details
          </PlanDetailsTitle>
          <OrderDetailsTitle onClick={viewOrderDetails} hasDetailsFlag={hasDetailsFlag} data-cy="order-details-title">
            Order Details
          </OrderDetailsTitle>
        </TitleWrapper>
        {hasDetailsFlag ? (
          <OrderDetailsFields contract={contract} product={product} />
        ) : (
          <PlanDetailsFields contract={contract} isADH={isADH} />
        )}
      </DetailsContentWrapper>
    </ContractPlanDetailsWrapper>
  )
}

const ContractPlanDetailsWrapper = styled.div({
  display: 'flex',
  backgroundColor: COLOR.BLUE[100],
  flexWrap: 'wrap',
  borderRadius: '16px',
  [bp.mobile]: {
    flexDirection: 'column',
    marginBottom: 60,
  },
  [bp.desktop]: {
    flexDirection: 'row',
    marginBottom: 80,
  },
})

const DetailsContentWrapper = styled.div({
  display: 'flex',
  boxSizing: 'border-box',
  flexDirection: 'column',
  [bp.mobile]: {
    padding: '24px 20px 20px',
  },
  [bp.desktop]: {
    flexGrow: 1,
    padding: '32px 24px 32px 40px',
  },
})

const ImageWrapper = styled.div({
  display: 'flex',
  [bp.mobile]: {
    paddingTop: 24,
    justifyContent: 'flex-start',
    paddingLeft: 24,
  },
  [bp.desktop]: {
    paddingLeft: 16,
    paddingTop: 16,
    paddingBottom: 16,
  },
})

const ProductImage = styled.div<{ image?: string }>(({ image }) => ({
  background: `no-repeat center center`,
  backgroundSize: 'cover',
  [bp.mobile]: {
    maxHeight: 160,
    maxWidth: 160,
    minHeight: 160,
    minWidth: 160,
    borderRadius: 8,
    backgroundImage: `url(${image}), url(${images.defaultMobileProductImage})`,
  },
  [bp.desktop]: {
    maxHeight: 320,
    maxWidth: 320,
    minHeight: 320,
    minWidth: 320,
    borderRadius: 16,
    backgroundImage: `url(${image}), url(${images.defaultProductImage})`,
  },
}))

const TitleWrapper = styled.div({
  display: 'flex',
  fontWeight: 'bold',
  lineHeight: '24px',
  width: 'fit-content',
  textColor: COLOR.BLUE[1000],
  [bp.mobile]: {
    flexDirection: 'row',
    fontSize: 17,
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: 20,
    lineHeight: '28px',
  },
})

export const PlanDetailsTitle = styled.button<{ hasDetailsFlag: boolean }>(
  ({ hasDetailsFlag }) => ({
    border: 'none',
    backgroundColor: 'inherit',
    borderBottom: hasDetailsFlag ? 'none' : `1px solid ${COLOR.BLUE[800]}`,
    margin: 0,
    height: 'fit-content',
    cursor: 'pointer',
    fontWeight: 'bold',
    color: hasDetailsFlag ? COLOR.NEUTRAL[600] : COLOR.NEUTRAL[1000],
    '&:hover': {
      color: COLOR.NEUTRAL[800],
    },
    '&:active': {
      color: COLOR.NEUTRAL[1000],
    },
    '&:inactive': {
      color: COLOR.NEUTRAL[600],
    },
    [bp.mobile]: {
      fontSize: 17,
      lineHeight: '24px',
    },
    [bp.desktop]: {
      fontSize: 20,
      lineHeight: '28px',
    },
  }),
)
export const OrderDetailsTitle = styled.button<{ hasDetailsFlag: boolean }>(
  ({ hasDetailsFlag }) => ({
    border: 'none',
    backgroundColor: 'inherit',
    borderBottom: hasDetailsFlag ? `1px solid ${COLOR.BLUE[800]}` : 'none',
    margin: 0,
    height: 'fit-content',
    cursor: 'pointer',
    fontWeight: 'bold',
    color: hasDetailsFlag ? COLOR.NEUTRAL[1000] : COLOR.NEUTRAL[600],
    '&:hover': {
      color: COLOR.NEUTRAL[800],
    },
    '&:active': {
      color: COLOR.NEUTRAL[1000],
    },
    '&:inactive': {
      color: COLOR.NEUTRAL[600],
    },
    [bp.mobile]: {
      marginLeft: 24,
      fontSize: 17,
      lineHeight: '24px',
    },
    [bp.desktop]: {
      marginLeft: 40,
      fontSize: 20,
      lineHeight: '28px',
    },
  }),
)

export { ContractPlanDetails }
