import type { FC } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import styled from '@emotion/styled'
import { bp, COLOR } from '@helloextend/customers-ui'
import { Button } from '@extend/zen'
import { formatPhoneNumberLocal } from '@extend/client-helpers'
import * as selectors from '../../reducers/selectors'
import images from '../../lib/images'

const EmptyPageContent: FC = () => {
  const history = useHistory()
  const decodedAccessToken = useSelector(selectors.getDecodedAccessToken)
  const userContact =
    decodedAccessToken?.email || formatPhoneNumberLocal(decodedAccessToken?.phone_number as string)
  const handleOnContactSupport = (): void => history.push('/contact')
  const handleOnLogin = (): void => history.push('/authentication')

  return (
    <Wrapper data-cy="empty-page-wrapper">
      <Container>
        <Header data-cy="empty-page-header">
          <StyledImage alt="kaley" src={isMobile ? images.kaleySmall : images.kaleyLarge} />
          <Title data-cy="empty-page-title">Looking for plans?</Title>
        </Header>
        <Content data-cy="empty-page-content">
          Oops! Couldn&apos;t find any Extend plans for <br />
          <b>{userContact}</b>. Try logging in with the phone or email linked to your plan.
          <ButtonsContainer>
            <Button
              onClick={handleOnLogin}
              text="Log in with a different account"
              size={isMobile ? 'regular' : 'large'}
              fillContainer
              data-cy="empty-page-login-button"
            />
            <Button
              onClick={handleOnContactSupport}
              text="Contact Support"
              emphasis="low"
              size={isMobile ? 'regular' : 'large'}
              fillContainer
              data-cy="empty-page-contact-button"
            />
          </ButtonsContainer>
        </Content>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '70vh',
  justifyContent: 'center',
})

const Container = styled.div({
  maxWidth: 488,
})

const Header = styled.div({
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'row',
})

const Title = styled.div({
  color: COLOR.NEUTRAL[1000],
  fontWeight: 600,
  paddingLeft: '8px',
  [bp.mobile]: {
    fontSize: '28px',
    lineHeight: '36px',
  },
  [bp.desktop]: {
    fontSize: '44px',
    lineHeight: '52px',
  },
})

const Content = styled.div({
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  color: COLOR.NEUTRAL[800],
  fontWeight: 400,
  paddingTop: '16px',
  wordBreak: 'break-word',
  [bp.mobile]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: '20px',
    lineHeight: '28px',
  },
})

const ButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  [bp.mobile]: {
    paddingTop: '24px',
  },
  [bp.desktop]: {
    paddingTop: '48px',
  },
})

const StyledImage = styled.img({
  width: '48px',
  height: '48px',
})

export { EmptyPageContent }
