import React from 'react';

function Tracking() {
    return (
        <div>
            
        </div>
    );
}

export { Tracking };