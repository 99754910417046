import type { FC, Dispatch, SetStateAction } from 'react'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { COLOR, bp, Grid, Button, InfoBanner } from '@helloextend/customers-ui'
import type { ContractsUpdateRequest } from '@helloextend/extend-api-client'
import type {
  FormSubmitData,
  CustomerSubmitDataTypes,
} from '../../components/forms/customer-form-template.types'
import { TransferCustomerDetails } from './transfer-customer-details'
import type { ContractsSearchIndividual } from '../../types/contract'
import { ExtendedWarrantyProductDetails } from './product-details/extended-warranty-product-details'
import { CategoryProductDetails } from './product-details/category-product-details'

interface TransferConfirmViewProps {
  contract: ContractsSearchIndividual
  formData: CustomerSubmitDataTypes
  setStage: Dispatch<SetStateAction<string>>
  isLoading: boolean
  isSuccess: boolean
  updateContract: ({
    contractId,
    data,
  }: {
    contractId: string
    data: ContractsUpdateRequest
  }) => void
}

const TransferConfirmView: FC<TransferConfirmViewProps> = ({
  contract,
  formData,
  setStage,
  isLoading,
  isSuccess,
  updateContract,
}) => {
  const history = useHistory()
  const locale = history.location.pathname.split('/')[1]

  const handleTransfer = (): void => {
    updateContract({
      contractId: contract.id,
      data: formData as ContractsUpdateRequest,
    })
  }

  const handleCancel = (): void => {
    setStage('input')
  }

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        setStage('input')
      }
    }
  }, [history.action, setStage])

  useEffect(() => {
    if (isSuccess) {
      history.push({
        pathname: `/${locale}/my_plans/${contract.id}/transfer`,
        search: '?stage=summary',
      })
      setStage('summary')
    }
  }, [history, isSuccess, contract, setStage, locale])

  return (
    <TransferConfirmGrid dataCy="transfer-confirm-grid">
      <Title data-cy="title">Review Details of Your Transfer</Title>
      <StyledInfoBanner kind="warning">
        Please review the information below carefully. You will not be able to view or manage this
        plan after the transfer is complete.
      </StyledInfoBanner>
      {contract.type === 'pcrs' && <ExtendedWarrantyProductDetails contract={contract} />}
      {contract.type === 'category' && <CategoryProductDetails contract={contract} />}
      <StyledCustomerDetails formData={formData as FormSubmitData} dataCy="customer-form" />
      <ButtonRow>
        <CancelButton
          kind="dangerSecondary"
          text="Go back"
          onClick={handleCancel}
          isDisabled={isLoading}
          dataCy="cancel-button"
        />
        <TransferButton
          kind="primary"
          text="Transfer plan"
          onClick={handleTransfer}
          isLoading={isLoading}
          dataCy="transfer-button"
        />
      </ButtonRow>
    </TransferConfirmGrid>
  )
}

const TransferConfirmGrid = styled(Grid)({
  flexDirection: 'column',
  width: '100%',
  [bp.mobile]: {
    padding: '0 20px',
  },
  [bp.desktop]: {
    padding: 0,
  },
})

const Title = styled.div({
  color: COLOR.NEUTRAL[1000],
  [bp.mobile]: {
    margin: '40px 0 0',
    fontSize: 20,
    lineHeight: '28px',
  },
  [bp.desktop]: {
    width: 668,
    margin: '60px auto 0',
    fontSize: 28,
    lineHeight: '36px',
  },
})

const StyledInfoBanner = styled(InfoBanner)({
  [bp.mobile]: {
    margin: '16px 0',
  },
  [bp.desktop]: {
    width: 668,
    margin: '32px auto',
  },
})

const StyledCustomerDetails = styled(TransferCustomerDetails)({
  [bp.mobile]: {
    margin: '16px 0 24px',
  },
  [bp.desktop]: {
    margin: '32px auto 40px',
  },
})

const ButtonRow = styled(Grid)({
  justifyContent: 'center',
  gap: 16,
  [bp.mobile]: {
    margin: '0 auto 60px',
  },
  [bp.desktop]: {
    margin: '0 auto 80px',
    maxWidth: 604,
  },
})

const TransferButton = styled(Button)({
  width: 'auto',
  [bp.mobile]: {
    height: 38,
    borderRadius: 20,
    fontSize: 16,
    padding: '8px 24px',
  },
  [bp.desktop]: {
    height: 47,
    borderRadius: 24,
    fontSize: 20,
    padding: '10px 48px',
  },
})

const CancelButton = styled(Button)({
  width: 'auto',
  [bp.mobile]: {
    borderRadius: 20,
    fontSize: 16,
    height: 38,
    padding: '8px 24px',
  },
  [bp.desktop]: {
    borderRadius: 24,
    fontSize: 20,
    height: 47,
    padding: '10px 48px',
  },
})

export type { TransferConfirmViewProps }
export { TransferConfirmView }
