import type { FC } from 'react'
import React from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { bp, Grid, ViewField } from '@helloextend/customers-ui'
import { currency } from '@extend/client-helpers'
import { ProductDetailsProp } from './contract-details-page'
import type { ContractsSearchIndividual } from '../../types/contract'

export interface OrderDetailsFieldsProps {
  contract: ContractsSearchIndividual
  product: ProductDetailsProp
}

const OrderDetailsFields: FC<OrderDetailsFieldsProps> = ({ contract, product }) => {
  const { purchasePrice, sellerName } = contract
  const purchaseDate = `${dayjs(contract.purchaseDate).format('MMMM D, YYYY')}`
  return (
    <OrderDetailsGrid dataCy="order-details-grid">
      <StyledViewField dataCy="sellerName" title="SELLING RETAILER" value={sellerName} />
      <StyledViewField
        dataCy="purchasePrice"
        title="PLAN PRICE"
        value={currency.format(purchasePrice)}
      />
      <StyledViewField dataCy="purchaseDate" title="PURCHASE DATE" value={purchaseDate} />
      <StyledViewField
        dataCy="productPrice"
        title="PURCHASE PRICE"
        value={currency.format(product.purchasePrice)}
      />
    </OrderDetailsGrid>
  )
}

const OrderDetailsGrid = styled(Grid)({
  marginTop: 8,
  [bp.mobile]: {
    flexDirection: 'column',
  },
  [bp.desktop]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 16,
  },
})

const StyledViewField = styled(ViewField)({
  marginTop: 24,
  [bp.desktop]: {
    maxWidth: 250,
  },
})

export { OrderDetailsFields }
