import { FormattedMessage } from '@helloextend/customers-ui'
import React from 'react'

export const TESTIMONIALS = [
  {
    isLongestReview: true,
    name: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_PERRY"
        defaultMessage="Perry W."
        description="Name of customer giving testimonial."
      />
    ),
    comment: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_PERRY'S_COMMENT"
        defaultMessage="Everyone has been so nice helping me get my product replaced with a new one. I will be applying for this protection plan again."
        description="Testimonial of a customer."
      />
    ),
  },
  {
    name: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_MATTHEW"
        defaultMessage="Matthew W."
        description="Name of customer giving testimonial."
      />
    ),
    comment: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_MATTHEW'S_COMMENT"
        defaultMessage="Great customer service. Fast turn around time."
        description="Testimonial of a customer."
      />
    ),
  },
  {
    name: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_EDWIN"
        defaultMessage="Edwin B."
        description="Name of customer giving testimonial."
      />
    ),
    comment: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_EDWIN'S_COMMENT"
        defaultMessage="Awesome service. Product was out of stock so they let me choose a similar product!"
        description="Testimonial of a customer."
      />
    ),
  },
  {
    name: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_LIWEI"
        defaultMessage="LiWei J."
        description="Name of customer giving testimonial."
      />
    ),
    comment: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_LIWEI'S_COMMENT"
        defaultMessage="The entire process was very easy, and the customer service was great. Communication was fast and prompt!"
        description="Testimonial of a customer."
      />
    ),
  },
  {
    name: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_WAYNE"
        defaultMessage="Wayne O."
        description="Name of customer giving testimonial."
      />
    ),
    comment: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_WAYNE'S_COMMENT"
        defaultMessage="Extend has truly helped me out. They replaced my broken parts quickly and are a great company to work with."
        description="Testimonial of a customer."
      />
    ),
  },
  {
    name: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_JOHN"
        defaultMessage="John N."
        description="Name of customer giving testimonial."
      />
    ),
    comment: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_JOHN'S_COMMENT"
        defaultMessage="It was a no hassle process. Submitted my claim and received a new motor in a reasonable time. Absolutely love it. Thanks!"
        description="Testimonial of a customer."
      />
    ),
  },
  {
    name: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_CLINTON"
        defaultMessage="Clinton H."
        description="Name of customer giving testimonial."
      />
    ),
    comment: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_CLINTON'S_COMMENT"
        defaultMessage="A perfect and simple process. Buy these protection plans and you wont be sorry AAA+++++++++++++"
        description="Testimonial of a customer."
      />
    ),
  },
  {
    name: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_TERRY"
        defaultMessage="Terry G."
        description="Name of customer giving testimonial."
      />
    ),
    comment: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_TERRY'S_COMMENT"
        defaultMessage="Very easy and helpful to request a claim."
        description="Testimonial of a customer."
      />
    ),
  },
  {
    name: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_JORDAN"
        defaultMessage="Jordan G."
        description="Name of customer giving testimonial."
      />
    ),
    comment: (
      <FormattedMessage
        id="SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_JORDAN'S_COMMENT"
        defaultMessage="So fast and easy. I purchase Extend plans on all electronics that are prone to power surges that always happen where I live."
        description="Testimonial of a customer."
      />
    ),
  },
]

export const SHIPPING_PROTECTION_TESTIMONIALS = [
  {
    name: 'Colleen K.',
    comment: 'Honesty, excellent customer service & respect.',
  },
  {
    name: 'Michael S.',
    comment: 'Great customer service!',
  },
  {
    name: 'Christopher K.',
    comment: 'Extend gave me the confidence to purchase worry-free.',
  },
]
