import type { FormEvent, SyntheticEvent, FC } from 'react'
import type { CSSObject } from '@emotion/react'
import { css } from '@emotion/react'
import { COLOR } from '@extend/zen'

import { Spinner } from '../spinner'

export type ButtonKind =
  | 'primary'
  | 'secondary'
  | 'login'
  | 'danger'
  | 'dangerSecondary'
  | 'disabled'
  | 'pillButton'
  | 'whiteOutline'

export type ButtonType = 'submit' | 'reset' | 'button'

export type ButtonSize = 'xxs' | 'xs' | 'sm' | 'lg'

type EventType = SyntheticEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>

export interface ButtonProps {
  /** @default false */
  isBlock?: boolean
  dataQa?: string
  dataCy?: string
  ariaLabel?: string
  /** @default false */
  isDisabled?: boolean
  /** @default 'primary' */
  kind?: ButtonKind
  /** @default false */
  isLoading?: boolean
  onClick?: (event: EventType) => void
  /** @default 'lg' */
  size?: ButtonSize
  text: string
  /** @default 'button' */
  type?: ButtonType
  name?: string
  isInverted?: boolean
  className?: string
}

const Button: FC<ButtonProps> = ({
  isBlock = false,
  dataQa = null,
  dataCy = null,
  ariaLabel,
  isDisabled = false,
  kind = 'primary',
  isLoading = false,
  onClick = function noop() {
    // do nothing
  },
  size = 'lg',
  text,
  type = 'button',
  name,
  isInverted = false,
  className,
}) => {
  const passThrough = { onClick, type, name }
  if (dataQa) {
    Object.assign(passThrough, { 'data-qa': dataQa })
  }

  if (dataCy) {
    Object.assign(passThrough, { 'data-cy': dataCy })
  }

  Object.assign(passThrough, { 'aria-label': ariaLabel ?? text })

  return (
    <button
      {...passThrough}
      disabled={isDisabled || isLoading}
      className={className}
      css={css`
        ${baseStyles},
        ${isInverted ? kindStylesInverted[kind] : kindStyles[kind]},
        ${sizeStyles[size]},
        ${isBlock && { width: '100%' }},
        ${isLoading && (loadingStyles[kind] || loadingStyles.default)},
        ${isDisabled && (disabledStyles[kind] || disabledStyles.default)},
      `}
    >
      {isLoading && <Spinner color="inherit" />}
      <span css={{ visibility: isLoading ? 'hidden' : 'visible' }}>{text}</span>
    </button>
  )
}

// Styles
// ==================================================================================
export const baseStyles: CSSObject = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'transparent',
  boxShadow: 'none',
  boxSizing: 'border-box',
  borderRadius: 24,
  color: COLOR.NEUTRAL[200],
  cursor: 'pointer',
  fontWeight: 700,
  lineHeight: 1,
  position: 'relative',
  transitionProperty: 'background-color, color, border-color',
  transitionDuration: '200ms',
  '&:focus': {
    outline: 'none',
    borderColor: COLOR.BLUE[700],
    boxShadow: `0px 0px 0px 2px ${COLOR.WHITE},
                0px 0px 3px 2px rgba(0, 51, 204, 0.5)`,
  },
}

export const sizeStyles: Record<ButtonSize, CSSObject> = {
  xxs: {
    fontSize: 16,
    height: 40,
    padding: '8px 16px',
  },
  xs: {
    fontSize: 16,
    height: 40,
    padding: '11px 16px',
  },
  sm: {
    fontSize: 16,
    height: 38,
    padding: '8px 24px',
  },
  lg: {
    fontSize: 20,
    height: 47,
    padding: '10px 48px',
  },
}

const disabledStyles: CSSObject = {
  primary: {
    backgroundColor: COLOR.NEUTRAL[300],
    borderColor: COLOR.NEUTRAL[300],
    color: COLOR.NEUTRAL[800],
    cursor: 'not-allowed',
    '&:hover,&:focus': {
      backgroundColor: COLOR.NEUTRAL[300],
      borderColor: COLOR.NEUTRAL[300],
    },
  },
  secondary: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.NEUTRAL[300],
    color: COLOR.NEUTRAL[600],
    cursor: 'not-allowed',
    '&:hover,&:focus': {
      backgroundColor: COLOR.WHITE,
      borderColor: COLOR.NEUTRAL[300],
      color: COLOR.NEUTRAL[600],
    },
  },
  danger: {
    backgroundColor: COLOR.RED[300],
    borderColor: COLOR.RED[300],
    color: COLOR.WHITE,
    cursor: 'not-allowed',
    '&:hover,&:focus': {
      backgroundColor: COLOR.RED[300],
      borderColor: COLOR.RED[300],
    },
  },
  dangerSecondary: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.RED[300],
    color: COLOR.RED[300],
    cursor: 'not-allowed',
    '&:hover,&:focus': {
      backgroundColor: COLOR.WHITE,
      borderColor: COLOR.RED[300],
      color: COLOR.RED[300],
    },
  },
  default: {
    backgroundColor: COLOR.NEUTRAL[400],
    borderColor: COLOR.NEUTRAL[400],
    color: COLOR.WHITE,
    cursor: 'not-allowed',
    '&:hover,&:focus': {
      backgroundColor: COLOR.NEUTRAL[400],
      borderColor: COLOR.NEUTRAL[400],
    },
    '&:disabled': {
      backgroundColor: COLOR.NEUTRAL[400],
      borderColor: COLOR.NEUTRAL[400],
      color: COLOR.WHITE,
      '&:hover': {
        backgroundColor: COLOR.NEUTRAL[400],
        borderColor: COLOR.NEUTRAL[400],
      },
    },
  },
}

const loadingStyles: CSSObject = {
  primary: {
    cursor: 'not-allowed',
    [`& .spinner`]: {
      position: 'absolute',
      left: 'calc(50% - 13px)',
      top: 'calc(50% - 15px)',
    },
    backgroundColor: COLOR.BLUE[500],
    borderColor: COLOR.BLUE[500],
    color: COLOR.WHITE,
    '&:hover,&:focus': {
      backgroundColor: COLOR.BLUE[500],
      borderColor: COLOR.BLUE[500],
      color: COLOR.WHITE,
    },
  },
  secondary: {
    cursor: 'not-allowed',
    [`& .spinner`]: {
      position: 'absolute',
      left: 'calc(50% - 13px)',
      top: 'calc(50% - 15px)',
    },
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.BLUE[500],
    color: COLOR.BLUE[500],
    '&:hover,&:focus': {
      backgroundColor: COLOR.WHITE,
      borderColor: COLOR.BLUE[500],
      color: COLOR.BLUE[500],
    },
  },
  danger: {
    cursor: 'not-allowed',
    [`& .spinner`]: {
      position: 'absolute',
      left: 'calc(50% - 13px)',
      top: 'calc(50% - 15px)',
    },
    backgroundColor: COLOR.RED[300],
    borderColor: COLOR.RED[300],
    color: COLOR.WHITE,
    '&:hover,&:focus': {
      backgroundColor: COLOR.RED[300],
      borderColor: COLOR.RED[300],
      color: COLOR.WHITE,
    },
  },
  dangerSecondary: {
    cursor: 'not-allowed',
    [`& .spinner`]: {
      position: 'absolute',
      left: 'calc(50% - 13px)',
      top: 'calc(50% - 15px)',
    },
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.RED[300],
    color: COLOR.RED[300],
    '&:hover,&:focus': {
      backgroundColor: COLOR.WHITE,
      borderColor: COLOR.RED[300],
      color: COLOR.RED[300],
    },
  },
  default: {
    cursor: 'not-allowed',
    [`& .spinner`]: {
      position: 'absolute',
      left: 'calc(50% - 13px)',
      top: 'calc(50% - 15px)',
    },
  },
}

export const kindStyles: Record<ButtonKind, CSSObject> = {
  primary: {
    backgroundColor: COLOR.BLUE[800],
    border: COLOR.BLUE[800],
    color: COLOR.WHITE,
    '&:focus': {
      backgroundColor: COLOR.BLUE[800],
      borderColor: COLOR.BLUE[800],
    },
    '&:hover,&:active': {
      borderColor: COLOR.BLUE[900],
      backgroundColor: COLOR.BLUE[900],
    },
  },
  login: {
    backgroundColor: COLOR.BLACK,
    border: 'none',
    color: COLOR.WHITE,
    '&:hover,&:active': {
      textDecoration: 'underline',
    },
    margin: '0 !important',
  },
  secondary: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.BLUE[800],
    color: COLOR.BLUE[800],
    '&:focus': {
      borderColor: COLOR.BLUE[800],
      color: COLOR.BLUE[800],
    },
    '&:hover,&:active': {
      color: COLOR.BLUE[900],
      borderColor: COLOR.BLUE[900],
    },
  },
  danger: {
    backgroundColor: COLOR.RED[700],
    borderColor: COLOR.RED[700],
    color: COLOR.WHITE,
    '&:focus': {
      borderColor: COLOR.RED[700],
      backgroundColor: COLOR.RED[700],
    },
    '&:hover,&:active': {
      borderColor: COLOR.RED[800],
      backgroundColor: COLOR.RED[800],
    },
  },
  dangerSecondary: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.RED[700],
    color: COLOR.RED[700],
    '&:focus': {
      borderColor: COLOR.RED[700],
      color: COLOR.RED[700],
    },
    '&:hover,&:active': {
      borderColor: COLOR.RED[800],
      color: COLOR.RED[800],
    },
  },
  // disabled appearance styling
  disabled: {
    backgroundColor: COLOR.NEUTRAL[400],
    borderColor: COLOR.NEUTRAL[400],
    color: COLOR.WHITE,
  },
  // new kind is specific to chat portal buttons
  pillButton: {
    borderColor: COLOR.BLUE[700],
    color: COLOR.BLUE[700],
    backgroundColor: COLOR.WHITE,
    borderRadius: 16,
    fontWeight: 'normal',
    '&:hover': {
      backgroundColor: COLOR.BLUE[700],
      color: COLOR.WHITE,
    },
  },
  // Error banner in customer portal
  whiteOutline: {
    borderColor: COLOR.WHITE,
    backgroundColor: 'transparent',
    color: COLOR.WHITE,
    '&:hover': {
      backgroundColor: COLOR.WHITE,
      color: COLOR.RED[500],
    },
  },
}

export const kindStylesInverted: Record<ButtonKind, CSSObject> = {
  primary: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.WHITE,
    color: COLOR.BLUE[800],
    '&:focus': {
      borderColor: COLOR.WHITE,
    },
    '&:hover,&:active': {
      color: COLOR.BLUE[900],
    },
  },
  login: {
    backgroundColor: COLOR.WHITE,
    border: 'none',
    color: COLOR.BLACK,
    '&:hover,&:active': {
      textDecoration: 'underline',
    },
    margin: '0 !important',
  },
  secondary: {
    borderColor: COLOR.WHITE,
    color: COLOR.WHITE,
    '&:focus': {
      backgroundColor: COLOR.BLUE[800],
    },
    '&:hover,&:active': {
      backgroundColor: COLOR.BLUE[900],
    },
  },
  danger: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.WHITE,
    color: COLOR.RED[700],
    '&:focus': {
      borderColor: COLOR.WHITE,
    },
    '&:hover,&:active': {
      color: COLOR.RED[800],
    },
  },
  dangerSecondary: {
    borderColor: COLOR.WHITE,
    color: COLOR.RED[700],
    '&:focus': {
      color: COLOR.RED[700],
      borderColor: COLOR.WHITE,
    },
    '&:hover,&:active': {
      color: COLOR.RED[800],
    },
  },
  // disabled appearance styling
  disabled: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.WHITE,
    color: COLOR.NEUTRAL[400],
  },
  // new kind is specific to chat portal buttons
  pillButton: {
    backgroundColor: COLOR.WHITE,
    borderWidth: 1,
    '&:hover': {
      borderColor: COLOR.BLACK,
      color: COLOR.BLACK,
    },
    '&:hover,&:focus': {
      color: COLOR.BLACK,
    },
  },
  // Error banner in customer portal
  whiteOutline: {
    backgroundColor: COLOR.WHITE,
    borderWidth: 1,
    '&:hover': {
      borderColor: COLOR.BLACK,
      color: COLOR.BLACK,
    },
    '&:hover,&:focus': {
      color: COLOR.BLACK,
    },
  },
}

export { Button }
