import type { ClaimStatus } from '@helloextend/extend-api-client'
import { COLOR } from '@helloextend/customers-ui'

interface Colors {
  primary: string
  secondary: string
}

export const colorScheme: Record<ClaimStatus, Colors> = {
  approved: {
    primary: COLOR.GREEN[500],
    secondary: COLOR.GREEN[200],
  },
  fulfilled: {
    primary: COLOR.GREEN[500],
    secondary: COLOR.GREEN[200],
  },
  closed: {
    primary: COLOR.NEUTRAL[500],
    secondary: COLOR.NEUTRAL[200],
  },
  review: {
    primary: COLOR.YELLOW[500],
    secondary: COLOR.YELLOW[200],
  },
  denied: {
    primary: COLOR.NEUTRAL[500],
    secondary: COLOR.RED[600],
  },
  pending: {
    primary: COLOR.YELLOW[500],
    secondary: COLOR.YELLOW[200],
  },
  pending_adjudication: {
    primary: COLOR.YELLOW[500],
    secondary: COLOR.YELLOW[200],
  },
}
