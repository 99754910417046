import type { PresignedPost } from 'aws-sdk/clients/s3'
import type { DefaultPrompt } from '@extend-conversations/types'
import type { ClaimStatus, ShippingStatus } from './claim'
import type { ServiceType } from './plan'

export enum Slot {
  HelpType = 'HelpType',
  ClaimType = 'ClaimType',
  ContractsSearchKey = 'ContractsSearchKey',
  ResetContractSearch = 'ResetContractSearch',
  VerificationCode = 'VerificationCode',
  ContractId = 'ContractId',
  ClaimId = 'ClaimId',
  IncidentDate = 'IncidentDate',
  ClaimExists = 'ClaimExists',
  AdjudicationType = 'AdjudicationType',
  FailureType = 'FailureType',
  FailureDescription = 'FailureDescription',
  EndClaim = 'EndClaim',
  ClaimPhotoUpload = 'ClaimPhotoUpload',
  ProductSection = 'ProductSection',
  ShippingAddress = 'ShippingAddress',
  ClaimItem = 'ClaimItem',
  Shipment = 'Shipment',
  Products = 'Products',
  SearchType = 'SearchType',
}

export type PromptType =
  | 'input'
  | 'inputWithButtons'
  | 'buttons'
  | 'carousel'
  | 'datepicker'
  | 'multiselect'
  | 'imageUpload'
  | 'addressInput'
  | 'shipmentSelect'
  | 'productSelect'

export interface BasePrompt<TType extends PromptType> {
  slot: Slot
  type: TType
}

export interface InputPrompt extends BasePrompt<'input'> {
  placeholder?: string
  validationType?: DefaultPrompt['validationType']
}

export interface InputWithButtonsPrompt extends BasePrompt<'inputWithButtons'> {
  placeholder?: string
  options: SelectPromptOption[]
}

export interface ClaimCardTrackingActivity {
  message: string
  date: Date
}

export interface Card {
  contractId: string
  claimId: string
  updatedAt: number
  status: ClaimStatus
  value?: string
  sellerName: string
  title: string
  imageUrl?: string
  shippingStatus?: ShippingStatus
  claimTrackingActivity: ClaimCardTrackingActivity[]
  serviceType: ServiceType
}

export interface ButtonsPrompt extends BasePrompt<'buttons'> {
  options: SelectPromptOption[]
  card?: Card
}

export interface CarouselPrompt extends BasePrompt<'carousel'> {
  options: CarouselPromptOption[]
}

export type CarouselPromptOption = ContractCarouselPromptOption | OrderCarouselPromptOption

export type ContractCarouselPromptOption = SelectPromptOption & {
  // this is the generic version, for now, use hard coded value based on actual expected values from api
  // attributes: Record<string, string | number>
  attributes: {
    storeName: string
    transactionDate: number
    purchasePrice: number
  }
  status?: ClaimStatus
  serviceType?: ServiceType
  type: 'contract'
}

export interface OrderCarouselPromptOption {
  additionalItems: number
  items: OrderCarouselItem[]
  orderId: string
  outputText: string
  purchaseDate: number
  purchasePrice: number
  title: string
  type: 'order'
  value: string
}

export interface OrderCarouselItem {
  imageUrl: string
  purchasePrice: number
  quantity: number
  title: string
}

export interface DatePickerPrompt extends BasePrompt<'datepicker'> {}

export interface ContractAndLineItemId {
  contractId: string
  lineItemId?: string
}

export interface SelectPromptOption {
  title: string
  value: string | ContractAndLineItemId
  imageUrl?: string
  outputText?: string
  redirectUrl?: string
  enableDelaySec?: number
}

export interface ImageUploadPrompt extends BasePrompt<'imageUpload'> {
  description: string
  name: string
  presignedPost: PresignedPost
  type: 'imageUpload'
}

export interface MultiSelectPrompt extends BasePrompt<'multiselect'> {
  title: string
  options: MultiSelectPromptOption[]
}
export type MultiSelectPromptOption = {
  title: string
  outputText: string
  value: string
  imageUrl?: string
}

export interface AddressInputPrompt extends BasePrompt<'addressInput'> {}

export interface ShipmentSelectPrompt extends BasePrompt<'shipmentSelect'> {
  title: string
  options: ShipmentSelectPromptOption[]
}

export interface ShipmentSelectPromptOption {
  title: string
  value: string
  outputText?: string
  trackingUrl?: string
  products: SelectPromptProduct[]
}

export interface SelectPromptProduct {
  title: string
  imageUrl?: string
}

export interface ProductSelectPrompt extends BasePrompt<'productSelect'> {
  title: string
  type: 'productSelect'
  options: ProductSelectPromptOption[]
}

export interface ProductSelectPromptOption extends SelectPromptProduct {
  value: string
}

export type Prompt =
  | InputPrompt
  | InputWithButtonsPrompt
  | ButtonsPrompt
  | CarouselPrompt
  | DatePickerPrompt
  | MultiSelectPrompt
  | ImageUploadPrompt
  | AddressInputPrompt
  | ShipmentSelectPrompt
  | ProductSelectPrompt

// a robot's reply when the user sends claim information
export interface Reply {
  messages: Message[]
  prompt?: Prompt
  poll?: Poll
  claimId?: string
}

export interface InitialReply extends Reply {
  prompt: Prompt
  accessToken: string
}

export interface Poll {
  [key: string]: string | undefined
}

export interface Message {
  type: 'text' | 'textSelect'
  content: string
  elements?: Element[]
  fallbackMessage?: string
}

export interface Element {
  elementId: string
  elementType: ElementType
  link?: LinkContent
  resendCodeData?: ResendCodeData
}

export enum ElementType {
  Link = 'Link',
  Bold = 'Bold',
  ResendCodeLink = 'ResendCodeLink',
}

type ResendCodeData = {
  value: string
  text: string
}

export interface LinkContent {
  href: string
  isExternal: boolean
  text: string
  downloadTitle?: string
}

// Slot values

export enum HelpTypeSlotValue {
  START_NEW_CLAIM = 'START_NEW_CLAIM',
  TRACK_CLAIM = 'TRACK_CLAIM',
  END_CLAIM = 'END_CLAIM',
}

export enum FailureTypeSlotValue {
  ACCIDENT_DROP = 'ACCIDENT_DROP',
  ACCIDENT_LIQUID = 'ACCIDENT_LIQUID',
  ELECTRIC = 'ELECTRIC',
  MECHANICAL = 'MECHANICAL',
}

export type SlotValue = FailureTypeSlotValue | HelpTypeSlotValue | string | number

// Claims assitant contact form
export interface ContactFormValues {
  name: string
  email: string
  phoneNumber: string
  requestType: string
  message: string
  locale: string
}

export enum ClaimSource {
  chatbot = 'chatbot',
  ops_admin = 'ops_admin',
  merch_admin = 'merch_admin',
}
