import styled from '@emotion/styled'
import { Timeline, TimelineItem } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'

export const ManualReplacementApprovedTimeline: FC = () => {
  return (
    <TimelineWrapper data-cy="manual-replacement-timeline">
      <Timeline>
        <TimelineItem
          label="Claim approved"
          state="complete"
          data-cy="claim-approved-timeline-item"
        />
        <TimelineItem
          label="Waiting for seller to ship replacement product"
          state="current"
          data-cy="seller-ship-timeline-item"
        />
        <TimelineItem
          label="Claim fulfilled"
          state="upcoming"
          data-cy="claim-fulfilled-timeline-item"
        />
      </Timeline>
    </TimelineWrapper>
  )
}

const TimelineWrapper = styled.div({
  width: '100%',
})
