import type {
  Claim,
  GetClaimsReponse,
  ServiceOrder,
  ServiceType,
} from '@helloextend/extend-api-client'

export const getIsRepairClaim = (claim: Claim | GetClaimsReponse | ServiceOrder): boolean => {
  return claim.serviceType.includes('repair')
}

export const getIsRepairPlan = (serviceType: ServiceType): boolean => {
  return serviceType.includes('repair')
}
