import type { FC } from 'react'
import React, { memo, useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { COLOR } from '@helloextend/customers-ui'

interface BubbleProps {
  width: number
  height: number
  prevWidth: number
  open: boolean
  duration: number
  bot: boolean
  lastIndex: boolean
  isTyping: boolean
  role: string
  handleClick: () => void
}

export interface ChatBubbleProps extends BubbleProps {}

// TODO: these are fixed values, should ideally be calculating this on the fly
const TYPING_BUBBLE_HEIGHT = 28
const TYPING_BUBBLE_WIDTH = 68
const BUBBLE_PADDING = '8px 16px'

const ChatBubble: FC<ChatBubbleProps> = memo((props) => {
  const { bot, height, handleClick, role, width, duration } = props
  const [showBubble, setShowBubble] = useState(false)

  const divProps = useSpring({
    from: { height: 0 },
    to: { height },
    config: { duration },
  })

  useEffect(() => {
    let timeout: number
    if (!bot) {
      timeout = window.setTimeout(() => {
        setShowBubble(true)
      }, duration * 2)
    }
    return () => clearTimeout(timeout)
  }, [bot, duration])

  // Box out space to prevent the animation from dropping to the bottom of the screen on user inputs
  return !(bot || showBubble) ? (
    <animated.div style={{ ...divProps, width, padding: BUBBLE_PADDING }} />
  ) : (
    <BubbleWrapper
      role={role}
      height={height}
      onClick={handleClick}
      data-qa={role === 'button' ? 'text-select-button' : null}
    >
      <Bubble data-qa="chat-bubble" {...props} />
    </BubbleWrapper>
  )
})

interface AnimateSizeProps {
  width: number
  height: number
  isTyping: boolean
}

const animateSize = (props: AnimateSizeProps): ReturnType<typeof keyframes> => {
  const { width, height, isTyping } = props
  return keyframes({
    from: {
      width: isTyping ? '0px' : TYPING_BUBBLE_WIDTH,
      height: isTyping ? '0px' : TYPING_BUBBLE_HEIGHT,
    },
    to: {
      width: `${width}px`,
      height: `${height}px`,
    },
  })
}

const Bubble = styled.div((props: BubbleProps) => ({
  animation: `${animateSize(props)} ${props.duration}ms`,
  background: props.bot ? COLOR.NEUTRAL[100] : COLOR.BLUE[700],
  borderBottomLeftRadius: props.lastIndex && props.bot ? '2px' : '16px',
  borderBottomRightRadius: props.lastIndex && !props.bot ? '2px' : '16px',
  borderRadius: 16,
  color: props.bot ? COLOR.BLACK : COLOR.WHITE,
  display: 'inline-block',
  fontSize: 16,
  minHeight: props.isTyping ? props.height : TYPING_BUBBLE_HEIGHT,
  minWidth: props.isTyping ? props.width : TYPING_BUBBLE_WIDTH,
  overflow: 'hidden',
  padding: BUBBLE_PADDING,
  whiteSpace: 'break-spaces' as const,
}))

const BubbleWrapper = styled.div<{ height: number }>({}, ({ height }) => ({
  minHeight: height,
  '[role="button"]': {
    cursor: 'pointer',
  },
}))

export { ChatBubble }
