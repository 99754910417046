import styled from '@emotion/styled'
import { Timeline, TimelineItem, COLOR, LinkButton } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'
import type { Claim } from '../../../types/claim'

export interface ClaimPendingAdjudicationProps {
  claim: Claim
}

export const ClaimPendingAdjudication: FC<ClaimPendingAdjudicationProps> = ({ claim }) => {
  return (
    <Container data-cy="claim-pending-adjudication-timeline">
      <Timeline>
        <TimelineItem
          label="To process your claim, we need to verify your shipping address."
          state="current"
          data-cy="claim-in-review-timeline-item"
        />
      </Timeline>
      <LinkButton
        to={`/review_claim?claimId=${claim.id}&contractId=${claim.contractId}&sessionId=${claim.sessionId}`}
        data-cy="verify-shipping-address-button"
        text="Verify shipping address"
      />
    </Container>
  )
}

const Container = styled.div({
  width: '100%',
  background: `var(--white, ${COLOR.WHITE});`,
})
