import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import { COLOR } from '@helloextend/customers-ui'

interface ChatImageProps {
  image?: { src: string; width: number; height: number }
  caption: string
}

const ChatImage: FC<ChatImageProps> = ({ image, caption }) => {
  if (!image) return null
  const { src, width, height } = image
  // derive whether the image will be framed as a portrait based on its
  // aspect ratio in pixels (width : height)
  const hasNaturalPortraitSize = width / height < 1.1

  return (
    <ImageWrapper data-cy="chat-image" isPortrait={hasNaturalPortraitSize}>
      <img src={src} alt={caption} />
    </ImageWrapper>
  )
}

const ImageWrapper = styled.div<{ isPortrait: boolean }>(({ isPortrait }) => ({
  width: isPortrait ? 111 : 157,
  height: isPortrait ? 170.5 : 102,
  marginTop: 8,
  marginBottom: 8,
  backgroundColor: COLOR.NEUTRAL[100],
  [bp.md]: {
    width: isPortrait ? 163 : 383,
    height: 250,
  },
  img: {
    height: '100%',
    objectFit: 'cover',
    overflow: 'hidden',
    width: '100%',
  },
}))

export type { ChatImageProps }
export { ChatImage }
