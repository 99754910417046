import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import { Accordion } from '@helloextend/customers-ui/src/components/accordion'
import type { V2 } from '@extend-services/service-orders'
import { FAQ_TEXT } from '../../lib/faqs'
import type { FulfillmentMethod } from '../../types/faq'
import type { Locale } from '../../types/localization'

interface FaqsProps {
  fulfillmentType: 'repair' | V2.Models.ReplacementFulfillmentMethod
  locale?: Locale
}

const Faqs: FC<FaqsProps> = ({ fulfillmentType, locale = 'en-US' }) => {
  const { claimFulfillment, title }: { claimFulfillment: FulfillmentMethod; title: string } =
    FAQ_TEXT[locale]
  const faqList = claimFulfillment[fulfillmentType]
  const faqListTransform = faqList.map(Object.values) as Array<[string, string]>
  return (
    <Section>
      <Header>{title}</Header>
      <Accordion list={faqListTransform} />
    </Section>
  )
}

const Section = styled.section({
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  [bp.md]: {
    paddingBottom: 112,
  },
  [bp.maxWidthMd]: {
    paddingBottom: 60,
  },
})

const Header = styled.h2({
  fontWeight: 400,
  textAlign: 'center',
  margin: '0px auto 24px',
  [bp.md]: {
    fontSize: 28,
    lineHeight: '36px',
    margin: '0px 0 32px',
  },
  [bp.maxWidthMd]: {
    fontSize: 20,
    lineHeight: '28px',
  },
})

export { Faqs }
