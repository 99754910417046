import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, bp } from '@helloextend/customers-ui'
import { ClaimCard } from './claim-card'
import type { ClaimDetails, MappedClaimDetails } from '../../types/claim'

export interface ViewAllClaimsProps {
  claimDetails: MappedClaimDetails
}

const ViewAllClaims: FC<ViewAllClaimsProps> = ({ claimDetails }) => {
  const claimDetailsArr = Object.values(claimDetails)

  const completed = sortClaims(
    claimDetailsArr.filter((claimDetail: ClaimDetails) => {
      return (
        claimDetail.claim.status === 'fulfilled' ||
        claimDetail.claim.status === 'denied' ||
        claimDetail.claim.status === 'closed'
      )
    }),
  )
  const inProgress = sortClaims(
    claimDetailsArr.filter((claimDetail: ClaimDetails) => {
      return (
        claimDetail.claim.status === 'approved' ||
        claimDetail.claim.status === 'review' ||
        claimDetail.claim.status === 'pending_adjudication'
      )
    }),
  )

  return (
    <AllClaimsWrapper data-cy="all-claims-wrapper">
      {inProgress.length > 0 && (
        <StatusWrapper>
          <ClaimsWrapper>{inProgress}</ClaimsWrapper>
        </StatusWrapper>
      )}
      {completed.length > 0 && (
        <StatusWrapper>
          <StatusTitle>Completed Claims</StatusTitle>
          <ClaimsWrapper>{completed}</ClaimsWrapper>
        </StatusWrapper>
      )}
    </AllClaimsWrapper>
  )
}

export const sortClaims = (
  detailArr: ClaimDetails[],
): JSX.Element[] => {
  return detailArr
    .sort((a, b) => {
      return b.claim.createdAt - a.claim.createdAt
    })
    .map((detail: ClaimDetails) => {
      return (
        <ClaimCard listedClaim={detail.claim} contract={detail?.contract} key={detail.claim.id} />
      )
    })
}

const AllClaimsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  [bp.mobile]: {
    marginBottom: 36,
  },
  [bp.tablet]: {
    marginBottom: 64,
  },
  [bp.desktop]: {
    marginBottom: 48,
  },
})

const StatusWrapper = styled.div({
  display: 'flex',
  [bp.mobile]: {
    flexDirection: 'column',
    padding: '20px 20px 0',
  },
  [bp.tablet]: {
    flexDirection: 'column',
    padding: '20px 80px 0',
  },
  [bp.desktop]: {
    flexDirection: 'column',
    padding: '0px 255px 0px 255px',
  },
})

const StatusTitle = styled.div({
  whiteSpace: 'nowrap',
  color: COLOR.BLACK[0],
  fontWeight: 700,
  [bp.mobile]: {
    paddingBottom: 16,
    fontSize: 16,
    lineHeight: '24px',
  },
  [bp.tablet]: {
    paddingBottom: 24,
    fontSize: 20,
    lineHeight: '28px',
  },
  [bp.desktop]: {
    paddingRight: 55,
    fontSize: 24,
    lineHeight: '32px',
    marginTop: 5,
  },
})

const ClaimsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [bp.mobile]: {
    gap: 16,
  },
  [bp.desktop]: {
    gap: 24,
  },
})

export { ViewAllClaims }
