{
  "AUTHENTICATION_FAILED": [
    {
      "type": 0,
      "value": "Authentication Failed"
    }
  ],
  "AUTHENTICATION_FAILED_CONTENT": [
    {
      "type": 0,
      "value": "We're sorry, but our server is currently experiencing technical difficulties. Please try again later or contact our support team for further assistance."
    }
  ],
  "CONTRACT_TRANSFER_PRODUCT_DETAILS_TERM_LIFETIME": [
    {
      "type": 0,
      "value": "Lifetime"
    }
  ],
  "DATE_RANGE": [
    {
      "type": 1,
      "value": "startDateAsString"
    },
    {
      "type": 0,
      "value": " - "
    },
    {
      "type": 1,
      "value": "endDateAsString"
    }
  ],
  "DATE_RANGE_LIFETIME": [
    {
      "type": 1,
      "value": "startDateAsString"
    },
    {
      "type": 0,
      "value": " - Lifetime"
    }
  ],
  "EXCESSIVE_REQUESTS": [
    {
      "type": 0,
      "value": "Excessive Requests"
    }
  ],
  "EXCESSIVE_REQUESTS_CONTENT": [
    {
      "type": 0,
      "value": "You have requested too many passcodes. For security reasons, please try again later."
    }
  ],
  "FILE_CLAIM_LANGUAGE_OPTION_MODAL_CONTENT_ENGLISH": [
    {
      "type": 0,
      "value": "It looks like you're filing a claim from Canada. If you prefer to file your claim in French, "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "please call us at 877-248-7707,"
        }
      ],
      "type": 8,
      "value": "strong"
    },
    {
      "type": 0,
      "value": " or continue in English."
    }
  ],
  "FILE_CLAIM_LANGUAGE_OPTION_MODAL_CONTENT_TRANSLATED": [
    {
      "type": 0,
      "value": "Il semble que vous déposiez une réclamation depuis le Canada. Si vous préférez déposer votre réclamation en français, "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "veuillez nous appeler au 877-248-7707,"
        }
      ],
      "type": 8,
      "value": "strong"
    },
    {
      "type": 0,
      "value": " ou continuer en anglais."
    }
  ],
  "FILE_CLAIM_LANGUAGE_OPTION_MODAL_HEADING_ENGLISH": [
    {
      "type": 0,
      "value": "Prefer to file your claim in French?"
    }
  ],
  "FILE_CLAIM_LANGUAGE_OPTION_MODAL_PRIMARY_BUTTON_SUBTEXT_TRANSLATED": [
    {
      "type": 0,
      "value": "Continuer en anglais"
    }
  ],
  "FILE_CLAIM_LANGUAGE_OPTION_MODAL_SECONDARY_BUTTON_SUBTEXT_TRANSLATED": [
    {
      "type": 0,
      "value": "Fermer"
    }
  ],
  "FILE_CLAIM_LANGUAGE_OPTION_MODAL_SUB_HEADING_TRANSLATED": [
    {
      "type": 0,
      "value": "Préférez-vous déposer votre réclamation en français ?"
    }
  ],
  "MYEXTEND_AUTHENTICATION_EMAIL_LABEL": [
    {
      "type": 0,
      "value": "Email address"
    }
  ],
  "MYEXTEND_AUTHENTICATION_INTRO_MESSAGE": [
    {
      "type": 0,
      "value": "Enter your contact information associated with Extend. We'll send you a one-time passcode."
    }
  ],
  "MYEXTEND_AUTHENTICATION_PHONE_LABEL": [
    {
      "type": 0,
      "value": "Mobile phone number"
    }
  ],
  "MYEXTEND_AUTHENTICATION_TITLE": [
    {
      "type": 0,
      "value": "Let's get you logged in"
    }
  ],
  "PASSCODE_AUTHENTICATION_INPUT_MESSAGE": [
    {
      "type": 0,
      "value": "Enter the "
    },
    {
      "type": 1,
      "value": "passcodeLength"
    },
    {
      "type": 0,
      "value": "-digit passcode we sent to "
    },
    {
      "type": 1,
      "value": "break"
    },
    {
      "type": 1,
      "value": "userInfo"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "changeInput"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "break"
    },
    {
      "type": 0,
      "value": "If you don't see the passcode, check spam."
    }
  ],
  "PASSCODE_AUTHENTICATION_INPUT_TITLE": [
    {
      "type": 0,
      "value": "Enter the passcode"
    }
  ],
  "PASSCODE_INPUT_LABEL": [
    {
      "type": 1,
      "value": "passcodeLength"
    },
    {
      "type": 0,
      "value": "-digit passcode"
    }
  ],
  "PLAN_DETAILS_PLAN_CALCULATOR_DESKTOP": [
    {
      "type": 0,
      "value": "With a "
    },
    {
      "children": [
        {
          "type": 1,
          "value": "minusIcon"
        }
      ],
      "type": 8,
      "value": "MinusIconWrapper"
    },
    {
      "children": [
        {
          "offset": 0,
          "options": {
            "one": {
              "value": [
                {
                  "type": 1,
                  "value": "termLengthYears"
                },
                {
                  "type": 0,
                  "value": " year"
                }
              ]
            },
            "other": {
              "value": [
                {
                  "type": 1,
                  "value": "termLengthYears"
                },
                {
                  "type": 0,
                  "value": " year"
                }
              ]
            }
          },
          "pluralType": "cardinal",
          "type": 6,
          "value": "termLengthYears"
        },
        {
          "type": 0,
          "value": " "
        },
        {
          "options": {
            "other": {
              "value": [
                {
                  "type": 0,
                  "value": "plan"
                }
              ]
            },
            "plan": {
              "value": [
                {
                  "type": 0,
                  "value": "plan"
                }
              ]
            },
            "policy": {
              "value": [
                {
                  "type": 0,
                  "value": "policy"
                }
              ]
            }
          },
          "type": 5,
          "value": "policyType"
        }
      ],
      "type": 8,
      "value": "PlanLength"
    },
    {
      "children": [
        {
          "type": 1,
          "value": "plusIcon"
        }
      ],
      "type": 8,
      "value": "PlusIconWrapper"
    },
    {
      "type": 0,
      "value": " your purchase is protected until "
    },
    {
      "children": [
        {
          "type": 1,
          "value": "planTermsEndDate"
        }
      ],
      "type": 8,
      "value": "strong"
    }
  ],
  "PLAN_DETAILS_PLAN_CALCULATOR_MOBILE": [
    {
      "children": [
        {
          "type": 1,
          "value": "minusIcon"
        }
      ],
      "type": 8,
      "value": "MinusIconWrapper"
    },
    {
      "children": [
        {
          "offset": 0,
          "options": {
            "one": {
              "value": [
                {
                  "type": 1,
                  "value": "termLengthYears"
                },
                {
                  "type": 0,
                  "value": " year"
                }
              ]
            },
            "other": {
              "value": [
                {
                  "type": 1,
                  "value": "termLengthYears"
                },
                {
                  "type": 0,
                  "value": " year"
                }
              ]
            }
          },
          "pluralType": "cardinal",
          "type": 6,
          "value": "termLengthYears"
        },
        {
          "type": 0,
          "value": " "
        },
        {
          "options": {
            "other": {
              "value": [
                {
                  "type": 0,
                  "value": "plan"
                }
              ]
            },
            "plan": {
              "value": [
                {
                  "type": 0,
                  "value": "plan"
                }
              ]
            },
            "policy": {
              "value": [
                {
                  "type": 0,
                  "value": "policy"
                }
              ]
            }
          },
          "type": 5,
          "value": "policyType"
        }
      ],
      "type": 8,
      "value": "PlanLength"
    },
    {
      "children": [
        {
          "type": 1,
          "value": "plusIcon"
        }
      ],
      "type": 8,
      "value": "PlusIconWrapper"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "protects your purchase until: "
        },
        {
          "children": [
            {
              "type": 1,
              "value": "planTermsEndDate"
            }
          ],
          "type": 8,
          "value": "strong"
        }
      ],
      "type": 8,
      "value": "MobileAlign"
    }
  ],
  "PLAN_DETAILS_TESTIMONIAL_TITLE": [
    {
      "type": 0,
      "value": "We "
    },
    {
      "type": 1,
      "value": "heart"
    },
    {
      "type": 0,
      "value": " making customers happy"
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_CLINTON'S_COMMENT": [
    {
      "type": 0,
      "value": "A perfect and simple process. Buy these protection plans and you wont be sorry AAA+++++++++++++"
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_EDWIN'S_COMMENT": [
    {
      "type": 0,
      "value": "Awesome service. Product was out of stock so they let me choose a similar product!"
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_JOHN'S_COMMENT": [
    {
      "type": 0,
      "value": "It was a no hassle process. Submitted my claim and received a new motor in a reasonable time. Absolutely love it. Thanks!"
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_JORDAN'S_COMMENT": [
    {
      "type": 0,
      "value": "So fast and easy. I purchase Extend plans on all electronics that are prone to power surges that always happen where I live."
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_LIWEI'S_COMMENT": [
    {
      "type": 0,
      "value": "The entire process was very easy, and the customer service was great. Communication was fast and prompt!"
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_MATTHEW'S_COMMENT": [
    {
      "type": 0,
      "value": "Great customer service. Fast turn around time."
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_CLINTON": [
    {
      "type": 0,
      "value": "Clinton H."
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_EDWIN": [
    {
      "type": 0,
      "value": "Edwin B."
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_JOHN": [
    {
      "type": 0,
      "value": "John N."
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_JORDAN": [
    {
      "type": 0,
      "value": "Jordan G."
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_LIWEI": [
    {
      "type": 0,
      "value": "LiWei J."
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_MATTHEW": [
    {
      "type": 0,
      "value": "Matthew W."
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_PERRY": [
    {
      "type": 0,
      "value": "Perry W."
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_TERRY": [
    {
      "type": 0,
      "value": "Terry G."
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_NAME_WAYNE": [
    {
      "type": 0,
      "value": "Wayne O."
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_PERRY'S_COMMENT": [
    {
      "type": 0,
      "value": "Everyone has been so nice helping me get my product replaced with a new one. I will be applying for this protection plan again."
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_TERRY'S_COMMENT": [
    {
      "type": 0,
      "value": "Very easy and helpful to request a claim."
    }
  ],
  "SP_PLAN_DETAILS_DEFAULT_TESTIMONIAL_CUSTOMER_WAYNE'S_COMMENT": [
    {
      "type": 0,
      "value": "Extend has truly helped me out. They replaced my broken parts quickly and are a great company to work with."
    }
  ],
  "SP_PLAN_DETAILS_LIB_FAQ_CANCEL_SHIPPING_PROTECTION": [
    {
      "type": 0,
      "value": "If your order hasn’t shipped yet, please contact the merchant to adjust the order. If the order has already shipped, shipping protection coverage is in effect, and Extend can’t provide a refund."
    },
    {
      "type": 1,
      "value": "break"
    },
    {
      "type": 0,
      "value": "If shipping protection was automatically included in your order by the merchant, you may not cancel your plan."
    },
    {
      "type": 1,
      "value": "break"
    },
    {
      "type": 0,
      "value": "Still have questions? "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "Read our Shopper's Guide to Shipping Protection by Extend."
        }
      ],
      "type": 8,
      "value": "strong"
    },
    {
      "type": 1,
      "value": "break"
    }
  ],
  "SP_PLAN_DETAILS_LIB_FAQ_FILE_A_CLAIM": [
    {
      "type": 0,
      "value": "Filing a claim with Extend is simple. Contact "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "Extend"
        }
      ],
      "type": 8,
      "value": "contactExtend"
    },
    {
      "type": 0,
      "value": " online or by phone at (877) 248-7707. We’ll process your claim in minutes and once approved, send you details on your replacement."
    },
    {
      "type": 1,
      "value": "break"
    },
    {
      "type": 1,
      "value": "button"
    }
  ],
  "SP_PLAN_DETAILS_LIB_FAQ_MULTIPLE_PACKAGES_ANSWER": [
    {
      "type": 0,
      "value": "Yep! Your items are covered no matter how many different boxes they ship in."
    }
  ],
  "SP_PLAN_DETAILS_LIB_FAQ_SP_ANSWER": [
    {
      "type": 0,
      "value": "Coverage starts once the package is shipped. Coverage ends when the package is in your hands, or if you file a claim, once we resolve the issue."
    }
  ],
  "SP_PLAN_DETAILS_LIB_FAQ_SP_ELIGIBILITY_ANSWER": [
    {
      "type": 0,
      "value": "Shipping Protection by Extend only displays on eligible items. If you see it, your item is eligible."
    }
  ],
  "SP_PLAN_DETAILS_LIB_FAQ_SP_FILE_A_CLAIM_ANSWER": [
    {
      "type": 0,
      "value": "If your package is damaged, you can file a claim as soon as it is delivered."
    },
    {
      "type": 1,
      "value": "break"
    },
    {
      "type": 0,
      "value": "If your package is lost or stolen, you can file a claim 5 days after your estimated delivery date. You can typically find this date on the shipping carrier's tracking site."
    },
    {
      "type": 1,
      "value": "break"
    },
    {
      "type": 0,
      "value": " If the carrier's tracking page does not show an estimated delivery date or shows “Pending”, please contact Extend's support team, and they will help you complete your claim. Contact "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "Extend"
        }
      ],
      "type": 8,
      "value": "a"
    },
    {
      "type": 0,
      "value": " online or by phone at (877) 248-7707."
    },
    {
      "type": 1,
      "value": "break"
    },
    {
      "type": 1,
      "value": "button"
    },
    {
      "type": 1,
      "value": "break"
    },
    {
      "type": 0,
      "value": " We ask you to wait 5 days because packages are sometimes delayed in transit, prematurely marked as delivered or misdelivered but eventually rerouted to the correct destination."
    },
    {
      "type": 1,
      "value": "break"
    },
    {
      "type": 0,
      "value": "For example, if your shipping carrier's tracking page shows:"
    },
    {
      "type": 1,
      "value": "break"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
      ],
      "type": 8,
      "value": "list"
    },
    {
      "type": 0,
      "value": "For international packages that may be lost or stolen, please wait 20 days after your estimated delivery date before you file a claim."
    },
    {
      "type": 1,
      "value": "break"
    },
    {
      "type": 0,
      "value": "You have up to 60 days after your estimated delivery date to file all claims."
    }
  ],
  "SP_PLAN_DETAILS_LIB_FAQ_SP_FILE_A_CLAIM_ANSWER_DELIVERED": [
    {
      "type": 0,
      "value": "“Delivered” and the package hasn't shown up for 5 days since, submit a claim."
    }
  ],
  "SP_PLAN_DETAILS_LIB_FAQ_SP_FILE_A_CLAIM_ANSWER_IN_TRANSIT": [
    {
      "type": 0,
      "value": "“In Transit” and the date of that last status was 5 days ago, you should submit a claim."
    }
  ],
  "SP_PLAN_DETAILS_LIB_FAQ_SP_LIMITATIONS_ANSWER": [
    {
      "type": 0,
      "value": "Extend's shipping protection policies can cover up to $15,000 in value per order."
    }
  ],
  "SP_PLAN_DETAILS_LIB_FAQ_WHAT_HAPPENS_AFTER_FILING_CLAIM": [
    {
      "type": 0,
      "value": "If your claim is approved, Extend will replace your product. Most of the time, we will send you a store credit or gift card for the value of the product plus shipping costs and taxes so you can order a replacement product quickly. In some cases, we'll ship the replacement product directly to you."
    }
  ],
  "SUPPORT_FORM_EMAIL_LABEL": [
    {
      "type": 0,
      "value": "Email"
    }
  ],
  "SUPPORT_FORM_EMAIL_VALIDATION": [
    {
      "type": 0,
      "value": "Please enter a valid email address"
    }
  ],
  "SUPPORT_FORM_INTRO_MESSAGE": [
    {
      "type": 0,
      "value": "Questions, feedback, or special requests -- we are here to help! If you'd like to speak with someone you can do so by calling (877) 248-7707."
    }
  ],
  "SUPPORT_FORM_INTRO_TITLE": [
    {
      "type": 0,
      "value": "Hey there!"
    }
  ],
  "SUPPORT_FORM_MESSAGE_LABEL": [
    {
      "type": 0,
      "value": "How can we help you?"
    }
  ],
  "SUPPORT_FORM_MESSAGE_MAXIMUM_LENGTH": [
    {
      "type": 0,
      "value": "Please use only up to "
    },
    {
      "type": 1,
      "value": "MAX_MESSAGE_LENGTH"
    },
    {
      "type": 0,
      "value": " characters"
    }
  ],
  "SUPPORT_FORM_MESSAGE_VALIDATION": [
    {
      "type": 0,
      "value": "Send us a message"
    }
  ],
  "SUPPORT_FORM_NAME_LABEL": [
    {
      "type": 0,
      "value": "Name"
    }
  ],
  "SUPPORT_FORM_NAME_MAXIMUM_LENGTH": [
    {
      "type": 0,
      "value": "Please use only up to "
    },
    {
      "type": 1,
      "value": "MAX_NAME_LENGTH"
    },
    {
      "type": 0,
      "value": " characters"
    }
  ],
  "SUPPORT_FORM_NAME_VALIDATION": [
    {
      "type": 0,
      "value": "Please enter your name"
    }
  ],
  "SUPPORT_FORM_PHONE_NUMBER_LABEL": [
    {
      "type": 0,
      "value": "Phone Number"
    }
  ],
  "SUPPORT_FORM_PHONE_NUMBER_VALIDATION": [
    {
      "type": 0,
      "value": "Please enter a valid phone number"
    }
  ],
  "SUPPORT_FORM_SUBMIT_BUTTON": [
    {
      "type": 0,
      "value": "Send Message"
    }
  ],
  "SUPPORT_FORM_SUCCESS_MESSAGE": [
    {
      "type": 0,
      "value": "We will be in touch soon."
    }
  ],
  "SUPPORT_FORM_SUCCESS_TITLE": [
    {
      "type": 0,
      "value": "Thanks for reaching out!"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_LABEL": [
    {
      "type": 0,
      "value": "Support Topic"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_OTHER": [
    {
      "type": 0,
      "value": "Other"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_PRODUCT_PROTECTION": [
    {
      "type": 0,
      "value": "Product Protection"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_SELECT_OPTION": [
    {
      "type": 0,
      "value": "Select an option"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_SHIPPING_PROTECTION": [
    {
      "type": 0,
      "value": "Shipping Protection"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_VALIDATION": [
    {
      "type": 0,
      "value": "Please make a selection"
    }
  ],
  "SUPPORT_FORM_TITLE": [
    {
      "type": 0,
      "value": "Contact Us"
    }
  ],
  "WARRANTY_TERMS_CONTRACT_DETAILS_TITLE": [
    {
      "type": 0,
      "value": "Order Details"
    }
  ],
  "WARRANTY_TERMS_NO": [
    {
      "type": 0,
      "value": "No"
    }
  ],
  "termLengthYears": [
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 1,
              "value": "termLengthYears"
            },
            {
              "type": 0,
              "value": " year"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 1,
              "value": "termLengthYears"
            },
            {
              "type": 0,
              "value": " years"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "termLengthYears"
    }
  ]
}