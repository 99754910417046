import type { FC } from 'react'
import type { CSSObject } from '@emotion/react'
import { keyframes } from '@emotion/react'
import { COLOR } from '@extend/zen'
import styled from '@emotion/styled'

export type SpinnerSize = 'sm' | 'md' | 'lg'
export interface SpinnerProps {
  color?: string
  size?: SpinnerSize
  className?: string
}

const Spinner: FC<SpinnerProps> = ({ color = COLOR.BLACK, size = 'sm', className }) => (
  <SpinnerBox sizeStyles={sizeStyles[size]} color={color} className={className} />
)

// Styles
// ==================================================================================
const spinnerBorder = keyframes({ to: { transform: 'rotate(360deg)' } })
const sizeStyles: Record<SpinnerSize, CSSObject> = {
  sm: {
    height: 26,
    width: 26,
    borderWidth: 2,
  },
  md: {
    height: 52,
    width: 52,
    borderWidth: 4,
  },
  lg: {
    height: 104,
    width: 104,
    borderWidth: 8,
  },
}

const SpinnerBox = styled.div<{ sizeStyles?: Partial<CSSObject>; color: string }>(
  ({ sizeStyles, color }) => ({
    display: 'inline-block',
    verticalAlign: 'text-bottom',
    borderStyle: 'solid',
    borderRightColor: 'transparent',
    borderRadius: '50%',
    animation: `${spinnerBorder} .75s linear infinite`,
    color,
    ...sizeStyles,
  }),
)

export { Spinner }
