import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { COLOR, bp } from '@helloextend/customers-ui'
import {
  usePrecheckQuery,
  useGetMerchantServicingSettingsQuery,
  useLazyGetStoreConsumerQuery,
} from '@helloextend/extend-api-rtk-query'
import { Button, Badge, Popover, MenuButtonItem, usePopover, More } from '@extend/zen'
import { date } from '@extend/client-helpers'
import type {
  ExtendedWarrantyContract,
  ProductProtectionBundleContract,
  ExtendedWarrantyProduct,
  CustomBundleProduct,
} from '../../../types/contract'
import {
  getCustomBundleProduct,
  getDisplayDetails,
  getIsClaimInProgress,
  isBundleContract,
  isExtendedWarrantyContract,
  missingProfileFieldTooltip,
} from '../../../lib/helper-functions'
import { images } from '../../../lib/assets'
import { logEvent } from '../../../analytics'

export interface ProductProtectionCardProps {
  contract: ExtendedWarrantyContract | ProductProtectionBundleContract
  onFileClaimClick: (handlerArgs: {
    contractId: string
    isMerchantOwnedIntake?: boolean
  }) => void
  isMissingRequiredProfileField?: boolean
}

const ProductProtectionCard: FC<ProductProtectionCardProps> = ({
  contract,
  onFileClaimClick,
  isMissingRequiredProfileField = false,
}) => {
  const history = useHistory()
  const { locale } = useIntl()
  const [fetchStore] = useLazyGetStoreConsumerQuery()
  const { triggerRef, popoverRef, isPresent, toggle, triggerBoundingBox } =
    usePopover<HTMLButtonElement>()
  const { data: precheck, isLoading } = usePrecheckQuery({ contractId: contract.id })
  const { data: merchantServicingSettings } = useGetMerchantServicingSettingsQuery({
    sellerId: contract.sellerId,
  })

  const { purchaseDate, sellerName, id } = contract

  const isContractExtendedWarranty = isExtendedWarrantyContract(contract)
  const contractProduct: ExtendedWarrantyProduct | CustomBundleProduct = isContractExtendedWarranty
    ? ((contract as ExtendedWarrantyContract).product as ExtendedWarrantyProduct)
    : (getCustomBundleProduct(contract as ProductProtectionBundleContract) as CustomBundleProduct)
  const productName =
    (contractProduct as CustomBundleProduct).title ||
    (contractProduct as ExtendedWarrantyProduct).name

  const { badgeDetails, badgeColor, isClaimFilingDisabled, fileClaimTooltip, subBadgeText } =
    getDisplayDetails({
      precheck,
      contract,
    })

  const shouldDisplayContactSeller =
    precheck?.status === 'failure' && precheck?.validationError === 'mfr_warranty_active'

  const handleContactSeller = async (): Promise<void> => {
    logEvent('My Plans - Contract Card - Clicks', 'Contact Seller')
    let storeDomain
    if (!contract?.resellerMerchant?.domain) {
      const store = await fetchStore({
        storeId: contract.sellerId,
        version: 'latest',
      }).unwrap()
      storeDomain = store.domain
    }

    window.open(contract?.resellerMerchant?.domain ?? `https://${storeDomain}`, '_blank')
  }

  const handleContactSupport = (): void => {
    logEvent('My Plans - Contract Card - Clicks', 'Contact Support')
    history.push(`/${locale}/contact`)
  }

  const handleReviewTerms = (e: SyntheticEvent): void => {
    logEvent('My Plans - Contract Card - Clicks', 'Review Terms')
    if (!isContractExtendedWarranty && !isBundleContract) {
      e.stopPropagation()
    } else {
      history.push(`/my_plans/${id}`)
    }
  }

  const handleTransferPlan = (): void => {
    logEvent('My Plans - Contract Card - Clicks', 'Transfer Plan')
    history.push(`/my_plans/${id}/transfer`)
  }

  return (
    <CardWrapper
      data-cy="product-protection-card-wrapper"
      onClick={(e) =>
        !isContractExtendedWarranty ? e.stopPropagation() : history.push(`/my_plans/${id}`)
      }
      isCursorPointer={isContractExtendedWarranty}
      data-contract-id={contract.id}
    >
      <ImageWrapper isCursorPointer={isContractExtendedWarranty}>
        {!isLoading && (
          <BadgeWrapper>
            <Badge
              text={badgeDetails}
              color={badgeColor}
              data-cy="product-protection-coverage-badge"
            />
            {getIsClaimInProgress(precheck) && (
              <StyledBadgeWrapper>
                <Badge
                  emphasis="low"
                  text={subBadgeText || 'Claim in Progress'}
                  data-cy="product-protection-claim-progress-badge"
                  color="green"
                />
              </StyledBadgeWrapper>
            )}
          </BadgeWrapper>
        )}
        <ContractImage
          image={contractProduct.imageUrl}
          title={productName}
          data-cy="product-protection-image"
        />
      </ImageWrapper>
      <CardBottom>
        <ContractDetails>
          <ContractType data-cy="product-protection-contract-type">PRODUCT PROTECTION</ContractType>
          <ProductName data-cy="product-protection-product-name">{productName}</ProductName>
          <PurchaseInformation data-cy="product-protection-purchase-info">
            Purchased {date.format(purchaseDate / 1000)} · {sellerName}
          </PurchaseInformation>
        </ContractDetails>
        <ButtonGroup onClick={(e) => e.stopPropagation()}>
          <StyledButton
            data-cy="product-protection-file-claim-button"
            text="File a Claim"
            emphasis="medium"
            isDisabled={!precheck || isClaimFilingDisabled || isMissingRequiredProfileField}
            tooltip={isMissingRequiredProfileField ? missingProfileFieldTooltip : fileClaimTooltip}
            onClick={() =>
              onFileClaimClick({
                contractId: id,
                isMerchantOwnedIntake: merchantServicingSettings?.isMerchantOwnedIntake,
              })
            }
            fillContainer={!shouldDisplayContactSeller}
          />
          <StyledButton
            data-cy="product-protection-more-button"
            emphasis="medium"
            icon={More}
            onClick={toggle}
            ref={triggerRef}
            isToggled={isPresent}
          />
          <Popover ref={popoverRef} isPresent={isPresent} triggerBoundingBox={triggerBoundingBox}>
            <MenuLinkWrapper>
              <MenuButtonItem
                onClick={handleReviewTerms}
                data-cy="product-protection-review-terms-button"
              >
                Review Terms & Conditions
              </MenuButtonItem>
              <MenuButtonItem
                onClick={handleContactSupport}
                data-cy="product-protection-contact-support-button"
              >
                Contact Support
              </MenuButtonItem>
              {shouldDisplayContactSeller && (
                <MenuButtonItem
                  onClick={handleContactSeller}
                  data-cy="product-protection-contact-seller-button"
                >
                  Contact Seller
                </MenuButtonItem>
              )}
              <MenuButtonItem
                onClick={handleTransferPlan}
                data-cy="product-protection-transfer-plan-button"
              >
                Transfer Plan
              </MenuButtonItem>
            </MenuLinkWrapper>
          </Popover>
        </ButtonGroup>
      </CardBottom>
    </CardWrapper>
  )
}

const CardWrapper = styled.div<{ isCursorPointer: boolean }>(({ isCursorPointer }) => ({
  display: 'flex',
  alignContent: 'center',
  borderRadius: 16,
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  textDecoration: 'none',
  color: 'inherit',
  [bp.mobile]: {
    flexDirection: 'column',
    width: '355px',
    height: '370px',
  },
  [bp.desktop]: {
    flexDirection: 'column',
    width: '360px',
    height: '536px',
  },
  '&:hover': {
    cursor: isCursorPointer ? 'pointer' : 'default',
  },
}))

const ImageWrapper = styled.div<{ isCursorPointer: boolean }>(({ isCursorPointer }) => ({
  position: 'relative',
  cursor: isCursorPointer ? 'pointer' : 'default',
  borderBottom: `1px solid ${COLOR.NEUTRAL[300]}`,
  [bp.mobile]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
    height: '156px',
  },
  [bp.desktop]: {
    height: '288px',
    padding: 0,
  },
}))

const BadgeWrapper = styled.div({
  position: 'absolute',
  top: 16,
  left: 16,
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px',
})

const ContractImage = styled.div<{ image?: string }>(({ image }) => ({
  verticalAlign: 'middle',
  background: `no-repeat center center`,
  backgroundSize: 'cover',
  [bp.mobile]: {
    maxHeight: 128,
    maxWidth: 128,
    minHeight: 128,
    minWidth: 128,
    borderRadius: 8,
    backgroundImage: `url(${image}), url(${images.defaultProductImage})`,
  },
  [bp.desktop]: {
    maxHeight: 286,
    maxWidth: 358,
    minHeight: 286,
    minWidth: 358,
    borderRadius: '16px 16px 0px 0px',
    backgroundImage: `url(${image}), url(${images.defaultProductImage})`,
  },
}))

const CardBottom = styled.div({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0px 0px 16px 16px',
  justifyContent: 'space-between',
  gap: 4,
  [bp.mobile]: {
    width: 355,
    height: 214,
    padding: 16,
  },
  [bp.desktop]: {
    width: 360,
    height: 248,
    padding: 24,
  },
})

const ContractDetails = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
})

const ContractType = styled.div({
  alignSelf: 'flex-start',
  color: COLOR.NEUTRAL[600],
  fontWeight: 700,
  [bp.mobile]: {
    fontSize: 13,
    lineHeight: '16px',
  },
  [bp.desktop]: {
    fontSize: 14,
    lineHeight: '18px',
  },
})

const ProductName = styled.div({
  alignSelf: 'flex-start',
  fontWeight: 700,
  color: COLOR.NEUTRAL[1000],
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  [bp.mobile]: {
    fontSize: 17,
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: 24,
    lineHeight: '32px',
  },
})

const PurchaseInformation = styled.div({
  alignSelf: 'flex-start',
  color: COLOR.NEUTRAL[1000],
  fontWeight: 400,
  [bp.mobile]: {
    fontSize: 14,
    lineHeight: '20px',
  },
  [bp.desktop]: {
    fontSize: 16,
    lineHeight: '24px',
  },
})

const ButtonGroup = styled.div({
  display: 'flex',
  gap: 12,
  justifyContent: 'space-between',
  whiteSpace: 'nowrap',
})

const StyledButton = styled(Button)<{ isDisabled?: boolean }>(({ isDisabled }) => ({
  ...(!isDisabled && {
    '&:hover': {
      cursor: 'pointer !important',
    },
  }),
  ...(isDisabled && {
    '&:hover': {
      cursor: 'default !important',
    },
  }),
}))

const MenuLinkWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  padding: '8px',
})

const StyledBadgeWrapper = styled.div({
  // 75% transparent white
  background: 'rgba(255, 255, 255, 0.75)',
  borderRadius: '4px',
  padding: '0px 8px',
})

export { ProductProtectionCard }
