export enum LDFlag {
  PlanDetailsCCSFetch = 'plan-details-ccs-fetching',
  CustomersDisablePendo = 'customers-disable-pendo',
  ShippingProtectionPlanDetailsCCSFetch = 'shipping-offers-features',
  BundleContractClaims = 'bundle-contract-claims',
  ProfileVerification = 'profile-verification',
  ProfileEditing = 'profile-editing',
  IsUnauthenticatedWismoEnabled = 'is-unauthenticated-wismo-enabled',
  IsWhiteLabelEnabled = 'is-white-label-enabled',
  // TODO: [MINT-2063] Remove FF
  AutomatedReplacementFulfillment = 'automated-replacement-fulfillment',
}

type LDFlagType = boolean | string | number | JSON

export const defaultLDFlagValues: Record<LDFlag, LDFlagType> = {
  [LDFlag.PlanDetailsCCSFetch]: false,
  [LDFlag.CustomersDisablePendo]: false,
  [LDFlag.ShippingProtectionPlanDetailsCCSFetch]: JSON.stringify({
    ccsMarketingEnabled: false,
    ccsPlanDetailsEnabled: false,
    supportedCurrencies: ['USD'],
  }),
  [LDFlag.BundleContractClaims]: false,
  [LDFlag.ProfileVerification]: false,
  [LDFlag.ProfileEditing]: false,
  [LDFlag.IsUnauthenticatedWismoEnabled]: false,
  [LDFlag.IsWhiteLabelEnabled]: false,
  // TODO: [MINT-2063] Remove FF
  [LDFlag.AutomatedReplacementFulfillment]: false,
}
