import { createBooleanReducer } from '../reducers/factories'
import type { Action } from '../types/action-type'

export type ApiErrorState = boolean

const apiError = createBooleanReducer<Action>(
  [
    'CHAT_SESSION_CONNECT_FAILURE',
    'CHAT_SESSION_UPDATE_FAILURE',
    'CHAT_SESSION_EDIT_FAILURE',
    'CONTACT_FORM_SUBMIT_FAILURE',
  ],
  ['CUSTOMERS_API_ERROR_RESET'],
)

export default apiError
