import type {
  AuthFinishContact,
  AuthStartRequest,
} from '@helloextend/extend-api-rtk-query/src/my-extend/types'
import { formatPhoneNumberIso } from '@extend/client-helpers'
import type { Locale } from '../../types/localization'
import type { UserInfo } from './myextend-authentication'

export function buildPayload(
  userInfo: UserInfo,
  locale: Locale,
): AuthStartRequest | AuthFinishContact {
  const { email, phoneNumber } = userInfo

  if (email) return { email }
  if (phoneNumber) {
    const [, country] = locale.split('-')
    return { phoneNumber: formatPhoneNumberIso(phoneNumber, country) }
  }
  throw new Error('unexpected user info missing to build payload')
}
