import type { FC } from 'react'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { bp } from '@helloextend/customers-ui'
import {
  Badge,
  Button,
  Popover,
  usePopover,
  COLOR,
  MenuButtonItem,
  More,
  ArrowDropDownClose,
  ArrowDropDown,
} from '@extend/zen'
import { isMobile } from 'react-device-detect'
import {
  useGetMerchantServicingSettingsQuery,
  usePrecheckQuery,
} from '@helloextend/extend-api-rtk-query'
import { images } from '../../../lib/assets'
import {
  formatDate,
  getIsClaimInProgress,
  getDisplayDetails,
  getValidationErrorMessages,
  missingProfileFieldTooltip,
} from '../../../lib/helper-functions'
import { logEvent } from '../../../analytics'
import type { CategoryContract, CategoryProduct } from '../../../types/contract'
import { getCanTransfer } from '../../contract-transfer/contract-transfer.utils'
import { isPrecheckWithLineItems } from '../../../lib/type-guards'

export interface CategoryCardProps {
  contract: CategoryContract
  onFileClaimClick: (handlerArgs: {
    contractId: string
  lineItemIds?: string[]
    isMerchantOwnedIntake?: boolean
  }) => void
  isMissingRequiredProfileField?: boolean
}

const CategoryCard: FC<CategoryCardProps> = ({
  contract,
  onFileClaimClick,
  isMissingRequiredProfileField = false,
}) => {
  const history = useHistory()
  const { locale } = useIntl()
  const [isViewAllExpanded, setIsViewAllExpanded] = useState(false)
  const { data: precheck, isLoading } = usePrecheckQuery({ contractId: contract.id })
  const {
    toggle: transferToggle,
    triggerRef: transferTriggerRef,
    popoverRef: transferPopoverRef,
    isPresent: transferIsPresent,
    triggerBoundingBox: transferTriggerBoundingBox,
  } = usePopover<HTMLButtonElement>()

  const { sellerName, purchaseDate, productsList, id } = contract

  const hasManyProducts = (productsList?.length ?? 0) > 3
  const productsToShow = 3
  const products =
    hasManyProducts && !isViewAllExpanded ? productsList?.slice(0, productsToShow) : productsList
  const { data: merchantServicingSettings } = useGetMerchantServicingSettingsQuery({
    sellerId: contract.sellerId,
  })
  const handleContactSupport = (): void => {
    logEvent('My Plans - Contract Card - Clicks', 'Contact Support')
    history.push(`/${locale}/contact`)
  }

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>): void => {
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.src = images.fallbackImage
  }

  const handleTransferPlan = (): void => {
    logEvent('My Plans - Contract Card - Clicks', 'Transfer Plan')
    history.push(`/my_plans/${id}/transfer`)
  }

  const { badgeDetails, badgeColor, isClaimFilingDisabled, fileClaimTooltip } = getDisplayDetails({
    precheck,
    contract,
  })

  const hasActiveClaim =
    isPrecheckWithLineItems(precheck) &&
    Object.values(precheck.lineItems).some((lineItem) => lineItem.hasActiveClaim)

  const hasClaimPendingPhotoUploads =
    hasActiveClaim &&
    Object.values(precheck.lineItems).some((lineItem) => lineItem.hasClaimPendingPhotoUploads)

  const claimInProgressBadge = hasClaimPendingPhotoUploads
    ? getValidationErrorMessages(contract).pending_photo_uploads.default.subBadgeText
    : getValidationErrorMessages(contract).active_claim_found.default.subBadgeText

  const LineItemButtons = ({ lineItemId }: { lineItemId: string }): JSX.Element => {
    const { triggerRef, popoverRef, isPresent, toggle, triggerBoundingBox } =
      usePopover<HTMLButtonElement>()

    const lineItems = isPrecheckWithLineItems(precheck) ? precheck.lineItems : {}
    const shouldDisableClaimFiling =
      !precheck ||
      isMissingRequiredProfileField ||
      isClaimFilingDisabled ||
      (lineItems && lineItems[lineItemId]?.hasActiveClaim)

    let toolTipOverride = fileClaimTooltip
    if (
      shouldDisableClaimFiling &&
      lineItems &&
      lineItems[lineItemId]?.hasClaimPendingPhotoUploads
    ) {
      toolTipOverride =
        getValidationErrorMessages(contract).pending_photo_uploads.default.fileClaimTooltip
    } else if (shouldDisableClaimFiling) {
      toolTipOverride =
        getValidationErrorMessages(contract).active_claim_found.default.fileClaimTooltip
    }

    return (
      <div data-cy="category-line-item">
        <ButtonWrapper>
          <Button
            emphasis="medium"
            text="File a Claim"
            isDisabled={shouldDisableClaimFiling}
            tooltip={isMissingRequiredProfileField ? missingProfileFieldTooltip : toolTipOverride}
            onClick={() => {
              onFileClaimClick({
                contractId: contract.id,
                lineItemIds: [lineItemId],
                isMerchantOwnedIntake: merchantServicingSettings?.isMerchantOwnedIntake,
              })
            }}
            data-cy={`category-file-claim-button-${lineItemId}`}
            fillContainer={isMobile}
          />
          {!isMobile && (
            <Button
              emphasis="medium"
              icon={More}
              onClick={toggle}
              ref={triggerRef}
              isToggled={isPresent}
              data-cy={`category-more-button-${lineItemId}`}
            />
          )}
        </ButtonWrapper>
        <Popover ref={popoverRef} isPresent={isPresent} triggerBoundingBox={triggerBoundingBox}>
          <MenuLinkWrapper>
            <MenuButtonItem
              onClick={handleContactSupport}
              data-cy="category-contact-support-button"
            >
              Contact Support
            </MenuButtonItem>
          </MenuLinkWrapper>
        </Popover>
      </div>
    )
  }

  return (
    <Wrapper data-contract-id={id}>
      <Header>
        <HeaderInfo>
          <ContractTypeAndBadgeWrapper>
            <ContractType data-cy="category-contract-type">PRODUCT PROTECTION</ContractType>
            {!isLoading && (
              <BadgeWrapper>
                <CoverageBadge
                  text={badgeDetails}
                  color={badgeColor}
                  data-cy="category-coverage-badge"
                />
                {getIsClaimInProgress(precheck, productsList) && (
                  <Badge
                    emphasis="low"
                    text={claimInProgressBadge || 'Claim in Progress'}
                    data-cy="category-claim-progress-badge"
                    color="green"
                  />
                )}
              </BadgeWrapper>
            )}
          </ContractTypeAndBadgeWrapper>
          <HeaderTitle data-cy="category-header-title">{`Order from ${sellerName}`}</HeaderTitle>
          <PurchaseInformation data-cy="category-purchase-info">
            Purchased {formatDate(purchaseDate)}
          </PurchaseInformation>
        </HeaderInfo>
        <HeaderButtons>
          <Button
            emphasis="medium"
            icon={More}
            onClick={transferToggle}
            ref={transferTriggerRef}
            isToggled={transferIsPresent}
            data-cy="category-header-more-button"
          />
          <Popover
            ref={transferPopoverRef}
            isPresent={transferIsPresent}
            triggerBoundingBox={transferTriggerBoundingBox}
          >
            <MenuLinkWrapper>
              <MenuButtonItem
                onClick={handleContactSupport}
                data-cy="category-contact-header-support-button"
              >
                Contact Support
              </MenuButtonItem>
              {getCanTransfer({ contract, hasActiveClaim }) && (
                <MenuButtonItem
                  onClick={handleTransferPlan}
                  data-cy="category-transfer-plan-button"
                >
                  Transfer Plan for All Items
                </MenuButtonItem>
              )}
            </MenuLinkWrapper>
          </Popover>
        </HeaderButtons>
      </Header>
      <Body>
        {!!products?.length &&
          products.map((product) => {
            const { lineItemId } = product as CategoryProduct

            return (
              <Product data-cy="category-product-wrapper">
                <ProductInfo>
                  <ProductImage
                    data-cy={`category-product-image-${lineItemId}`}
                    src={product?.imageUrl || images.defaultCarouselImage}
                    onError={(e) => handleImageError(e)}
                  />
                  <ProductTitle
                    data-cy={`category-product-title-${lineItemId}`}
                    onClick={() => history.push(`/my_plans/${contract.id}/product/${lineItemId}`)}
                  >
                    {product?.title}
                  </ProductTitle>
                </ProductInfo>
                <LineItemButtons lineItemId={lineItemId as string} />
              </Product>
            )
          })}
        {hasManyProducts && (
          <Button
            emphasis="low"
            color="neutral"
            text={isViewAllExpanded ? 'View less items' : 'View all items'}
            icon={isViewAllExpanded ? ArrowDropDownClose : ArrowDropDown}
            onClick={() => setIsViewAllExpanded(!isViewAllExpanded)}
            data-cy="category-view-more-button"
          />
        )}
      </Body>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '16px',
  border: '1px solid',
  borderColor: COLOR.NEUTRAL['300'],
  width: 'fill-available',
  [bp.desktop]: {
    minWidth: '764px',
  },
})

const Header = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '16px',
  borderBottom: '1px solid',
  borderColor: COLOR.NEUTRAL['300'],
  [bp.mobile]: {
    flexDirection: 'column',
    padding: '16px',
  },
  [bp.desktop]: {
    flexDirection: 'row',
    padding: '24px',
  },
})

const ContractTypeAndBadgeWrapper = styled.div({
  display: 'flex',
  gap: 8,
  [bp.mobile]: {
    flexDirection: 'column',
  },
  [bp.desktop]: {
    flexDirection: 'row',
  },
})

const BadgeWrapper = styled.div({
  display: 'flex',
  gap: 8,
})

const CoverageBadge = styled(Badge)({
  borderRadius: '4px',
  '&:hover': {
    cursor: 'default',
  },
  [bp.mobile]: {
    width: '62px',
    height: '24px',
    padding: '3px 8px',
    gap: 4,
  },
})

const ContractType = styled.div({
  alignSelf: 'flex-start',
  color: COLOR.NEUTRAL[600],
  fontWeight: 700,
  [bp.mobile]: {
    fontSize: 13,
    lineHeight: '16px',
  },
  [bp.desktop]: {
    fontSize: 14,
    lineHeight: '18px',
  },
})

const Body = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  [bp.mobile]: {
    padding: '16px',
  },
  [bp.desktop]: {
    padding: '24px',
  },
})

const HeaderInfo = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})

const HeaderButtons = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  justifyContent: 'flex-end',
  [bp.mobile]: { alignSelf: 'flex-start' },
  [bp.desktop]: { alignSelf: 'center' },
})

const MenuLinkWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  padding: '8px',
})

const HeaderTitle = styled.div({
  fontWeight: 700,
  [bp.mobile]: {
    fontSize: '17px',
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: '24px',
    lineHeight: '32px',
  },
})

const PurchaseInformation = styled.div({
  fontWeight: 400,
  [bp.mobile]: {
    fontSize: '15px',
    lineHeight: '20px',
  },
  [bp.desktop]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
})

const Product = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
  [bp.mobile]: {
    flexDirection: 'column',
  },
  [bp.desktop]: {
    flexDirection: 'row',
  },
})

const ProductInfo = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
})

const ProductImage = styled.img({
  [bp.mobile]: {
    width: '40px',
    height: '40px',
    borderRadius: '4px',
  },
  [bp.desktop]: {
    width: '56px',
    height: '56px',
    borderRadius: '8px',
  },
})

const ProductTitle = styled.div({
  fontWeight: 400,
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  [bp.mobile]: {
    fontSize: '15px',
    lineHeight: '20px',
  },
  [bp.desktop]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
})

const ButtonWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '16px',
})

export { CategoryCard }
