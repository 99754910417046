import type { FC } from 'react'
import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { RouterError } from '@helloextend/component-commons'
import * as selectors from '../reducers/selectors'
import { errorActions } from '../actions'
import type { RootState } from '../reducers'

interface DP {
  onUnload: typeof errorActions.resourceFetchErrorReset
}

type ResourceErrorBoundaryProps = DP

const Component: FC<ResourceErrorBoundaryProps> = ({ children, onUnload }) => {
  const error = useSelector((state: RootState) => selectors.getResourceError(state))
  useEffect(() => {
    return () => {
      onUnload()
    }
  }, [onUnload])

  return error ? <RouterError /> : <>{children}</>
}

const ResourceErrorBoundary = connect(null, { onUnload: errorActions.resourceFetchErrorReset })(
  Component,
)

export type { ResourceErrorBoundaryProps }
export { Component, ResourceErrorBoundary }
