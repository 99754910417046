import { MerchantConfiguration } from "../types/merchant-configuration"
import { MerchantBrandingResponse } from '@extend-services/service-orders/dist/src/client/api-rest/v2/models'
import { images } from '../lib/assets'

export const extendConfiguation: MerchantConfiguration = {
  sellerId: "extend",
  isExtend: true,
  subdomainName: "customers",
  merchantWebsite: "https://extend.com",
  brandingConfigurations: {
    font: "Arial" as MerchantConfiguration['brandingConfigurations']['font'],
    primaryColor: "#000000",
    assetsUrls: {
      logo: images.extendLogoWhiteBlue,
    },
  },
}

export function buildMerchantConfiguration(branding: MerchantBrandingResponse): MerchantConfiguration {
  return {
    sellerId: branding.sellerId,
    isExtend: false,
    subdomainName: branding.subdomainName,
    merchantWebsite: branding.merchantWebsite,
    brandingConfigurations: {
      font: branding.brandingConfigurations.font,
      primaryColor: branding.brandingConfigurations.primaryColor,
      assetsUrls: {
        logo: `${branding.brandingConfigurations.assetBucketUrl}${branding.sellerId}/logo.png`
      },
    },
  }
}
