import type { FC } from 'react'
import React from 'react'
import { useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useLocation, Redirect, Switch } from 'react-router'
import type { RouteProps } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { RouterError } from '@helloextend/component-commons'
import { ConnectRequest } from '@extend-incredibot/types'
import { customLogger } from '@extend/client-helpers'
import { LDFlag } from '../constants/ld-flags'
import * as pages from './pages'
import type { CustomersRouteProps } from './types'
import { routes as PublicRoutes } from './routes/public-routes'
import { routes as ProtectedRoutes } from './routes/protected-routes'
import { routes as ShippingProtectionRoutes } from './routes/shipping-protection-routes'
import { routes as ContractTransferRoutes } from './routes/contract-transfer-routes'
import { routes as MyExtendAuthenticationRoutes } from './routes/authentication-routes'
import { injectParams } from './utils'
import * as selectors from '../reducers/selectors'
import { setItem, getItem } from '../store/persistence'

const ProtectedRoute: FC<RouteProps> = ({ children, ...rest }) => {
  const isLoggedIn = useSelector(selectors.getIsLoggedIn)
  const location = useLocation()
  if (location && 'pathname' in location) {
    setItem('prevLocation', location.pathname)
  }
  if (!isLoggedIn) {
    return (
      <Route {...rest}>
        <pages.MyExtendAuthenticationPage />
      </Route>
    )
  }
  return <Route {...rest}>{children}</Route>
}

const Component: FC<CustomersRouteProps> = ({ isLoggedIn, locale }) => {
  const { [LDFlag.IsUnauthenticatedWismoEnabled]: FF_IS_UNAUTHENTICATED_WISMO_ENABLED } = useFlags()

  const login = isLoggedIn ? (
    <Redirect to={`/${locale}/my_plans`} />
  ) : (
    <Redirect to={`/${locale}/authentication`} />
  )

  return (
    <Switch>
      <Route exact path={['/claims', '/claim', '/track']}>
        <Redirect to={`/${locale}/my_claims`} />
      </Route>

      {[
        ...PublicRoutes,
        ...ProtectedRoutes,
        ...ShippingProtectionRoutes,
        ...MyExtendAuthenticationRoutes,
        ...ContractTransferRoutes,
      ].map(({ path }) => {
        return (
          <Route
            path={path}
            exact
            key={`${path}-redirect`}
            render={({ match, location }) => (
              <Redirect to={`/${locale}${injectParams(match.params, path)}${location.search}`} />
            )}
          />
        )
      })}

      <Route exact path={`/${locale}/sign_up`}>
        <Redirect to={`/${locale}/authentication`} />
      </Route>

      {/* Landing Page */}
      {
        <Route path={`/${locale}/`} exact>
          {login}
        </Route>
      }

      {
        <Route
          path={['/:locale/kaley', '/kaley']}
          exact
          render={(props) => {
            const location = props.location
            const state = location.state as Partial<ConnectRequest>
            const { locale } = props.match.params as { locale?: string }
            const redirectPath = locale ? `/${locale}/my_plans` : '/my_plans'

            if (!state?.contractId && !state?.orderId) {
              customLogger.warn(
                'ContractId or OrderId missing from state. Redirecting to /my_plans',
                {
                  previousPath: getItem('prevLocation'),
                },
              )
              return <Redirect to={redirectPath} />
            }
            return <pages.ChatPortal {...props} />
          }}
        />
      }

      {/* Public Routes */}
      {PublicRoutes.map(({ path, component }) => {
        if (path === '/' && !isLoggedIn) {
          return (
            <Route path={`/${locale}${path}`} component={component} key={path} exact>
              <Redirect to={`/${locale}/authentication`} />
            </Route>
          )
        }

        if (path === '/tracking' && !FF_IS_UNAUTHENTICATED_WISMO_ENABLED) {
          return null
        }

        return <Route path={`/${locale}${path}`} component={component} key={path} exact />
      })}

      {/* Protected Routes */}
      {ProtectedRoutes.map((route) => {
        return (
          <ProtectedRoute path={`/${locale}${route.path}`} key={route.path} exact>
            <route.component />
          </ProtectedRoute>
        )
      })}

      {/* Shipping Protection Routes */}
      {ShippingProtectionRoutes.map(({ path, component }) => (
          <Route path={`/${locale}${path}`} component={component} key={path} exact />
      ))}

      {/* Authentication Routes */}
      {MyExtendAuthenticationRoutes.map(({ path, component }) => (
        <Route path={`/${locale}${path}`} component={component} key={path} exact />
      ))}

      {/* Contract Transfer Routes */}
      {ContractTransferRoutes.map(({ path, component }) => (
        <Route path={`/${locale}${path}`} component={component} key={path} exact />
      ))}

      {/* Login Routes */}
      <Route path="/login">{login}</Route>

      <Route>
        <RouterError />
      </Route>
    </Switch>
  )
}

export { Component as Routes }
