import type { FC } from 'react'
import React from 'react'
import { useIntl } from 'react-intl'
import type { Contract } from '@helloextend/extend-api-client'
import { getFormattedDate } from '../utils/date-converter'

interface DateRangeProps {
  contract: Contract
  isLifetime: boolean
  locale: string
}

type MonthFormat = 'long' | 'numeric' | '2-digit' | 'short' | 'narrow'

const DateRange: FC<DateRangeProps> = ({
  contract,
  isLifetime,
  locale = 'en-US',
}): JSX.Element | null => {
  const intl = useIntl()

  if (!contract.coverage) return null

  const startDateAsString = getFormattedDate(contract.coverage.starts / 1000, locale)

  if (isLifetime) {
    return (
      <>
        {intl.formatMessage(
          {
            id: 'DATE_RANGE_LIFETIME',
            defaultMessage: '{startDateAsString} - Lifetime',
          },
          { startDateAsString },
        )}
      </>
    )
  }

  const endDateAsString = getFormattedDate(contract.coverage.ends / 1000, locale)
  return (
    <>
      {intl.formatMessage(
        {
          id: 'DATE_RANGE',
          defaultMessage: '{startDateAsString} - {endDateAsString}',
        },
        { startDateAsString, endDateAsString },
      )}
    </>
  )
}
interface FormatDateProps {
  epochDateSeconds: number
  locale: string
  monthFormat: MonthFormat
}

const FormatDate: FC<FormatDateProps> = ({
  epochDateSeconds,
  locale = 'en-US',
  monthFormat = 'long',
}): JSX.Element | null => {
  if (!epochDateSeconds) {
    return null
  }

  const date = new Date(epochDateSeconds)
  const formattedDate = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: monthFormat,
    day: 'numeric',
  }).format(date)
  return <>{formattedDate}</>
}

export { DateRange, FormatDate }
