import type {
  Contract,
  ContractsGetResponse,
  ContractsV2GetResponse,
  Contract20220201GetResponse,
  Money,
  PlanContract,
} from '@helloextend/extend-api-client'
import { ContractType } from '@helloextend/extend-api-client'
import { set, flow, isObject, isNumber } from 'lodash/fp'

export function mapContractCoverage(contract: Contract): Contract {
  return flow(set('coverageAmount', setContractCoverageAmount(contract.product)))(contract)
}

function setContractCoverageAmount(contractProduct: Contract['product']): number {
  return contractProduct && contractProduct.purchasePrice
}

export function mapContractsGetToContract(
  contractsAPIResponse: ContractsGetResponse | ContractsV2GetResponse,
): Contract & { planDetails?: PlanContract } {
  if (contractsAPIResponse.contractVersion === '1.0') {
    return {
      type: ContractType.PCRS,
      ...contractsAPIResponse,
      plan: {
        ...contractsAPIResponse.plan,
        subcategory: contractsAPIResponse.planDetails?.pcmi_subcategory,
        purchasePrice: contractsAPIResponse.plan.purchasePrice?.amount,
        expectedPrice: contractsAPIResponse.plan.expectedPrice?.amount,
      },
      product: contractsAPIResponse.product && {
        ...contractsAPIResponse.product,
        listPrice: extractPricingInfo(contractsAPIResponse.product.listPrice),
        purchasePrice: extractPricingInfo(contractsAPIResponse.product.purchasePrice),
        reimbursementAmount: extractPricingInfo(contractsAPIResponse.product.reimbursementAmount),
        manufacturerWarrantyLengthLabor:
          contractsAPIResponse.product.manufacturerWarrantyLengthLabor || 0,
        manufacturerWarrantyLengthParts:
          contractsAPIResponse.product.manufacturerWarrantyLengthParts || 0,
        // Contracts api responses for contract versions have different product objects and title or name are sometimes undefined.
        title: contractsAPIResponse.product.title || contractsAPIResponse.product.name || '',
      },
      transactionTotal: getNumberAmount(contractsAPIResponse.transactionTotal || 0) ?? undefined,
      planDetails: contractsAPIResponse.planDetails,
    }
  }

  return {
    type: ContractType.PCRS,
    ...contractsAPIResponse,
    plan: contractsAPIResponse.plan && {
      ...contractsAPIResponse.plan,
      expectedPrice: contractsAPIResponse.plan?.expectedPrice,
      planId: contractsAPIResponse.plan.id,
      planSku: contractsAPIResponse.plan.skuId,
    },
    product: contractsAPIResponse.product && {
      ...contractsAPIResponse.product,
      listPrice: extractPricingInfo(contractsAPIResponse.product.listPrice),
      purchasePrice: extractPricingInfo(contractsAPIResponse.product.purchasePrice),
      reimbursementAmount: extractPricingInfo(contractsAPIResponse.product.reimbursementAmount),
      manufacturerWarrantyLengthLabor: contractsAPIResponse.product.manufacturerWarrantyLengthLabor,
      manufacturerWarrantyLengthParts: contractsAPIResponse.product.manufacturerWarrantyLengthParts,
      title: contractsAPIResponse.product.name || '',
      imageUrl: '',
      transactionDate: contractsAPIResponse.purchaseDate,
    },
    listPrice: extractPricingInfo(contractsAPIResponse.listPrice || 0),
    transactionTotal: contractsAPIResponse.transactionTotal,
    transactionDate: contractsAPIResponse.purchaseDate,
  }
}

export function mapContractsGet20220201ToContract(
  contractsAPIResponse: Contract20220201GetResponse,
): Contract & { planDetails?: PlanContract } {
  return {
    ...contractsAPIResponse,
    currency: contractsAPIResponse.purchaseCurrency,
    plan: contractsAPIResponse.plan && {
      ...contractsAPIResponse.plan,
      expectedPrice: contractsAPIResponse.listPrice,
      planId: contractsAPIResponse.plan.id,
      planSku: contractsAPIResponse.plan.skuId,
    },
    product: contractsAPIResponse.product && {
      ...contractsAPIResponse.product,
      listPrice: extractPricingInfo(contractsAPIResponse.product.listPrice),
      purchasePrice: extractPricingInfo(contractsAPIResponse.product.purchasePrice),
      reimbursementAmount: contractsAPIResponse.product.reimbursementAmount ?? 0,
      manufacturerWarrantyLengthLabor: contractsAPIResponse.product.manufacturerWarrantyLengthLabor,
      manufacturerWarrantyLengthParts: contractsAPIResponse.product.manufacturerWarrantyLengthParts,
      title: contractsAPIResponse.product.name || '',
      imageUrl: '',
      transactionDate: contractsAPIResponse.purchaseDate,
    },
    listPrice: contractsAPIResponse.listPrice,
    transactionDate: contractsAPIResponse.purchaseDate,
    purchasePrice: contractsAPIResponse.purchasePrice,
  }
}

function extractPricingInfo(value: Money | number): number {
  return isObject(value) ? value.amount : value
}

function getNumberAmount(input: number | Money): number {
  return isNumber(input) ? input : input.amount
}
