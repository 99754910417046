import type { FC } from 'react'
import { ErrorBoundary } from '@helloextend/component-commons'
import { customLogger } from '@extend/client-helpers'
import type * as selectors from '../../reducers/selectors'

interface ErrorBoundaryProps {
  page: FC
  messages: ReturnType<typeof selectors.getChatMessages>
  prompt: ReturnType<typeof selectors.getChatPrompt>
}

class ChatErrorBoundary extends ErrorBoundary<ErrorBoundaryProps> {
  componentDidCatch(error: Error, errorInfo: { componentStack: string }): void {
    customLogger.error(`Kaley Error: ${error.message}`, {
      ...errorInfo,
      chatState: {
        messages: this.props.messages.map((item) => item.content),
        prompt: { type: this.props.prompt?.type, slot: this.props.prompt?.slot },
      },
    })
  }
}

export { ChatErrorBoundary }
