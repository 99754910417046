import type { FC } from 'react'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { COLOR, Export, Icon } from '@extend/zen'
import { navLinksContent } from './nav-links-content'
import * as selectors from '../../reducers/selectors'
import { reset as resetPersistedStorage } from '../../store'

interface MenuLinksProps {
  closeMenu?: () => void
  isLoggedIn: boolean
}

const MenuLinks: FC<MenuLinksProps> = ({ closeMenu, isLoggedIn }) => {
  const consumerProfile = useSelector(selectors.getConsumerProfile)

  return (
    <NavLinksWrapper>
      {isLoggedIn ? (
        <>
          {navLinksContent.map((link) => {
            const { url, text, id } = link
            return (
              <NavLinkItem
                key={id}
                to={url}
                data-qa={`nav-link-${url.replace('/', '')}`}
                data-cy={`nav-link-${url.replace('/', '')}`}
                onClick={closeMenu}
                onKeyPress={closeMenu}
              >
                {text}
              </NavLinkItem>
            )
          })}
        </>
      ) : (
        <>
          <NavLinkItem to="/login" data-cy="nav-link-login" onClick={closeMenu}>
            Log in
          </NavLinkItem>
        </>
      )}
      <NavLinkItem to="/contact" data-cy="nav-link-contact" onClick={closeMenu}>
        Contact Support
      </NavLinkItem>
      {!isEmpty(consumerProfile) && (
        <NavLinkItem to="/profile" data-cy="nav-link-manage-account" onClick={closeMenu}>
          Manage My Account
        </NavLinkItem>
      )}
      {isLoggedIn && (
        <LogoutLinkWrapper>
          <NavLinkItem to={'/'} onClick={resetPersistedStorage} data-cy="nav-link-logout">
            <Icon icon={Export} color={COLOR.NEUTRAL[800]} />
            <span>Log out</span>
          </NavLinkItem>
        </LogoutLinkWrapper>
      )}
    </NavLinksWrapper>
  )
}

const NavLinksWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
})

const NavLinkItem = styled(NavLink)({
  fontSize: 20,
  lineHeight: '28px',
  fontWeight: 700,
  color: COLOR.NEUTRAL[700],
  fontFamily: 'Nunito Sans',
  display: 'flex',
  alignItems: 'center',
  gap: 8,
})

const LogoutLinkWrapper = styled.div({
  marginTop: '56px',
  display: 'flex',
  alignItems: 'center',
})

export { MenuLinks }
