import type {
  ContractsUpdateRequest,
  ContractsV2GetResponse,
  ContractType,
} from '@helloextend/extend-api-client'

type ContractSearchV1QueryStringOptions = {
  apiVersion?: string
  searchVersion?: '1'
  adminSyncStatus?: 'sync_error' | 'synced' | 'unsynced' | 'resync_required'
  contractId?: string
  customerEmail?: string
  customerPhone?: string
  productSerialNumber?: string
  productTransactionId?: string
  sellerId?: string
  status?: string
  transactionId?: string
  typeFilter?: ContractType[]
  showAll?: boolean
}

type GetContractParams = {
  accessToken?: string
  apiVersion?: string
  contractId: string
}

type ContractsSearchResponse = {
  items: ContractsV2GetResponse[]
  limit: number
  nextPageCursor?: string
}

type ContractsSearchV1Response = {
  contracts: ContractsV2GetResponse[]
}

type UpdateContractRequest = {
  apiVersion?: string
  contractId: string
  data: ContractsUpdateRequest
}

export type {
  ContractsSearchResponse,
  UpdateContractRequest,
  ContractsSearchV1Response,
  ContractSearchV1QueryStringOptions,
  GetContractParams,
}
