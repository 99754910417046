import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, Button, HeadingSmall, LinkButton, Subheading } from '@extend/zen'
import { Slot } from '@helloextend/extend-api-client'
import type { ShipmentSelectorProps } from './shipment-selector-types'
import { ShipmentProductsList } from './shipment-products-list'

export const ShipmentSelector: FC<ShipmentSelectorProps> = ({
  'data-cy': dataCy,
  prompt,
  isLoading,
  onSelect,
}) => {
  return (
    <Wrapper data-cy={dataCy}>
      <Header>{prompt.title}</Header>
      {Boolean(prompt.options.length) &&
        prompt.options.map((shipment, id) => {
          const { products, value, title, trackingUrl, outputText } = shipment
          return (
            <ShipmentWrapper key={value} data-cy="shipment-wrapper">
              <ShipmentHeader>
                <div>
                  <TrackingHeader>
                    <Subheading>{`SHIP ${id + 1}${trackingUrl ? '  ·' : ''}`}</Subheading>
                    {trackingUrl && (
                      <LinkButton
                        color="neutral"
                        data-cy={`shipment-track-btn-${id}`}
                        emphasis="low"
                        size="xsmall"
                        text="TRACK"
                        isDisabled={isLoading}
                        to={trackingUrl}
                        isBold={false}
                      />
                    )}
                  </TrackingHeader>
                  <StatusHeader>{title}</StatusHeader>
                </div>
                <div>
                  <Button
                    data-cy={`shipment-select-btn-${id}`}
                    emphasis="medium"
                    size="small"
                    type="button"
                    text="Select"
                    isDisabled={isLoading}
                    onClick={() => onSelect(Slot.Shipment, value, outputText)}
                  />
                </div>
              </ShipmentHeader>
              <ShipmentProductsList products={products} />
            </ShipmentWrapper>
          )
        })}
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'grid',
  grid: 'auto-flow / 1fr',
  gap: 16,
  padding: '0px 20px',
  maxWidth: 640,
  margin: '0 auto',
})

const ShipmentWrapper = styled.div({
  color: COLOR.NEUTRAL['700'],
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 8,
})

const StatusHeader = styled.div({
  margin: 0,
  padding: 0,
  fontSize: '17px',
  lineHeight: '24px',
  fontWeight: 700,
})

const Header = styled(HeadingSmall)({
  textAlign: 'center',
})

const TrackingHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
})
const ShipmentHeader = styled.div({
  borderBottom: `1px solid ${COLOR.NEUTRAL[300]}`,
  padding: '16px 16px 8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})
