import type { FC } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import { COLOR, ContentLayout } from '@helloextend/customers-ui'

import image from '../../lib/images'

const ErrorPage: FC = () => {
  const history = useHistory()
  const handleOnclick = (): void => history.push('/contact')

  return (
    <ContentLayout pageTitle="Error Page">
      <ErrorBody>
        <HeaderError>
          <KaileyIcon src={image.kaleySmall} alt="kailey_icon" />
          <HeaderText>Oops! We&apos;ve encountered an error</HeaderText>
        </HeaderError>
        <MessageBody>
          It looks like we&apos;ve got some leaky pipes to attend to. Please try again. {'\n'}
          If the problem continues to persist, please feel free to contact us.
        </MessageBody>
        <Button onClick={handleOnclick}>Contact Support</Button>
      </ErrorBody>
    </ContentLayout>
  )
}

const ErrorBody = styled.div({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  margin: 'auto',
  width: '320px',
  height: '250px',
  [bp.md]: {
    width: '800px',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

const HeaderError = styled.div({
  [bp.md]: {
    width: '770px',
  },
  width: '300px',
  height: '52px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const HeaderText = styled.div({
  [bp.md]: {
    lineHeight: '52px',
    fontSize: '44px',
    fontWeight: 600,
  },
  lineHeight: '25px',
  fontSize: '22px',
  marginLeft: '15px',
  fontWeight: 300,
})

const Button = styled.button({
  border: `1px solid ${COLOR.BLUE[800]}`,
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '247px',
  height: '47px',
  fontSize: '20px',
  lineHeight: '27px',
  textAlign: 'center',
  color: COLOR.BLUE[800],
})

const MessageBody = styled.div({
  [bp.md]: {
    width: '770px',
    height: '56px',
    fontSize: '20px',
    lineHeight: '28px',
  },
})

const KaileyIcon = styled.img({
  height: '52px',
  width: '52px',
  transform: 'scaleX(-1)',
})

export default ErrorPage
