{
  "CONTRACT_TRANSFER_PRODUCT_DETAILS_TERM_LIFETIME": [
    {
      "type": 0,
      "value": "Lifetime"
    }
  ],
  "DATE_RANGE": [
    {
      "type": 1,
      "value": "startDateAsString"
    },
    {
      "type": 0,
      "value": "–"
    },
    {
      "type": 1,
      "value": "endDateAsString"
    }
  ],
  "DATE_RANGE_LIFETIME": [
    {
      "type": 1,
      "value": "startDateAsString"
    },
    {
      "type": 0,
      "value": "–Lifetime"
    }
  ],
  "PLAN_DETAILS_PLAN_CALCULATOR_DESKTOP": [
    {
      "type": 0,
      "value": "With "
    },
    {
      "children": [
        {
          "type": 1,
          "value": "minusIcon"
        }
      ],
      "type": 8,
      "value": "MinusIconWrapper"
    },
    {
      "children": [
        {
          "offset": 0,
          "options": {
            "one": {
              "value": [
                {
                  "type": 1,
                  "value": "termLengthYears"
                },
                {
                  "type": 0,
                  "value": " year"
                }
              ]
            },
            "other": {
              "value": [
                {
                  "type": 1,
                  "value": "termLengthYears"
                },
                {
                  "type": 0,
                  "value": " year"
                }
              ]
            }
          },
          "pluralType": "cardinal",
          "type": 6,
          "value": "termLengthYears"
        },
        {
          "type": 0,
          "value": " "
        },
        {
          "options": {
            "other": {
              "value": [
                {
                  "type": 0,
                  "value": "coverage"
                }
              ]
            },
            "plan": {
              "value": [
                {
                  "type": 0,
                  "value": "coverage"
                }
              ]
            },
            "policy": {
              "value": [
                {
                  "type": 0,
                  "value": "coverage"
                }
              ]
            }
          },
          "type": 5,
          "value": "policyType"
        }
      ],
      "type": 8,
      "value": "PlanLength"
    },
    {
      "children": [
        {
          "type": 1,
          "value": "plusIcon"
        }
      ],
      "type": 8,
      "value": "PlusIconWrapper"
    },
    {
      "type": 0,
      "value": " your purchase is protected until "
    },
    {
      "children": [
        {
          "type": 1,
          "value": "planTermsEndDate"
        }
      ],
      "type": 8,
      "value": "strong"
    }
  ],
  "PLAN_DETAILS_PLAN_CALCULATOR_MOBILE": [
    {
      "children": [
        {
          "type": 1,
          "value": "minusIcon"
        }
      ],
      "type": 8,
      "value": "MinusIconWrapper"
    },
    {
      "children": [
        {
          "offset": 0,
          "options": {
            "one": {
              "value": [
                {
                  "type": 1,
                  "value": "termLengthYears"
                },
                {
                  "type": 0,
                  "value": " year"
                }
              ]
            },
            "other": {
              "value": [
                {
                  "type": 1,
                  "value": "termLengthYears"
                },
                {
                  "type": 0,
                  "value": " year"
                }
              ]
            }
          },
          "pluralType": "cardinal",
          "type": 6,
          "value": "termLengthYears"
        },
        {
          "type": 0,
          "value": " "
        },
        {
          "options": {
            "other": {
              "value": [
                {
                  "type": 0,
                  "value": "coverage"
                }
              ]
            },
            "plan": {
              "value": [
                {
                  "type": 0,
                  "value": "coverage"
                }
              ]
            },
            "policy": {
              "value": [
                {
                  "type": 0,
                  "value": "coverage"
                }
              ]
            }
          },
          "type": 5,
          "value": "policyType"
        }
      ],
      "type": 8,
      "value": "PlanLength"
    },
    {
      "children": [
        {
          "type": 1,
          "value": "plusIcon"
        }
      ],
      "type": 8,
      "value": "PlusIconWrapper"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "protects your purchase until: "
        },
        {
          "children": [
            {
              "type": 1,
              "value": "planTermsEndDate"
            }
          ],
          "type": 8,
          "value": "strong"
        }
      ],
      "type": 8,
      "value": "MobileAlign"
    }
  ],
  "PLAN_DETAILS_TESTIMONIAL_TITLE": [
    {
      "type": 0,
      "value": "We "
    },
    {
      "type": 1,
      "value": "heart"
    },
    {
      "type": 0,
      "value": " making customers happy"
    }
  ],
  "SUPPORT_FORM_EMAIL_LABEL": [
    {
      "type": 0,
      "value": "Email"
    }
  ],
  "SUPPORT_FORM_EMAIL_VALIDATION": [
    {
      "type": 0,
      "value": "Please enter a valid email address"
    }
  ],
  "SUPPORT_FORM_INTRO_MESSAGE": [
    {
      "type": 0,
      "value": "Questions, feedback, or special requests—we are here to help! If you'd like to speak with someone, you can do so by calling 877-248-7707."
    }
  ],
  "SUPPORT_FORM_INTRO_TITLE": [
    {
      "type": 0,
      "value": "Hey there!"
    }
  ],
  "SUPPORT_FORM_MESSAGE_LABEL": [
    {
      "type": 0,
      "value": "How can we help you?"
    }
  ],
  "SUPPORT_FORM_MESSAGE_MAXIMUM_LENGTH": [
    {
      "type": 0,
      "value": "Please use up to "
    },
    {
      "type": 1,
      "value": "MAX_MESSAGE_LENGTH"
    },
    {
      "type": 0,
      "value": " characters maximum"
    }
  ],
  "SUPPORT_FORM_MESSAGE_VALIDATION": [
    {
      "type": 0,
      "value": "Send us a message"
    }
  ],
  "SUPPORT_FORM_NAME_LABEL": [
    {
      "type": 0,
      "value": "Name"
    }
  ],
  "SUPPORT_FORM_NAME_MAXIMUM_LENGTH": [
    {
      "type": 0,
      "value": "Please use up to "
    },
    {
      "type": 1,
      "value": "MAX_NAME_LENGTH"
    },
    {
      "type": 0,
      "value": " characters maximum"
    }
  ],
  "SUPPORT_FORM_NAME_VALIDATION": [
    {
      "type": 0,
      "value": "Please enter your name"
    }
  ],
  "SUPPORT_FORM_PHONE_NUMBER_LABEL": [
    {
      "type": 0,
      "value": "Phone Number"
    }
  ],
  "SUPPORT_FORM_PHONE_NUMBER_VALIDATION": [
    {
      "type": 0,
      "value": "Please enter a valid phone number"
    }
  ],
  "SUPPORT_FORM_SUBMIT_BUTTON": [
    {
      "type": 0,
      "value": "Send Message"
    }
  ],
  "SUPPORT_FORM_SUCCESS_MESSAGE": [
    {
      "type": 0,
      "value": "We will be in touch soon."
    }
  ],
  "SUPPORT_FORM_SUCCESS_TITLE": [
    {
      "type": 0,
      "value": "Thanks for reaching out!"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_LABEL": [
    {
      "type": 0,
      "value": "Support Topic"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_OTHER": [
    {
      "type": 0,
      "value": "Other"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_PRODUCT_PROTECTION": [
    {
      "type": 0,
      "value": "Product Protection"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_SELECT_OPTION": [
    {
      "type": 0,
      "value": "Select an option"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_SHIPPING_PROTECTION": [
    {
      "type": 0,
      "value": "Shipping Protection"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_VALIDATION": [
    {
      "type": 0,
      "value": "Please make a selection"
    }
  ],
  "SUPPORT_FORM_TITLE": [
    {
      "type": 0,
      "value": "Contact Us"
    }
  ],
  "WARRANTY_TERMS_CONTRACT_DETAILS_TITLE": [
    {
      "type": 0,
      "value": "Order Details"
    }
  ],
  "WARRANTY_TERMS_NO": [
    {
      "type": 0,
      "value": "No"
    }
  ],
  "termLengthYears": [
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 1,
              "value": "termLengthYears"
            },
            {
              "type": 0,
              "value": " year"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 1,
              "value": "termLengthYears"
            },
            {
              "type": 0,
              "value": " years"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "termLengthYears"
    }
  ]
}