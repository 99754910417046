import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/customers-ui'

interface HamburgerIconProps {
  openMenu: () => void
  dataQa?: string
}

const HamburgerIcon: React.FC<HamburgerIconProps> = ({ openMenu, dataQa }) => (
  <Wrapper id="burger-icon" data-qa={dataQa} onClick={openMenu}>
    <Line />
    <Line />
    <Line />
  </Wrapper>
)

const Line = styled.div({
  height: '1px',
  background: COLOR.WHITE,
  marginBottom: '5px',
  width: '23px',
})

const Wrapper = styled.div({
  alignSelf: 'center',
  marginTop: 5,
  '&:hover': {
    cursor: 'pointer',
  },
})

export { HamburgerIcon }
