import type { Money } from '@helloextend/extend-api-client'

export type WalletGetResponse = Omit<Wallet, 'cards'> & {
  cards: Array<Omit<Card, 'transation' | 'virtualCardProviderDetails'>>
}

export type WalletClosedReason =
  | 'card_fully_spent'
  | 'address_verification_failure'
  | 'generic_card_decline'
  | 'product_unavailable'
  | 'other'

export interface WalletClosedMetaData {
  reason: WalletClosedReason
  closedAt: number
  notes?: string
}

export interface Wallet {
  id: string
  cards: Card[]
  initialValue: Money
  initialBufferedValue: Money
  remainingValue: Money
  metadata: WalletMetadata
  status: WalletStatus
  mids?: string[]
  createdAt: number
  updatedAt: number
  userEmail: string
  closedMetaData?: WalletClosedMetaData
}

export type WalletStatus = 'closed' | 'active'
export type WalletMetadataType = 'serviceOrder'

export interface WalletMetadata {
  type: WalletMetadataType
  id: string
}

export interface Card {
  id: string
  walletId: string
  virtualCardProviderDetails: VirtualCardProviderDetails
  status: CardStatus
  value: Money
  transaction?: Transaction
  expiresAt?: number
  createdAt: number
  updatedAt: number
  initializedBy: CardInitializationMethods
}

/*
    Inactive -> Card created but needs to be activated before use
    Active -> Card can be used
    Expired -> Card has expired and no longer can be used
    Spent -> Card has been used can no longer be used again
*/
export type CardStatus = 'inactive' | 'active' | 'expired' | 'spent' | 'terminated'

export type CardInitializationMethods = 'wallet_created' | 'card_spent' | 'card_expired'

export type VirtualCardProvider = 'marqeta'

// All fields in object generated by 3rd party service
export interface VirtualCardProviderDetails {
  provider: VirtualCardProvider
  cardToken: string
  userToken: string
  velocityToken: string
  authorizationTokens: string[]
}

export interface VirtualCardProviderTransactionDetails {
  transactionToken: string
}
export interface Transaction {
  value: Money
  transactionDate: number
  virtualCardProviderTransactionDetails: VirtualCardProviderTransactionDetails
}
