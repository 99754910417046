import type { FC } from 'react'
import React, { useEffect, useState, useCallback } from 'react'
import { bp } from '@helloextend/customers-ui'
import styled from '@emotion/styled'
import { useStartPopaChallengeMutation } from '@helloextend/extend-api-rtk-query'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import type { AuthStartRequest } from '@helloextend/extend-api-rtk-query/src/my-extend/types'
import { buildPayload } from '../../pages/myextend-authentication/utils'
import { setAccessToken } from '../../store/slices/my-extend'
import type { Locale } from '../../types/localization'
import { MyExtendAuthPasscodeInput } from '../../pages/myextend-authentication/myextend-auth-passcode-input'
import { isFetchBaseQueryError, isCodeInError } from '../../lib/helper-functions'
import { MyExtendAuthErrors } from '../../pages/myextend-authentication/myextend-auth-errors'

interface PopaFlowProps {
  userInfo: { email: string; phoneNumber: string }
  isAuth?: boolean
  resetForm: (error: string | null) => void
  handleSuccess: () => void
}

export const PopaFlow: FC<PopaFlowProps> = ({
  userInfo,
  isAuth = true,
  resetForm,
  handleSuccess,
}) => {
  const [sessionId, setSessionId] = useState<string>('')
  const [errorType, setErrorType] = useState<string>('')
  const [flowStarted, setFlowStarted] = useState(false)
  const [getSessionId] = useStartPopaChallengeMutation()
  const dispatch = useDispatch()
  const intl = useIntl()
  const [isErrorPageVisible, setIsErrorPageVisible] = useState<boolean>(false)

  const requestSessionId = useCallback(async () => {
    if (isAuth) {
      dispatch(setAccessToken(''))
    }
    if (userInfo.email || userInfo.phoneNumber) {
      try {
        const payload = buildPayload(userInfo, intl.locale as Locale) as AuthStartRequest
        const response = await getSessionId({ body: payload, apiVersion: 'latest' }).unwrap()
        if (response && response.sessionId) {
          setSessionId(response.sessionId)
        }
      } catch (error) {
        if (isFetchBaseQueryError(error) && isCodeInError(error.data)) {
          if (error.data.code === 'max_number_of_challenge_requests') {
            setErrorType(error.data.code)
          } else {
            setErrorType('authentication_failed')
          }
        } else {
          setErrorType('authentication_failed')
        }
        setIsErrorPageVisible(true)
      }
    }
  }, [dispatch, userInfo, intl.locale, getSessionId, isAuth])

  useEffect(() => {
    if (!flowStarted) {
      requestSessionId()
      setFlowStarted(true)
    }
  }, [flowStarted, requestSessionId, setFlowStarted])

  return (
    <>
      {isErrorPageVisible ? (
        <MyExtendAuthErrors isAuth={isAuth} error={errorType} />
      ) : (
        <Wrapper isAuth={isAuth}>
          <Container>
            <MyExtendAuthPasscodeInput
              isAuth={isAuth}
              handleSuccess={handleSuccess}
              email={userInfo.email}
              phoneNumber={userInfo.phoneNumber}
              sessionId={sessionId}
              resetForm={resetForm}
              resendPasscode={requestSessionId}
            />
          </Container>
        </Wrapper>
      )}
    </>
  )
}

const Wrapper = styled.div<{ isAuth?: boolean }>(({ isAuth }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: `${isAuth ? '100vw' : '100%'}`,
  overflow: 'hidden',
  [bp.mobile]: {
    margin: 0,
    ...(isAuth && { padding: '48px 20px 152px' }),
    ...(isAuth && { marginTop: 40 }),
  },
  [bp.desktop]: {
    position: 'relative',
    padding: 0,
    height: `${isAuth ? '100vh' : '100%'}`,
  },
}))

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  [bp.mobile]: {
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 0,
    gap: 50,
    width: '100%',
  },
  [bp.desktop]: {
    width: '100%',
    gap: 0,
    padding: 0,
    justifyContent: 'center',
    flexDirection: 'column',
  },
  '@media (min-width: 1180px)': {
    width: '100%',
    gap: 0,
    padding: 0,
    justifyContent: 'center',
    flexDirection: 'column',
  },
})
