import { createBooleanReducer } from './factories'
import type { Action } from '../types/action-type'

export type ContactFormReducerState = boolean

const contactFormSubmitted = createBooleanReducer<Action>(
  ['CONTACT_FORM_SUBMIT_SUCCESS'],
  ['CONTACT_FORM_RESET_SUBMIT'],
)

export default contactFormSubmitted
