{
  "CONTRACT_TRANSFER_PRODUCT_DETAILS_TERM_LIFETIME": [
    {
      "type": 0,
      "value": "Durée"
    }
  ],
  "DATE_RANGE": [
    {
      "type": 0,
      "value": "Du "
    },
    {
      "type": 1,
      "value": "startDateAsString"
    },
    {
      "type": 0,
      "value": " au "
    },
    {
      "type": 1,
      "value": "endDateAsString"
    }
  ],
  "DATE_RANGE_LIFETIME": [
    {
      "type": 1,
      "value": "startDateAsString"
    },
    {
      "type": 0,
      "value": " - Durée"
    }
  ],
  "PLAN_DETAILS_PLAN_CALCULATOR_DESKTOP": [
    {
      "type": 0,
      "value": "Avec ​"
    },
    {
      "children": [
        {
          "type": 0,
          "value": "​"
        },
        {
          "type": 1,
          "value": "minusIcon"
        },
        {
          "type": 0,
          "value": "​"
        }
      ],
      "type": 8,
      "value": "MinusIconWrapper"
    },
    {
      "type": 0,
      "value": "​​"
    },
    {
      "children": [
        {
          "type": 0,
          "value": "​"
        },
        {
          "offset": 0,
          "options": {
            "one": {
              "value": [
                {
                  "type": 1,
                  "value": "termLengthYears"
                },
                {
                  "type": 0,
                  "value": " an de couverture"
                }
              ]
            },
            "other": {
              "value": [
                {
                  "type": 0,
                  "value": "une couverture de "
                },
                {
                  "type": 1,
                  "value": "termLengthYears"
                },
                {
                  "type": 0,
                  "value": " ans"
                }
              ]
            }
          },
          "pluralType": "cardinal",
          "type": 6,
          "value": "termLengthYears"
        },
        {
          "type": 0,
          "value": "​​"
        }
      ],
      "type": 8,
      "value": "PlanLength"
    },
    {
      "type": 0,
      "value": "​​"
    },
    {
      "children": [
        {
          "type": 0,
          "value": "​"
        },
        {
          "type": 1,
          "value": "plusIcon"
        },
        {
          "type": 0,
          "value": "​"
        }
      ],
      "type": 8,
      "value": "PlusIconWrapper"
    },
    {
      "type": 0,
      "value": "​ votre achat est protégé jusqu'au ​"
    },
    {
      "children": [
        {
          "type": 0,
          "value": "​"
        },
        {
          "type": 1,
          "value": "planTermsEndDate"
        },
        {
          "type": 0,
          "value": "​"
        }
      ],
      "type": 8,
      "value": "strong"
    }
  ],
  "PLAN_DETAILS_PLAN_CALCULATOR_MOBILE": [
    {
      "children": [
        {
          "type": 1,
          "value": "minusIcon"
        }
      ],
      "type": 8,
      "value": "MinusIconWrapper"
    },
    {
      "children": [
        {
          "type": 0,
          "value": "​"
        },
        {
          "offset": 0,
          "options": {
            "one": {
              "value": [
                {
                  "type": 1,
                  "value": "termLengthYears"
                },
                {
                  "type": 0,
                  "value": " an de couverture"
                }
              ]
            },
            "other": {
              "value": [
                {
                  "type": 0,
                  "value": "une couverture de "
                },
                {
                  "type": 1,
                  "value": "termLengthYears"
                },
                {
                  "type": 0,
                  "value": " ans"
                }
              ]
            }
          },
          "pluralType": "cardinal",
          "type": 6,
          "value": "termLengthYears"
        },
        {
          "type": 0,
          "value": "​​"
        }
      ],
      "type": 8,
      "value": "PlanLength"
    },
    {
      "type": 0,
      "value": "​​"
    },
    {
      "children": [
        {
          "type": 1,
          "value": "plusIcon"
        }
      ],
      "type": 8,
      "value": "PlusIconWrapper"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "protège votre achat jusqu'au : "
        },
        {
          "children": [
            {
              "type": 1,
              "value": "planTermsEndDate"
            }
          ],
          "type": 8,
          "value": "strong"
        }
      ],
      "type": 8,
      "value": "MobileAlign"
    }
  ],
  "PLAN_DETAILS_TESTIMONIAL_TITLE": [
    {
      "type": 0,
      "value": "Nous "
    },
    {
      "type": 1,
      "value": "heart"
    },
    {
      "type": 0,
      "value": " faisons plaisir aux clients"
    }
  ],
  "SUPPORT_FORM_EMAIL_LABEL": [
    {
      "type": 0,
      "value": "Adresse courriel"
    }
  ],
  "SUPPORT_FORM_EMAIL_VALIDATION": [
    {
      "type": 0,
      "value": "Veuillez entrer une adresse courriel valide."
    }
  ],
  "SUPPORT_FORM_INTRO_MESSAGE": [
    {
      "type": 0,
      "value": "Des questions, des commentaires ou des demandes spéciales? Nous sommes là pour vous aider! Pour parler à quelqu'un, appelez le 877 248-7707."
    }
  ],
  "SUPPORT_FORM_INTRO_TITLE": [
    {
      "type": 0,
      "value": "Bonjour!"
    }
  ],
  "SUPPORT_FORM_MESSAGE_LABEL": [
    {
      "type": 0,
      "value": "Comment pouvons-nous vous aider?"
    }
  ],
  "SUPPORT_FORM_MESSAGE_MAXIMUM_LENGTH": [
    {
      "type": 0,
      "value": "Vous ne pouvez pas utiliser plus de "
    },
    {
      "type": 1,
      "value": "MAX_MESSAGE_LENGTH"
    },
    {
      "type": 0,
      "value": " caractères."
    }
  ],
  "SUPPORT_FORM_MESSAGE_VALIDATION": [
    {
      "type": 0,
      "value": "Envoyez-nous un message"
    }
  ],
  "SUPPORT_FORM_NAME_LABEL": [
    {
      "type": 0,
      "value": "Nom"
    }
  ],
  "SUPPORT_FORM_NAME_MAXIMUM_LENGTH": [
    {
      "type": 0,
      "value": "Vous ne pouvez pas utiliser plus de "
    },
    {
      "type": 1,
      "value": "MAX_NAME_LENGTH"
    },
    {
      "type": 0,
      "value": " caractères."
    }
  ],
  "SUPPORT_FORM_NAME_VALIDATION": [
    {
      "type": 0,
      "value": "Veuillez entrer votre nom."
    }
  ],
  "SUPPORT_FORM_PHONE_NUMBER_LABEL": [
    {
      "type": 0,
      "value": "Numéro de téléphone"
    }
  ],
  "SUPPORT_FORM_PHONE_NUMBER_VALIDATION": [
    {
      "type": 0,
      "value": "Veuillez entrer un numéro de téléphone valide."
    }
  ],
  "SUPPORT_FORM_SUBMIT_BUTTON": [
    {
      "type": 0,
      "value": "Envoyer un message"
    }
  ],
  "SUPPORT_FORM_SUCCESS_MESSAGE": [
    {
      "type": 0,
      "value": "Nous vous contacterons bientôt."
    }
  ],
  "SUPPORT_FORM_SUCCESS_TITLE": [
    {
      "type": 0,
      "value": "Merci de nous avoir contactés!"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_LABEL": [
    {
      "type": 0,
      "value": "Type d'aide"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_OTHER": [
    {
      "type": 0,
      "value": "Autre"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_PRODUCT_PROTECTION": [
    {
      "type": 0,
      "value": "Assurance produit"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_SELECT_OPTION": [
    {
      "type": 0,
      "value": "Sélectionnez une option"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_SHIPPING_PROTECTION": [
    {
      "type": 0,
      "value": "Assurance transport"
    }
  ],
  "SUPPORT_FORM_SUPPORT_TOPIC_VALIDATION": [
    {
      "type": 0,
      "value": "Veuillez choisir une option"
    }
  ],
  "SUPPORT_FORM_TITLE": [
    {
      "type": 0,
      "value": "Nous contacter"
    }
  ],
  "WARRANTY_TERMS_CONTRACT_DETAILS_TITLE": [
    {
      "type": 0,
      "value": "Détails de la commande"
    }
  ],
  "WARRANTY_TERMS_NO": [
    {
      "type": 0,
      "value": "Non"
    }
  ],
  "termLengthYears": [
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 1,
              "value": "termLengthYears"
            },
            {
              "type": 0,
              "value": " an"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 1,
              "value": "termLengthYears"
            },
            {
              "type": 0,
              "value": " ans"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "termLengthYears"
    }
  ]
}