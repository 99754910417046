import { combineReducers } from 'redux'
import {
  claimPhotosApi,
  serviceOrdersApi,
  contentApi,
  plansApi,
  virtualCardsApi,
  claimsApi,
  contractsApi,
  storesApi,
  plansTermsApi,
  servicersApi,
  adjudicationManagementApi,
  incredibotApi,
  myExtendApi,
} from '@helloextend/extend-api-rtk-query'
import type { ContactFormReducerState } from './contact-form'
import contactForm from './contact-form'
import type { ChatReducerState } from './chat'
import chat from './chat'
import type { ApiErrorState } from './api-error'
import apiError from './api-error'
import type { ResourceFetchErrorState } from './resource-fetch-error'
import resourceFetchError from './resource-fetch-error'
import type { AuthReducerState } from './auth'
import auth from './auth'
import type { SlicesState } from '../store/slices'
import { slicesReducers } from '../store/slices'

export interface RootState extends SlicesState {
  apiError: ApiErrorState
  auth: AuthReducerState
  chat: ChatReducerState
  contactForm: ContactFormReducerState
  resourceFetchError: ResourceFetchErrorState
  [serviceOrdersApi.reducerPath]: ReturnType<typeof serviceOrdersApi.reducer>
  [servicersApi.reducerPath]: ReturnType<typeof servicersApi.reducer>
  [virtualCardsApi.reducerPath]: ReturnType<typeof virtualCardsApi.reducer>
  [claimsApi.reducerPath]: ReturnType<typeof claimsApi.reducer>
  [contractsApi.reducerPath]: ReturnType<typeof contractsApi.reducer>
  [contentApi.reducerPath]: ReturnType<typeof contentApi.reducer>
  [plansApi.reducerPath]: ReturnType<typeof plansApi.reducer>
  [storesApi.reducerPath]: ReturnType<typeof storesApi.reducer>
  [plansTermsApi.reducerPath]: ReturnType<typeof plansTermsApi.reducer>
  [adjudicationManagementApi.reducerPath]: ReturnType<typeof adjudicationManagementApi.reducer>
  [incredibotApi.reducerPath]: ReturnType<typeof incredibotApi.reducer>
  [claimPhotosApi.reducerPath]: ReturnType<typeof claimPhotosApi.reducer>
  [myExtendApi.reducerPath]: ReturnType<typeof myExtendApi.reducer>
}

export default combineReducers<RootState>({
  apiError,
  auth,
  chat,
  contactForm,
  resourceFetchError,
  [serviceOrdersApi.reducerPath]: serviceOrdersApi.reducer,
  [servicersApi.reducerPath]: servicersApi.reducer,
  [virtualCardsApi.reducerPath]: virtualCardsApi.reducer,
  [claimsApi.reducerPath]: claimsApi.reducer,
  [contractsApi.reducerPath]: contractsApi.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
  [plansApi.reducerPath]: plansApi.reducer,
  [storesApi.reducerPath]: storesApi.reducer,
  [plansTermsApi.reducerPath]: plansTermsApi.reducer,
  [adjudicationManagementApi.reducerPath]: adjudicationManagementApi.reducer,
  [incredibotApi.reducerPath]: incredibotApi.reducer,
  [claimPhotosApi.reducerPath]: claimPhotosApi.reducer,
  [myExtendApi.reducerPath]: myExtendApi.reducer,
  ...slicesReducers,
})
