import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from '@emotion/styled'
import { bp, customLogger } from '@extend/client-helpers'
import { ContentLayout } from '@helloextend/customers-ui'
import { SplashScreen } from '@helloextend/component-commons'
import { useActivateCardMutation } from '@helloextend/extend-api-rtk-query'
import { string } from '@extend/client-helpers'
import { useIntl } from 'react-intl'
import type { Locale } from '../../types/localization'
import ErrorPage from '../../components/common/error-page'
import {
  ClaimFulfillmentHeading,
  ClaimFulfillmentFooter,
  FaqSection,
  ProductDetails,
} from '../../components/claim-fulfillment'
import { VirtualCardDetails } from './virtual-card-details'
import { ModalCardholderAgreement } from '../../components/modal-cardholder-agreement'
import { useGetVcData } from '../../hooks/use-get-vc-data'

const VirtualCardFulfillmentMyExtend: FC = () => {
  const [activateCard, { isSuccess: isCardActivated }] = useActivateCardMutation()
  const [isCardholderAgreementModalOpen, setIsCardholderAgreementModalOpen] = useState(false)
  const [isAgreementAccepted, setIsAgreementAccepted] = useState(false)
  const locale = useIntl().locale as Locale

  const { id: claimId } = useParams<{ id: string }>()
  const history = useHistory()

  const {
    isLoading,
    isVirtualCardFulfillment,
    isUserAgreementLoading,
    isCanada,
    replacementFulfillmentMethod,
    isRequestError,
    hasAgreementNeverBeenAccepted,
    isVirtualCardUserAgreementSubmitting,
    claim,
    contract,
    serviceOrder,
    wallet,
    walletId,
    store,
    isVirtualCardUserAgreementSubmitSuccess,
    submitUserAgreement,
  } = useGetVcData(claimId)

  const setLocale = (currentLocale: Locale, newLocale: Locale): void => {
    window.location.href = window.location.href.replace(currentLocale, newLocale)
  }

  useEffect(() => {
    if (isUserAgreementLoading) return
    if (hasAgreementNeverBeenAccepted) {
      setIsCardholderAgreementModalOpen(true)
      return
    }

    if (serviceOrder?.fulfillmentMetaData && walletId && wallet && wallet.remainingValue?.amount) {
      activateCard({ walletId })
    }
  }, [
    isUserAgreementLoading,
    hasAgreementNeverBeenAccepted,
    serviceOrder,
    walletId,
    activateCard,
    isVirtualCardUserAgreementSubmitSuccess,
    wallet?.remainingValue?.amount,
  ])

  if (isCanada && locale !== 'en-CA') setLocale(locale, 'en-CA')

  if (isRequestError || (!isLoading && !isVirtualCardFulfillment)) {
    customLogger.warn('[Err: Virtual Card Page]: Request Error', {
      // setting keys to string so they show in DD logs
      isRequestError: isRequestError || 'No RequestError',
      isLoading: isLoading || 'Has Loaded',
      isVirtualCardFulfillment: isVirtualCardFulfillment || 'No VirtualCardFulfillment',
      serviceOrderConfigurations:
        JSON.stringify(serviceOrder?.configurations) || 'No ServiceOrder Configurations',
    })
    return <ErrorPage />
  }

  if (isLoading) return <SplashScreen data-cy="splash-screen" />

  if (!contract || !claim || !serviceOrder || !wallet || !walletId) {
    customLogger.warn('[Err: Virtual Card Page]: Missing Data', {
      contractId: contract?.id || 'No Contract',
      claimId: claim?.id || 'No Claim',
      serviceOrderId: serviceOrder?.id || 'No ServiceOrder',
      walletId: walletId || 'No WalletId',
    })
    return <ErrorPage />
  }

  const { customer, sellerName } = contract
  const [firstName] = string.splitName(customer.name || '')

  const dismissCardholderAgreement = (): void => {
    history.push('/virtual_card_fulfillment/support')
  }

  const acceptCardholderAgreement = async (): Promise<void> => {
    await submitUserAgreement()
    setIsCardholderAgreementModalOpen(false)
  }

  return (
    <ContentLayout>
      <Main role="main">
        <ClaimFulfillmentHeading
          firstName={firstName}
          isCanada={isCanada}
          locale={locale}
          setLocale={setLocale}
        />
        <VirtualCardDetails
          isHidden={hasAgreementNeverBeenAccepted}
          isCardActivated={isCardActivated}
          remainingAmount={wallet.remainingValue.amount}
          walletId={walletId}
          sellerName={sellerName}
          store={store}
          contract={contract}
          locale={locale}
          isCA={serviceOrder.totalItemsValue?.currencyCode === 'CAD'}
        />
        <ProductDetails contract={contract} claim={claim} locale={locale} />
        <FaqSection fulfillmentType={replacementFulfillmentMethod} locale={locale} />
        <ClaimFulfillmentFooter customer={customer} locale={locale} />
      </Main>
      <ModalCardholderAgreement
        isCA={isCanada}
        isModalOpen={isCardholderAgreementModalOpen}
        isAgreementAccepted={isAgreementAccepted}
        isProcessing={isVirtualCardUserAgreementSubmitting}
        onDismiss={dismissCardholderAgreement}
        onSubmit={acceptCardholderAgreement}
        toggleIsAgreementAccepted={setIsAgreementAccepted}
      />
    </ContentLayout>
  )
}

export { VirtualCardFulfillmentMyExtend }

const Main = styled.div({
  margin: '0 auto',
  padding: '16px 40px',
  [bp.md]: {
    padding: '50px 40px 72px 40px',
  },
  [bp.lg]: {
    padding: '50px 116px 72px 116px',
  },
})
