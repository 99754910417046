import type { FC } from 'react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { ShipmentSelectPrompt, Slot } from '@helloextend/extend-api-client'
import { ShipmentSelector } from '@helloextend/kaley-ui'
import { Modal, ModalController } from '@extend/zen'
import { Button } from '@helloextend/customers-ui'
import styled from '@emotion/styled'
import { createPortal } from 'react-dom'
import { chatActions } from '../../../../../actions'
import type { RootState } from '../../../../../reducers'
import * as selectors from '../../../../../reducers/selectors'

interface DispatchProps {
  onAddInput: typeof chatActions.chatSessionUpdate
}

type ChatShipmentSelectProps = DispatchProps

const ChatShipmentSelect: FC<ChatShipmentSelectProps> = ({ onAddInput }) => {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const prompt = useSelector((state: RootState) =>
    selectors.getChatPrompt(state),
  ) as ShipmentSelectPrompt

  const onSelect = (slot: Slot, value: string | number, outputText?: string): void => {
    dispatch(chatActions.chatPromptClear())
    onAddInput({ slot, value, message: outputText })
  }

  const handleModalOpen = (): void => {
    setIsModalOpen(true)
  }

  const handleModalClose = (): void => {
    setIsModalOpen(false)
  }

  return (
    <>
      <ChatShipmentSelectButtonWrapper>
        <Button
          dataCy="chat-shipment-select-button"
          kind="pillButton"
          size="xxs"
          type="button"
          ariaLabel="Select shipment"
          text="Select shipment"
          onClick={handleModalOpen}
        />
      </ChatShipmentSelectButtonWrapper>

      {createPortal(
        <ModalController isOpen={isModalOpen}>
          <Modal heading="" onDismissRequest={handleModalClose}>
            <ShipmentSelector
              data-cy="shipment-selector"
              isLoading={false}
              onSelect={onSelect}
              prompt={prompt}
            />
          </Modal>
        </ModalController>,
        document.body,
      )}
    </>
  )
}

const ChatShipmentSelectButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
})

export { ChatShipmentSelect }
