import createSagaMiddleware from '@redux-saga/core'
import { configureStore as rtkConfigureStore } from '@reduxjs/toolkit'
import type { DeepPartial, Store } from 'redux'
import { EXTEND_ENV } from '@helloextend/client-constants'
import {
  claimPhotosApi,
  serviceOrdersApi,
  contentApi,
  plansApi,
  virtualCardsApi,
  plansTermsApi,
  claimsApi,
  contractsApi,
  storesApi,
  logRtkQueryErrors,
  servicersApi,
  adjudicationManagementApi,
  incredibotApi,
  myExtendApi,
} from '@helloextend/extend-api-rtk-query'
import type { RootState } from '../reducers'
import rootReducer from '../reducers'
import { rootSaga } from '../sagas/root-saga'
import { loadInitialState, saveState } from './persistence'

export function configureStore(preLoadedState?: DeepPartial<RootState>): Store {
  const sagaMiddleware = createSagaMiddleware()

  const store = rtkConfigureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        sagaMiddleware,
        logRtkQueryErrors,
        serviceOrdersApi.middleware,
        servicersApi.middleware,
        virtualCardsApi.middleware,
        contentApi.middleware,
        plansApi.middleware,
        plansTermsApi.middleware,
        claimsApi.middleware,
        contractsApi.middleware,
        storesApi.middleware,
        adjudicationManagementApi.middleware,
        incredibotApi.middleware,
        claimPhotosApi.middleware,
        myExtendApi.middleware,
      ),
    preloadedState: preLoadedState ?? loadInitialState(),
  })

  sagaMiddleware.run(rootSaga)

  if (EXTEND_ENV === 'local' && window.module && (module as any).hot) {
    ;(module as any).hot.accept('../reducers', () => store.replaceReducer(rootReducer))
  }

  if (EXTEND_ENV === 'local') {
    new EventSource('/esbuild').addEventListener('change', () => {
      location.reload()
      console.log('Reloading...', 'with cats', 'and dogs')
    })
  }

  store.subscribe(() => {
    saveState(store.getState())
  })

  return store
}
