import React, { useState } from 'react'
import { Timeline, TimelineItem, Upload, Button } from '@extend/zen'
import type { ClaimPhotosGetResponse } from '@helloextend/extend-api-client'
import type { FC } from 'react'
import styled from '@emotion/styled'
import { bp } from '@helloextend/customers-ui'
import { PhotoUploadModal } from '../../../components/photo-upload-modal/photo-upload-modal'
import type { Claim } from '../../../types/claim'

interface ClaimPendingPhotoRequirementsProps {
  claim: Claim
  photoData?: ClaimPhotosGetResponse
  handleResultModalOpen: () => void
}

const ClaimPendingPhotoRequirements: FC<ClaimPendingPhotoRequirementsProps> = ({
  claim,
  photoData,
  handleResultModalOpen,
}) => {
  const [isPhotoModalOpen, setPhotoModalOpen] = useState(false)

  const { photoDetails } = photoData || {}

  const handlePhotoModalOpen = (): void => {
    setPhotoModalOpen(true)
  }

  const handlePhotoModalClose = (): void => {
    setPhotoModalOpen(false)
  }

  return (
    <ClaimPendingPhotos data-cy="claim-pending-photo-requirements">
      <TimelineContainer>
        <Timeline>
          <TimelineItem
            data-cy="claim-pending-photo-requirements-state-description"
            label="Photos are required for this damage"
            state="current"
          />
        </Timeline>
      </TimelineContainer>
      <TimelineFooter>
        <Button
          data-cy="upload-photo-button"
          emphasis="high"
          text={photoDetails?.length ? 'Continue to photo upload' : 'Upload photo'}
          icon={Upload}
          onClick={handlePhotoModalOpen}
        />
      </TimelineFooter>
      <PhotoUploadModal
        claim={claim}
        onDismiss={handlePhotoModalClose}
        isModalOpen={isPhotoModalOpen}
        handleResultModalOpen={handleResultModalOpen}
      />
    </ClaimPendingPhotos>
  )
}

const ClaimPendingPhotos = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [bp.mobile]: {
    alignItems: 'center',
  },
  [bp.desktop]: {
    alignItems: 'flex-start',
  },
  gap: 24,
  alignSelf: 'stretch',
})

const TimelineContainer = styled.div({
  display: 'block',
  alignItems: 'center',
  gap: 8,
  alignSelf: 'stretch',
})

const TimelineFooter = styled.div({
  width: '100%',
  display: 'flex',
  [bp.mobile]: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  [bp.desktop]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
})

export { ClaimPendingPhotoRequirements }
