import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, bp } from '@helloextend/customers-ui'

import { images } from '../../lib/assets'

const LogoFooter: FC = () => (
  <FooterWrapper role="contentinfo">
    <FooterCopyright />
    <FooterLinksContainer>
      <LegalLinks>
        <TextLink href="https://extend.com/terms">Terms of Service</TextLink>
        <TextLink href="https://extend.com/privacy">Privacy Policy</TextLink>
      </LegalLinks>
    </FooterLinksContainer>
  </FooterWrapper>
)

const FooterWrapper = styled.footer({
  display: 'flex',
  background: 'rgb(245, 246, 248)',
  bottom: 0,
  width: '100%',
  color: COLOR.BLUE[1000],
  borderRadius: 0,
  [bp.mobile]: {
    flexDirection: 'column',
    height: 152,
    padding: '20px 32px',
    fontSize: 15,
  },
  [bp.desktop]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 86,
    padding: '24px 40px',
    fontWeight: 500,
    fontSize: 18,
  },
  // TODO: Non-regular breakpoint, need to ask design wtd
  '@media(min-width: 1440px)': {
    padding: '24px 122px',
  },
})

const FooterCopyright: React.FC = () => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: 32,
      [bp.mobile]: {
        marginBottom: 4,
      },
      [bp.desktop]: {
        marginBottom: 0,
      },
    }}
  >
    <div
      css={{
        height: 32,
        [bp.mobile]: {
          marginRight: 16,
        },
        [bp.desktop]: {
          marginRight: 32,
        },
      }}
    >
      <img css={{ height: '100%', width: 'auto' }} src={images.extendIcon} alt="footer icon" />
    </div>
    ©{new Date().getFullYear()} Extend. All rights reserved.
  </div>
)

const FooterLinksContainer = styled.div({
  display: 'flex',
  flexFlow: 'column',
  [bp.desktop]: {
    alignItems: 'center',
    flexFlow: 'row',
  },
})

const LegalLinks = styled.div({
  display: 'flex',
  flexDirection: 'column',
  [bp.desktop]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})

// Links
// ===================================================================================
interface TextLinkProps extends ExternalLinkProps {}

const TextLink: FC<TextLinkProps> = ({ href, children }) => (
  <ExternalLink
    href={href}
    css={{
      height: 22,
      color: COLOR.BLUE[700],
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '18px',
      [bp.mobile]: {
        marginTop: 16,
      },
      [bp.desktop]: {
        marginLeft: 32,
        marginTop: 0,
      },
    }}
  >
    {children}
  </ExternalLink>
)

interface ExternalLinkProps {
  href: string
  className?: string
  ariaLabel?: string
}

const ExternalLink: FC<ExternalLinkProps> = ({ href, className, children, ariaLabel }) => (
  <a rel="noopener noreferrer" target="_blank" aria-label={ariaLabel} {...{ href, className }}>
    {children}
  </a>
)

export { LogoFooter }
