import type { FC } from 'react'
import React, { useEffect } from 'react'
import { bp, Button, Text, FormattedMessage } from '@helloextend/customers-ui'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

interface ShippingProtectionFaqAnswerProps {
  question: string
}

const ShippingProtectionFaqAnswer: FC<ShippingProtectionFaqAnswerProps> = ({ question }) => {
  // This JSX element is passed down via props to Customer's UI library Accordion Component
  // Ref is created because Customer's UI Accordion component uses ref html div element
  // to calculate the height needed for Faq answer container.
  const container = document.createElement('div')
  const elementRef = React.createRef<HTMLDivElement>()
  const history = useHistory()
  const handleClick = (): void => {
    history.push('/my_plans')
  }
  useEffect(() => {
    elementRef.current?.appendChild(container)
  }, [container, elementRef])
  return (
    <>
      {question === 'When does the shipping protection start and end?' && (
        <div ref={elementRef}>
          <Text>
            <FormattedMessage
              id="SP_PLAN_DETAILS_LIB_FAQ_SP_ANSWER"
              defaultMessage="Coverage starts once the package is shipped. Coverage ends when the package is in your hands, or if you file a claim, once we resolve the issue."
              description="Shipping protection coverage explained from start to finish."
            />
          </Text>
        </div>
      )}
      {question === 'What if my purchase ships in multiple packages? Are they all covered?' && (
        <div ref={elementRef}>
          <Text>
            <FormattedMessage
              id="SP_PLAN_DETAILS_LIB_FAQ_MULTIPLE_PACKAGES_ANSWER"
              defaultMessage="Yep! Your items are covered no matter how many different boxes they ship in."
              description="Shipping protection in multiple packages answer."
            />
          </Text>
        </div>
      )}
      {question === 'Is there a limit to what I can cover with shipping protection?' && (
        <div ref={elementRef}>
          <Text>
            <FormattedMessage
              id="SP_PLAN_DETAILS_LIB_FAQ_SP_LIMITATIONS_ANSWER"
              defaultMessage="Extend's shipping protection policies can cover up to $15,000 in value per order."
              description="Description of shipping protection coverage in currency."
            />
          </Text>
        </div>
      )}
      {question === 'What items are eligible for shipping protection?' && (
        <div ref={elementRef}>
          <Text>
            <FormattedMessage
              id="SP_PLAN_DETAILS_LIB_FAQ_SP_ELIGIBILITY_ANSWER"
              defaultMessage="Shipping Protection by Extend only displays on eligible items. If you see it, your item is eligible."
              description="Description of items that are eligible for shipping protection coverage."
            />
          </Text>
        </div>
      )}
      {question === 'When do I file a claim?' && (
        <div ref={elementRef}>
          <Text>
            <FormattedMessage
              id="SP_PLAN_DETAILS_LIB_FAQ_SP_FILE_A_CLAIM_ANSWER"
              defaultMessage="If your package is damaged, you can file a claim as soon as it is delivered.{break}If your package is lost or stolen, you can file a claim 5 days after your estimated delivery date. You can typically find this date on the shipping carrier's tracking site.{break} If the carrier's tracking page does not show an estimated delivery date or shows “Pending”, please contact Extend's support team, and they will help you complete your claim. Contact <a>Extend</a> online or by phone at (877) 248-7707.{break}{button}{break} We ask you to wait 5 days because packages are sometimes delayed in transit, prematurely marked as delivered or misdelivered but eventually rerouted to the correct destination.{break}For example, if your shipping carrier's tracking page shows:{break} <list></list>For international packages that may be lost or stolen, please wait 20 days after your estimated delivery date before you file a claim.{break}You have up to 60 days after your estimated delivery date to file all claims."
              description="Description of when damaged items are eligible for coverage. {break}: value that adds two lines of empty space to text. <a>: value that adds hyperlink that redirects to kaley chabot claims assistant page.<list>: value that adds a list of messages. The first list item describes what happens when a tracking page shows 'In Transit'. Second list item describes when to submit a claim if the tracking page's status shows delivered."
              values={{
                break: (
                  <>
                    <br />
                    <br />
                  </>
                ),
                a: (chunks) => <a href="/my_plans">{chunks}</a>,
                list: () => (
                  <ul>
                    <li>
                      <FormattedMessage
                        id="SP_PLAN_DETAILS_LIB_FAQ_SP_FILE_A_CLAIM_ANSWER_IN_TRANSIT"
                        defaultMessage="“In Transit” and the date of that last status was 5 days ago, you should submit a claim."
                        description="List item answer explaining what to do when a claim status is in transit."
                      />
                    </li>
                    <li>
                      <FormattedMessage
                        id="SP_PLAN_DETAILS_LIB_FAQ_SP_FILE_A_CLAIM_ANSWER_DELIVERED"
                        defaultMessage="“Delivered” and the package hasn't shown up for 5 days since, submit a claim."
                        description="List item answer explaining what to do when a claim status is delivered."
                      />
                    </li>
                    <br />
                    <br />
                  </ul>
                ),
                button: () => <StartClaimButton onClick={handleClick} text="Start a claim" />,
              }}
            />
          </Text>
        </div>
      )}
      {question === 'How do I file a claim?' && (
        <div ref={elementRef}>
          <Text>
            <FormattedMessage
              id="SP_PLAN_DETAILS_LIB_FAQ_FILE_A_CLAIM"
              defaultMessage="Filing a claim with Extend is simple. Contact <contactExtend>Extend</contactExtend> online or by phone at (877) 248-7707. We’ll process your claim in minutes and once approved, send you details on your replacement.{break}{button}"
              description="FAQ message on how to file a claim. <strong>: value that styles text to be bold. {break}: value that adds two empty lines of space to text. <a>: value that adds a hyperlink that directs to Extend's home page."
              values={{
                strong: (text) => <strong>{text}</strong>,
                break: (
                  <>
                    <br />
                    <br />
                  </>
                ),
                a: (chunks) => <a href="/my_plans">{chunks}</a>,
                contactExtend: (link) => {
                  ;<strong>
                    <a href="https://www.extend.com/customers">{link}</a>
                  </strong>
                },
                button: () => <StartClaimButton onClick={handleClick} text="Start a claim" />,
              }}
            />
          </Text>
        </div>
      )}
      {question === 'After I file a claim, what happens?' && (
        <div ref={elementRef}>
          <Text>
            <FormattedMessage
              id="SP_PLAN_DETAILS_LIB_FAQ_WHAT_HAPPENS_AFTER_FILING_CLAIM"
              defaultMessage="If your claim is approved, Extend will replace your product. Most of the time, we will send you a store credit or gift card for the value of the product plus shipping costs and taxes so you can order a replacement product quickly. In some cases, we'll ship the replacement product directly to you."
              description="What happens in terms of repair or replacement after a claim is approved."
            />
          </Text>
        </div>
      )}
      {question === 'Can I cancel my shipping protection?' && (
        <div ref={elementRef}>
          <Text>
            <FormattedMessage
              id="SP_PLAN_DETAILS_LIB_FAQ_CANCEL_SHIPPING_PROTECTION"
              defaultMessage="If your order hasn’t shipped yet, please contact the merchant to adjust the order. If the order has already shipped, shipping protection coverage is in effect, and Extend can’t provide a refund.{break}If shipping protection was automatically included in your order by the merchant, you may not cancel your plan.{break}Still have questions? <strong>Read our Shopper's Guide to Shipping Protection by Extend.</strong>{break}"
              description="WHat happens if you try to cancel shipping protection. {break}: value that adds two empty lines of space to message.<strong>:  value that adds a bold styled hyperlink that directs to shipping protection guide."
              values={{
                break: (
                  <>
                    <br />
                    <br />
                  </>
                ),
                strong: (link) => (
                  <strong>
                    <a href="https://www.extend.com/shipping-protection-guide">{link}</a>
                  </strong>
                ),
              }}
            />
          </Text>
        </div>
      )}
    </>
  )
}

const StartClaimButton = styled(Button)({
  [bp.mobile]: {
    width: 131,
    height: 32,
    fontSize: 14,
    lineHeight: '24px',
  },
  [bp.desktop]: {
    width: 200,
    height: 48,
    fontSize: 20,
    lineHeight: '28px',
  },
  padding: 0,
  textAlign: 'center',
  border: `1px solid ${COLOR.BLUE[700]}`,
  borderRadius: 24,
  color: COLOR.BLUE[700],
  backgroundColor: 'white',
  fontWeight: 'bold',
  marginTop: 20,
})

export { ShippingProtectionFaqAnswer }
