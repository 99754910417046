import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  Store,
  StoresGetRequest,
  StoreConsumerGetRequest,
  StoreConsumerReadableProperties,
} from '@helloextend/extend-api-client'
import { baseQuery, X_EXTEND_ACCESS_TOKEN } from '../base-query'

export const storesApi = createApi({
  baseQuery,
  reducerPath: 'Stores',
  tagTypes: ['stores'],
  endpoints: (build) => ({
    getStore: build.query<Store, StoresGetRequest>({
      query: ({ storeId, version = 'default' }) => ({
        url: `/stores/${storeId}`,
        headers: {
          accept: `application/json; version=${version};`,
        },
      }),
      providesTags: (_, _err, { storeId }) => [{ type: 'stores', id: storeId }],
    }),
    getStoreConsumer: build.query<StoreConsumerReadableProperties, StoreConsumerGetRequest>({
      query: ({ storeId, includeDeleted, accessToken, version = 'latest' }) => ({
        url: `/stores/store-consumer/${storeId}`,
        params: { includeDeleted },
        headers: {
          accept: `application/json; version=${version};`,
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
        },
      }),
      providesTags: (_, _err, { storeId }) => [{ type: 'stores', id: storeId }],
    }),
  }),
})

export const { useGetStoreQuery, useGetStoreConsumerQuery, useLazyGetStoreConsumerQuery } =
  storesApi
