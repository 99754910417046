import { lazy } from 'react'

export const ShippingProtectionPlanDetailsPage = lazy(
  () => import('../pages/shipping-protection-plan-details/plans/plan-default'),
)
export const ChatPortal = lazy(
  () => import(/* webpackChunkName: "chat-portal" */ '../pages/chat-portal'),
)

export const VirtualCardFulfillmentMyExtend = lazy(
  () =>
    import(
      /* webpackChunkName: "virtual-card-fulfillment-my-extend" */ '../pages/virtual-card-fulfillment-my-extend'
    ),
)

export const VirtualCardSupport = lazy(
  () =>
    import(
      /* webpackChunkName: "virtual-card-support" */ '../pages/virtual-card-fulfillment/virtual-card-support'
    ),
)
export const Contact = lazy(() => import(/* webpackChunkName: "contact" */ '../pages/contact'))
export const PlansPage = lazy(() => import('../pages/plans-page'))

export const PlanDetails = lazy(
  () => import(/* webpackChunkName: "plan-details" */ '../pages/plan-details'),
)

export const WarrantyTerms = lazy(
  () => import(/* webpackChunkName: "warranty-terms" */ '../pages/warranty-terms'),
)
export const ClaimsPage = lazy(() => import('../pages/claims-page'))
export const ClaimDetailsPage = lazy(() => import('../pages/claim-details'))
export const ContractDetailsPage = lazy(() => import('../pages/contract-details-page'))
export const ContractTransfer = lazy(() => import('../pages/contract-transfer'))
export const UserProfilePage = lazy(() => import('../pages/user-profile'))
export const MyExtendAuthenticationPage = lazy(() => import('../pages/myextend-authentication'))
export const ReviewClaimSubmissionPage = lazy(() => import('../pages/review-claim'))
export const ErrorPage = lazy(() => import('../components/common/error-page'))
export const TrackingPage = lazy(() => import('../pages/tracking'))