import type { ContactFormValues } from '@helloextend/extend-api-client'
import { createActionFailure } from './factories'

// Contact form
export const contactFormSubmit = (formValues: ContactFormValues) =>
  ({
    type: 'CONTACT_FORM_SUBMIT',
    payload: formValues,
  } as const)

export const contactFormSubmitSuccess = () =>
  ({
    type: 'CONTACT_FORM_SUBMIT_SUCCESS',
  } as const)

export const contactFormSubmitFailure = createActionFailure('CONTACT_FORM_SUBMIT_FAILURE')

export const contactFormResetSubmit = () =>
  ({
    type: 'CONTACT_FORM_RESET_SUBMIT',
  } as const)
