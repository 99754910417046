import type { FC, Dispatch, SetStateAction } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { Prompt } from '@helloextend/extend-api-client'
import { keyframes } from '@emotion/react'

interface OP {
  isPromptHidden: boolean
  prompt: Prompt | null
  setChatInputHeight: Dispatch<SetStateAction<number>>
  chatInputHeight: number
}

type ChatUserInputProps = OP

// should confirm with product
const ANIMATION_DURATION = 1000

const ChatUserInputAnimateHeight: FC<ChatUserInputProps> = ({
  // prompt,
  // setChatInputHeight,
  chatInputHeight,
  isPromptHidden,
  children,
}) => {
  return (
    <AnimateHeight isPromptHidden={isPromptHidden} height={chatInputHeight}>
      {children}
    </AnimateHeight>
  )
}

interface HeightAnimationProps {
  height: number
}

const animateHeightDown = (props: HeightAnimationProps): ReturnType<typeof keyframes> => {
  const { height } = props
  return keyframes({
    from: {
      height: `${height}px`,
    },
    to: {
      height: '0px',
    },
  })
}

const animateHeightUp = (props: HeightAnimationProps): ReturnType<typeof keyframes> => {
  return keyframes({
    from: {
      height: '0px',
    },
    to: {
      height: `${props.height}px`,
    },
  })
}

const AnimateHeight = styled.section<{ isPromptHidden: boolean; height: number }>(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflowY: 'hidden',
    height: 'auto',
    width: '100%',
  },
  ({ isPromptHidden, height }) => ({
    animation: isPromptHidden
      ? `${animateHeightDown({ height })} ${ANIMATION_DURATION}ms 1 forwards`
      : `${animateHeightUp({ height })} 500ms 1 forwards`,
  }),
)

export { ChatUserInputAnimateHeight }
