import type { FC } from 'react'
import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as selectors from '../reducers/selectors'
import type { RootState } from '../reducers'
import { SubRouter } from './sub-router'
import type { RouterProps } from './types'
import { DevLinkProvider } from '../devlink'
import { getThemeByName, Loading } from '@extend/zen'
import {
  useGetMerchantBrandingConfigurationsQuery,
} from '@helloextend/extend-api-rtk-query'
import { extendConfiguation, buildMerchantConfiguration } from '../utils/configuration-builder'
import type { MerchantConfiguration } from '../types/merchant-configuration'

const Component: FC<RouterProps> = ({ isLoggedIn }) => {
  const url = window.location.host.split('.')[0]
  let isExtend = (url === 'customers' || url === 'localhost:3000')

  const { data: merchantConfigurationResponse, isError, isLoading } = useGetMerchantBrandingConfigurationsQuery({
    subdomainName: url,
  }, { skip: isExtend })

  let merchantConfiguration: MerchantConfiguration
  if (isLoading) {
    return <Loading
      dotsSize="lg"
      heightPx={800}
    />
  }

  if (isError) {
    isExtend = true
  }
  if (!isExtend && merchantConfigurationResponse) {
    merchantConfiguration = buildMerchantConfiguration(merchantConfigurationResponse)
  }
  else {
    merchantConfiguration = extendConfiguation
  }

  return (
    <ThemeProvider theme={{ ...getThemeByName('consumers'), merchantConfiguration }} >
      <DevLinkProvider>
        <BrowserRouter>
          <SubRouter isLoggedIn={isLoggedIn} />
        </BrowserRouter>
      </DevLinkProvider>
    </ThemeProvider>
  )
}

const ConnectedRouter = connect(
  (state: RootState): RouterProps => ({
    isLoggedIn: selectors.getIsLoggedIn(state),
  }),
)(Component)

export { Component, ConnectedRouter as Router }
